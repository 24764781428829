
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Component, OnInit, Input, ElementRef, HostListener, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-utility-menu-item',
  templateUrl: './utility-menu-item.component.html',
  styleUrls: ['./utility-menu-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UtilityMenuItemComponent implements OnInit {

  @Input() utilityMenu: any[];
  @Input() cssClass: string;

  mainMenu: boolean[] = [];
  secondaryMenu: boolean[] = [];

  constructor(private eRef: ElementRef) { }

  ngOnInit() {
  }

  setActiveMenu(id: number, event: Event) {
    const existingVal = this.mainMenu[id];
    this.mainMenu = [];
    this.mainMenu[id] = !existingVal;
  }

  getActiveMenu(id: number) {
    return this.mainMenu[id];
  }

  setActiveSubMenu(id: number, event: Event) {
    const existingVal = this.secondaryMenu[id];
    this.secondaryMenu = [];
    this.secondaryMenu[id] = !existingVal;
  }

  getActiveSubMenu(id: number) {
    return this.secondaryMenu[id];
  }

  closeAllFlyout() {
    this.mainMenu = this.secondaryMenu = [];
  }

  getRedirectUrl(url) {
    if ((url.toLowerCase().indexOf('indices') > -1 && url.toLowerCase().indexOf('indices3') == -1)
     || (url.toLowerCase().indexOf('data-insights') > -1)) {
      const idp = localStorage.getItem("idp");
      const domainHint = localStorage.getItem("domain_hint");
      if(idp && ["okta","b2c","b2c-federated"].includes(idp)){
        url = url.indexOf('?') > -1 ? url + `&chkClt=1&idp=${idp}` : url + `?chkClt=1&idp=${idp}`;
        if(domainHint && ["b2c-federated"].includes(idp)){
          url += `&domain_hint=${domainHint}`;
        }
      }else{
        url = url.indexOf('?') > -1 ? url + '&chkClt=1' : url + '?chkClt=1';
      }     
    }
    return url;
  }

  @HostListener('window:click', ['$event'])
  hideMenu(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeAllFlyout();
    }
  }

}
