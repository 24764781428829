import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HomeConstants } from '../../../shared/constants/home-constants';
import { InterTabsNavigation, InterTabsNavigationParams } from '../../../shared/models/intertabs-navigation.model';
@Injectable({
  providedIn: 'root'
})
export class IntertabsNavigationService implements InterTabsNavigation {

  defaultValue: InterTabsNavigationParams = new InterTabsNavigationParams();
  $interTabsNavigation: BehaviorSubject<InterTabsNavigationParams>;
  tabs = HomeConstants.tabs;

  constructor() {
    this.$interTabsNavigation = new BehaviorSubject<InterTabsNavigationParams>(this.defaultValue);
  }


  navigateToTab(params?: InterTabsNavigationParams) {
    let interTabsData = { ...this.$interTabsNavigation.value };
    if (params && params.data) {
      interTabsData.data = params.data;
    }
    if (params) {
      interTabsData.fromIdx = params.fromIdx;
    }
    if (params && params.toIdx) {
      interTabsData.toIdx = params.toIdx;
    }
    if (params && params.appliedFilters) {
      interTabsData.appliedFilters = params.appliedFilters;
    }
    if (params && params.appliedOptions) {
      interTabsData.appliedOptions = params.appliedOptions;
    }

    this.$interTabsNavigation.next(interTabsData);
  }

  resetTabsNavigation() {
    let interTabsData = { ...this.defaultValue };
    this.$interTabsNavigation.next(interTabsData);
  }
}
