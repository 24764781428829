<div class="item" fxLayout="row" fxLayoutAlign="start center" [ngClass]="{'available': item.status == downloadFileStatus.COMPLETED}">
    <div fxFlex="30" class="file-name">
        {{getFileName()}}
    </div>
    <div fxFlex="10" fxFlexOffset="5" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon *ngIf="(item.status == downloadFileStatus.COMPLETED || item.status == downloadFileStatus.DOWNLOADED) && !isDownloading" class="download-icon" (click)="downloadFile()">download</mat-icon>
        <mat-progress-spinner *ngIf="(item.status == downloadFileStatus.COMPLETED || item.status == downloadFileStatus.DOWNLOADED) && isDownloading" [color]="'primary'" [mode]="'indeterminate'" [diameter]="20"> </mat-progress-spinner>
        <mat-icon *ngIf="item.status == downloadFileStatus.FAILED" class="failed-icon">close</mat-icon>
        <span class="status" *ngIf="item.status == downloadFileStatus.INITIALIZED">Processing</span>
    </div>
    <div fxFlex="15" fxFlexOffset="5" class="expiry">
        <span *ngIf="item.status == downloadFileStatus.COMPLETED || item.status == downloadFileStatus.DOWNLOADED">{{expiryDateToDisplay}}</span>
        <span *ngIf="item.status == downloadFileStatus.FAILED">Failed - Please retry</span>
        <span fxFlex="100" fxLayout="row" fxLayoutAlign="center center" *ngIf="item.status == downloadFileStatus.INITIALIZED">-</span>
    </div>
    <div fxFlex="30" fxFlexOffset="5" class="comments">
        {{commentTextToDisplay}}
        <ng-container *ngIf="showTruncatedLinks">
            <ng-container *ngTemplateOutlet="moreLessLinks">

            </ng-container>
        </ng-container>
        
    </div>

    <ng-template #nothing></ng-template>

    <ng-template #moreLessLinks>
        <a href="#" *ngIf="seeMore" (click)="textLink($event)">&nbsp;...See more</a>
        <a href="#" *ngIf="!seeMore" (click)="textLink($event)">&nbsp;See less</a>
    </ng-template>
</div>