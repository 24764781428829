
<!--
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
-->

<div *ngIf="searchMenu && searchMenu.length > 0">
    <ul class="side-menu__utility">
        <li [class.current]="isSearchMenuOpen">
            <a (click)="isSearchMenuOpen = !isSearchMenuOpen">
                <app-svg-icon [name]="searchMenu[0].icon">
                </app-svg-icon>
                <span class="link-text">
                    {{searchMenu[0].name}}
                </span>
            </a>
            <ul *ngIf="searchMenu[0].children && searchMenu[0].children.length > 0"
                class="side-menu__search"
                [style.display]="isSearchMenuOpen ? 'block' : 'none'">
                <div class="panel-group menu-search"
                     aria-multiselectable="true">
                    <li *ngIf="providerProfileEnabled">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <div class="panel-title">
                                    <a (click)="providerProfileExpanded = !providerProfileExpanded"
                                       [class.collapsed]="!providerProfileExpanded">
                                        Find Provider Profile
                                        <app-svg-icon
                                                      [name]="providerProfileExpanded? 'expander_close' : 'expander_open'">
                                        </app-svg-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="panel-collapse"
                                 [style.display]="providerProfileExpanded ? 'block':'none'">
                                <div class="panel-body">
                                    <form #findProviderProfile
                                          action="Search/ProviderSearch/Providers"
                                          [formGroup]="providerProfileForm"
                                          (ngSubmit)="onSubmit(providerProfileForm, findProviderProfile)">
                                        <io-form-field type="io-text"
                                                       placeholder="First Name"
                                                       name="providerfirstname"
                                                       formControlName="providerfirstname">
                                        </io-form-field>
                                        <io-form-field type="io-text"
                                                       placeholder="Last Name"
                                                       name="providerlastname"
                                                       formControlName="providerlastname">
                                        </io-form-field>
                                        <io-form-field type="io-text"
                                                       placeholder="Provider ID/NPI"
                                                       name="providernpi"
                                                       formControlName="providernpi">
                                        </io-form-field>
                                        <io-primary-button>Search
                                        </io-primary-button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li *ngIf="providerGroupEnabled">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <div class="panel-title">
                                    <a (click)="providerGroupExpanded = !providerGroupExpanded"
                                       [class.collapsed]="!providerGroupExpanded">
                                        Find Provider Group
                                        <app-svg-icon
                                                      [name]="providerGroupExpanded? 'expander_close' : 'expander_open'">
                                        </app-svg-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="panel-collapse"
                                 [style.display]="providerGroupExpanded ? 'block':'none'">
                                <div class="panel-body">
                                    <form #findProviderGroup
                                          action="Search/ProviderGroupSearch/ProviderGroupSearch"
                                          [formGroup]="providerGroupForm"
                                          (ngSubmit)="onSubmit(providerGroupForm, findProviderGroup)">
                                        <io-form-field type="io-text"
                                                       placeholder="Group Id"
                                                       name="provider_group_id"
                                                       formControlName="provider_group_id">
                                        </io-form-field>
                                        <io-form-field type="io-text"
                                                       placeholder="Group Name"
                                                       name="providergroup"
                                                       formControlName="providergroup">
                                        </io-form-field>
                                        <io-primary-button>Search
                                        </io-primary-button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li *ngIf="memberProfileEnabled">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <div class="panel-title">
                                    <a (click)="memberProfileExpanded = !memberProfileExpanded"
                                       [class.collapsed]="!memberProfileExpanded">
                                        Find Member Profile
                                        <app-svg-icon
                                                      [name]="memberProfileExpanded? 'expander_close' : 'expander_open'">
                                        </app-svg-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="panel-collapse"
                                 [style.display]="memberProfileExpanded ? 'block':'none'">
                                <div class="panel-body">
                                    <form #findMemberProfile
                                          action="Search/MemberSearch/Members"
                                          [formGroup]="memberProfileForm"
                                          (ngSubmit)="onSubmit(memberProfileForm, findMemberProfile)">
                                        <io-form-field type="io-text"
                                                       placeholder="First Name"
                                                       name="firstname"
                                                       formControlName="firstname">
                                        </io-form-field>
                                        <io-form-field type="io-text"
                                                       placeholder="Last Name"
                                                       name="lastname"
                                                       formControlName="lastname">
                                        </io-form-field>
                                        <io-form-field type="io-text"
                                                       placeholder="Member ID"
                                                       name="memberid"
                                                       formControlName="memberid">
                                        </io-form-field>
                                        <io-form-field type="io-text"
                                                       placeholder="Subscriber Key"
                                                       name="subscriberkey"
                                                       formControlName="subscriberkey">
                                        </io-form-field>
                                        <io-primary-button>Search
                                        </io-primary-button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li *ngIf="portalSearchEnabled">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <div class="panel-title">
                                    <a (click)="portalSearchExpanded = !portalSearchExpanded"
                                       [class.collapsed]="!portalSearchExpanded">
                                        Search Portal
                                        <app-svg-icon [name]="portalSearchExpanded? 'expander_close' : 'expander_open'">
                                        </app-svg-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="panel-collapse"
                                 [style.display]="portalSearchExpanded ? 'block':'none'">
                                <div class="panel-body">
                                    <form #searchPortal
                                          action="Search/HomePageSearch/HomePageSearchResults"
                                          [formGroup]="portalSearchForm"
                                          (ngSubmit)="onSubmit(portalSearchForm, searchPortal)">
                                        <io-form-field type="io-text"
                                                       placeholder="Keyword"
                                                       name="keyword"
                                                       formControlName="keyword">
                                        </io-form-field>
                                        <io-primary-button>Search
                                        </io-primary-button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </li>
                </div>
            </ul>
        </li>
    </ul>
    <!-- Search Menu container -->
    <div class="menu-secondary"
         [class.showSecondary]="isSearchMenuOpen">
        <div class="menu-secondary__controls clearfix">
            Select Category
            <button class="close-btn"
                    (click)="isSearchMenuOpen = false">
                <app-svg-icon name="close_x"></app-svg-icon>
            </button>
        </div>
        <div class="menu-secondary__inner">
        </div>
    </div>
</div>