import { HomeConstants } from '../../../shared/constants/home-constants';

export class OverviewSummaryConstants {
    static readonly Keys = {
        DAYS_IN_PROJECT: "daysInProject",
        DAYS_LEFT: "daysLeft",
        PROJECT_COMPLETION: "projectCompletion",

        // Charts
        [HomeConstants.CHARTS_TEXT]: {
            PROJECT_COMPLETION: "chartsCompletedPercent",
            TOTAL: "chartsCount",
            PURSUABLE: "chartsPursuable",
            COMPLETED: "chartsCompleted",
            AVAILABLE: "chartsAvailable",
            INITIAL_TARGET: "initialTarget",
        },

        // Pursuits
        [HomeConstants.PURSUITS_TEXT]: {
            PROJECT_COMPLETION: "completedPercent",
            TOTAL: "pursuitCount",
            PURSUABLE: "pursuable",
            COMPLETED: "completed",
            AVAILABLE: "available",
            INITIAL_TARGET: "initialTarget",
        },
    }

    static readonly Labels = {
        DAYS_IN_PROJECT: "Days in Project",
        DAYS_LEFT: "Days Left",
        PROJECT_COMPLETION: "Project Completion %",
        INITIAL_TARGET: "Initial Target",

        // Charts
        [HomeConstants.CHARTS_TEXT]: {
            TOTAL: "Total Charts",
            PURSUABLE: "Pursuable Charts",
            COMPLETED: "Completed Charts",
            AVAILABLE: "Available Charts",
        },

        // Pursuits
        [HomeConstants.PURSUITS_TEXT]: {
            TOTAL: "Total Pursuits",
            PURSUABLE: "Pursuable Pursuits",
            COMPLETED: "Completed Pursuits",
            AVAILABLE: "Available Pursuits",
        },
    }

    static readonly InfoIconContent = {
        DAYS_IN_PROJECT: "Duration of the project in days and is calculated as the difference between Project End Date and Project Start Date. This field is populated only if a single project is selected else N/A is displayed.",
        DAYS_LEFT: "Number of days remaining in the project and is calculated as the difference between Project End Date and current date. This field is populated only if a single project is selected else N/A is displayed.",
        PROJECT_COMPLETION: "The percentage of pursuits or charts for which  the review is complete.",
        INITIAL_TARGET: "The number of pursuits or charts targeted for completion by end of the year",
        AVAILABLE_CHARTS_PURSUITS: "The number of pursuits or charts available for collection. Available = Pursuable - (Chart Review Complete or in QA Process )- (Collection Complete - Ready for Review)",
        SITE_DETAIL: "The site details provide pursuit or chart details at site level. Users can customize the layout by adding or removing  dimensions such as State, Medical Group and Site Name. Only one field among Site Name and Provider Name will be enabled. ",

        // Charts
        [HomeConstants.CHARTS_TEXT]: {
            TOTAL: "The total number of approved pursuits or charts that are chased for medical record review.",
            PURSUABLE: "The total number of charts that can be pursued for collection. Pursuable Pursuits/Charts  = Total Pursuits/Charts - Closed Pursuits/Charts -Issued Pursuits/Charts",
            COMPLETED: "The number of pursuits or charts for which  the review is complete.",
            AVAILABLE: "The number of pursuits or charts available for collection. Available = Pursuable - (Chart Review Complete or in QA Process )- (Collection Complete - Ready for Review)",
        },

        // Pursuits
        [HomeConstants.PURSUITS_TEXT]: {
            TOTAL: "The total number of approved pursuits or charts that are chased for medical record review.",
            PURSUABLE: "The total number of charts that can be pursued for collection. Pursuable Pursuits/Charts  = Total Pursuits/Charts - Closed Pursuits/Charts -Issued Pursuits/ChartsThe total number of charts that can be pursued for collection. Pursuable Pursuits/Charts  = Total Pursuits/Charts - Closed Pursuits/Charts -Issued Pursuits/Charts",
            COMPLETED: "The number of pursuits or charts for which  the review is complete.",
            AVAILABLE: "The number of pursuits or charts available for collection. Available = Pursuable - (Chart Review Complete or in QA Process )- (Collection Complete - Ready for Review)",
        },
    }
}

export class NavigationFromComponentType {
    static readonly NavigationFromComponent = {
        DEFAULT: "default",
        DATA_GRID: "grid",
        STATUS_PANEL: "mrr-status-panel",
        DOWNLOAD_DIALOG: "download-dialog",
    }
}

export class OverviewMRRPanelConstants {
    static readonly Keys = {
        SITE_ID: "siteId",
        [HomeConstants.CHARTS_TEXT]: {
            PURSUIT_GROUPED_STATUS: "pursuitGroupedStatus",
            MRR_STATUS_BREAKDOWN: "mrrStatusBreakdown",
            RECORD_REQUEST_IN_PROGRESS_BREAKDOWN: "recrodRequestInProgressBreakdown",
            COUNT: "chartsCount",
        },
        [HomeConstants.PURSUITS_TEXT]: {
            PURSUIT_GROUPED_STATUS: "pursuitGroupedStatus",
            MRR_STATUS_BREAKDOWN: "mrrStatusBreakdown",
            RECORD_REQUEST_IN_PROGRESS_BREAKDOWN: "recrodRequestInProgressBreakdown",
            COUNT: "pursuitCount",
        }
    }

    static readonly FormattedKeys = {
        STATUS: "status",
        SUB_STATUSES: "subStatuses",
        SUB_STATUS: "subStatus",
        SUB_SUB_STATUSES: "subSubStatuses",
        SUB_SUB_STATUS: "subSubStatus"
    }

    static readonly MappingKeys = {
        "status": "pursuitGroupedStatus",
        "subStatus": "mrrStatusBreakdown",
        "subSubStatus": "recrodRequestInProgressBreakdown",
        "pursuitCount": "pursuitCount",
        "chartCount": "chartsCount"
    }

    static readonly StatusLevels = {
        STATUS: "status",
        SUB_STATUS: "subStatus",
        SUB_SUB_STATUS: "subSubStatus"
    }
}

export class GridConstants {
    static readonly Fields = {
        STATE: "state",
        STATE_CODE: "stateCode",
        SITE: "site",
        SITE_ID: "siteId",
        SITE_NAME: "siteName",
        SORT_SITE_NAME: "sortSiteName",
        MEDICAL_GROUP_ID: "medicalGroupId",
        MEDICAL_GROUP_NAME: "medicalGroupName",
        PROVIDER_ID: "providerId",
        PROVIDER_NAME: "providerName",
        // Charts
        [HomeConstants.CHARTS_TEXT]: {
            COUNT: "chartsCount",
            PURSUABLE: "chartsPursuable",
            CLOSED: "chartsClosed",
            ISSUED: "chartsIssued",
            COMPLETED: "chartsCompleted",
            COMPLETED_PERCENT: "chartsCompletedPercent",
            AVAILABLE: "chartsAvailable",
            AVAILABLE_PERCENT: "chartsAvailablePercent",
        },

        // Pursuits
        [HomeConstants.PURSUITS_TEXT]: {
            COUNT: "pursuitCount",
            PURSUABLE: "pursuable",
            CLOSED: "closed",
            ISSUED: "issued",
            COMPLETED: "completed",
            COMPLETED_PERCENT: "completedPercent",
            AVAILABLE: "available",
            AVAILABLE_PERCENT: "availablePercent",
        },
    }

    static readonly Headers = {
        STATE: "State",
        SITE_NAME: "Site Name",
        SITE_NAME_WITH_ID: "Site Name (ID)",
        MEDICAL_GROUP_NAME: "Medical Group Name",
        MEDICAL_GROUP_NAME_WITH_ID: "Medical Group Name (ID)",
        PROVIDER_NAME: "Provider Name",
        PROVIDER_NAME_WITH_ID: "Provider Name (ID)",
        PURSUABLE: "Pursuable",
        CLOSED: "Closed",
        ISSUED: "Issued",
        COMPLETED: "Completed",
        COMPLETED_WITH_PERCENT: "Completed (%)",
        AVAILABLE: "Available",
        AVAILABLE_WITH_PERCENT: "Available (%)",

        // Charts
        [HomeConstants.CHARTS_TEXT]: {
            COUNT: "Chart Count",
        },

        // Pursuits
        [HomeConstants.PURSUITS_TEXT]: {
            COUNT: "Pursuit Count",
        },
    }
}

export class CDEConstants {
    static readonly Keys = {
        TOTAL_TARGETS_SENT: "cdeTotalTargetsSent",
        CURRENT_TARGETS: "cdeCurrentTargets"
    }

    static readonly FilterKeyForCurrentTargets = "RequestorUID";
}

export class AuxiliaryConstants {
    static readonly Mappings = {
        STATUS: "pursuitGroupedStatus",
    }
}
