/*
 * Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
 * This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
 * The receipt or possession of this program does not convey any rights to use,
 * reproduce or disclose its contents in whole or in part, without the specific
 * written consent of Inovalon, Inc. Any use, reproduction or disclosure of
 * this program without the express written consent of Inovalon, Inc., is a
 * violation of the copyright laws and may subject you to criminal prosecution.
 */

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { OktaCustomComponentComponent } from "./okta-custom-component/okta-custom-component.component";
import { OktaGuard } from "./factory/iookta.guard";
import { LogoutComponent } from "./shared/logout/logout.component";
import { OktaCallbackComponent } from "@okta/okta-angular";
import { HomeComponent } from "./shared/home/home.component";
import { UnauthorizedComponent } from "./shared/unauthorized/unauthorized.component";
import { ExplorerComponent } from "./shared/explorer/explorer.component";
import { InvalidurlComponent } from "./shared/invalidurl/invalidurl.component";
import { LoginGuard,EnterEmailComponent,AuthenticatedGuard, SigninRedirectComponent } from "@io-angular/auth";
import { AzureB2CGuard } from "./factory/azureb2c.guard";
import { CustomCookieGuard } from "./factory/custom-cookie-guard";
/*
const oktaSpaConfig = {
  issuer: environment.okta.issuerUrl,
  redirectUri: environment.okta.redirectUri,
  clientId: environment.okta.clientId,
  scopes: ['openid', 'profile', 'email'],
  pkce: environment.okta.pkce,
  autoRenew: false,
  storage: environment.okta.storage
 };*/

const routes: Routes = [
  // { path: '', redirectTo: 'vbpi', pathMatch: 'full' },
  { path: "", component: HomeComponent, canActivate: [CustomCookieGuard,LoginGuard,OktaGuard] },

  // { path: "callback", component: OktaCallbackComponent },
  // { path: "callback", component: OktaCustomComponentComponent },
  {
    path: 'enter-email',
    component: EnterEmailComponent,
    // canActivate: [AuthenticatedGuard]
  },

  {
    path:'signin-redirect',
    component:SigninRedirectComponent,
    canActivate:[AuthenticatedGuard]
  },

  { path: 'rli', loadChildren: () => import('./rli/rli.module').then(m => m.RLIModule) },
  { path: 'qpi', loadChildren: () => import('./qpi/qpi.module').then(m => m.QpiModule) },
  { path: 'vbpi', loadChildren: () => import('./vbpi/vbpi.module').then(m => m.VbpiModule) },
  { path: 'explorer', component: ExplorerComponent },
  { path: 'rli', loadChildren: () => import('./rli/rli.module').then(m => m.RLIModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'explorer', component: ExplorerComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'unauthorized', component: UnauthorizedComponent, canActivate: [CustomCookieGuard,LoginGuard] },
  { path: "mi", loadChildren: () => import("./mi/mi.module").then(m => m.MiModule) },
  { path: "es",loadChildren: () => import("./es/es.module").then(m => m.EsModule) },
  { path: "fia",loadChildren: () => import("./fia/fia.module").then(m => m.FiaModule) },
  { path: "dbaas", loadChildren: () => import("./dbaas/dbaas.module").then(m => m.DbaasModule) },
  { path: 'inactivate', component: LogoutComponent},
  { path: 'sign-out', component: LogoutComponent},
  { path: 'invalidurl', component: InvalidurlComponent},
  { path: '**', component: InvalidurlComponent},
  // { path: '**',  component: HomeComponent, canActivate: [CustomCookieGuard,LoginGuard,OktaGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
