
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/services/menu.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.css']
})
export class LeftNavComponent implements OnInit {

  @Output() collapseMenuEvt = new EventEmitter();
  isMenuCollapsed = false;
  isHandset: Observable<BreakpointState>;
  redirectUrl = '';

  constructor(
    private breakpointObserver: BreakpointObserver,
    public domSanitizer: DomSanitizer,
    public menuService: MenuService,
    public router: Router) {
    this.isHandset = this.breakpointObserver.observe('(max-width: 839px)');
  }

  ngOnInit() {
    this.collapseMenu();
    this.getIndicesRedirectUrl();
  }

  collapseMenu() {
    this.isHandset.subscribe(result => {
      if (result.matches && !this.menuService.keepOpen) {
        this.isMenuCollapsed = true;
      } else {
        this.isMenuCollapsed = false;
      }
      this.collapseMenuEvt.emit(this.isMenuCollapsed);
    });
  }

  toggleMenu() {
    this.isMenuCollapsed = this.menuService.keepOpen ? false : !this.isMenuCollapsed;
    this.collapseMenuEvt.emit(this.isMenuCollapsed);
  }

  getIndicesRedirectUrl() {
    const idp = localStorage.getItem("idp");
    const domainHint = localStorage.getItem("domain_hint");
    if(idp && ["okta","b2c","b2c-federated"].includes(idp)){
      this.redirectUrl = localStorage.getItem('indicesAppUrl') + '?chkClt=1'+`&idp=${idp}`;
      if(domainHint && ["b2c-federated"].includes(idp)){
        this.redirectUrl += `&domain_hint=${domainHint}`;
      }
    }else{
      this.redirectUrl = localStorage.getItem('indicesAppUrl') + '?chkClt=1';
    }      
  }

}
