export class FilterLabels {
    static readonly ALL = "All";
}

export class FiaFilterFields {
    static readonly SERVICE_YEAR = "serviceYear";
    static readonly PROJECT_NAME = "projectName";
    static readonly PAYER_STATE = "payerState";
    static readonly LINE_OF_BUSINESS = "lineOfBusiness";
    static readonly POPULATION = "population";
    static readonly PARTNER = "vendorName";
    static readonly INTERVENTION_PLAN_NAME = "interventionPlanName";



     static readonly MEMBER_NAME = "memberName";
     static readonly SITE_NAME = "siteName";
}

export class FiaFiltersBinding {
    static readonly bindingInfo = {
        [FiaFilterFields.SERVICE_YEAR]: {
            idField: "serviceYear",
            label: "Service Year",
        },
        
        [FiaFilterFields.PROJECT_NAME]: {
            idField: "projectId",
            label: "Project Name"
        },
       
        [FiaFilterFields.PAYER_STATE]: {
          idField: "payerState",
          label: "Payer State"
        },
        [FiaFilterFields.LINE_OF_BUSINESS]: {
          idField: "lineOfBusiness",
          label: "Line of Business"
        },
        [FiaFilterFields.POPULATION]: {
          idField: "population",
          label: "Population"
        },
        [FiaFilterFields.PARTNER]: {
          idField: "vendorName",
          label: "Partner"
        },
        [FiaFilterFields.INTERVENTION_PLAN_NAME]: {
          idField: "interventionPlanName",
          label: "Intervention Plan Name"
        },
        [FiaFilterFields.MEMBER_NAME]: {
          idField: "memberId",
          label: "Member"
        }
    }
}
