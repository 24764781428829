
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { BehaviorSubject, EMPTY, Observable, Subscription, timer, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IoDialogService, IoConfirmDialogComponent } from '@io-angular/core';
import { Inject, Injectable } from '@angular/core';
import { retry, shareReplay, multicast } from 'rxjs/operators';
import { TokenModel } from '../models/token.model';
import { SessionIdleService } from './session-idle.service';
import { Router } from '@angular/router';
import { ClientModel } from '../models/client.model';
import { LoggerService } from '../shared/services/logger.service';
import { environment } from '../../environments/environment'
import { AuthService } from '@io-angular/auth';
import { AppLoaderService } from '../shared/services/app-loader/app-loader.service';

@Injectable({
  providedIn: 'root'
})
export class OktaService {
  upn$: BehaviorSubject<string>;
  idToken$: BehaviorSubject<any>;
  storedIdToken$: BehaviorSubject<any>;
  oktaTokenStorage$: BehaviorSubject<any>;
  userInfo$: BehaviorSubject<any>;
  baseURL = environment.BASE_URL.concat('/api/v1');
  upn = '';
  parsedExpiresAt: number;
  timeLeft = '';
  lastActiveExpiresAt: number;
  logMsg = {};
  redirectUrl: string ;

  _logout$: Subject<boolean> = new Subject()
  logout$: Observable<boolean> = this._logout$.asObservable()

  jwtHelper = new JwtHelperService();
  idToken: any;
  accessToken$: Subject<string> = new Subject();
  sessionIdleSubsription: Subscription;
  sessionSubsribtions: Subscription[] = [];

  isAuthenticated: boolean;
  // Client object is set in services/menu.services.ts
  client: BehaviorSubject<ClientModel> = new BehaviorSubject<ClientModel>(null);
  client$: Observable<ClientModel> = this.client.asObservable();

  // userPermissions object is set in services/menu.services.ts
  userPermissions: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  userPermissions$: Observable<any> = this.userPermissions.asObservable();

  // display warning every (env.okta.refreshInterval/60000)-2.5 minutes
  // set a background timer to execute 30 seconds before {this.env.okta.refreshInterval-120000}
  // then 30 seconds before every {this.env.okta.refreshInterval-120000} milisec
  warningPopupInterval = this.env.okta.refreshInterval - 120000 - 30000;

  // refresh remainingTimeLeft after 1 millisecond then after every 10 seconds
  remainingTimeLeftTimer$ = timer(1, 10000);


  constructor(
    private http: HttpClient,
    private dialogService: IoDialogService,
    private tokenModel: TokenModel,
    @Inject('env') private env,
    private sessionIdleService: SessionIdleService,
    private router: Router,
    private loggerService: LoggerService,
    private authService:AuthService,
    private appLoaderService : AppLoaderService
  ) {
    this.upn$ = new BehaviorSubject<string>('');
    this.idToken$ = new BehaviorSubject<string>('');
    this.storedIdToken$ = new BehaviorSubject<string>('');
    this.oktaTokenStorage$ = new BehaviorSubject<string>('');
    this.userInfo$ = new BehaviorSubject<any>(null);
  }

  async logout() {
    try{
    this.appLoaderService.showLoader();
    this._logout$.next(true);
    this.authService.logout();
    }catch(err){
      console.error("Auth logout failed : ",err);
    }

}

  getAccessToken() {
    const accessToken =  this.authService.accessToken;
    return accessToken;
  }

  //getIdToken for Azure b2c
  getTheIdToken() {
    // Get and parse IdToken to get user details
    
    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      if(!isAuthenticated){
        return;
      }
      console.log("get info");
      const rawIdToken = this.authService.idToken;
      if (rawIdToken) {
        this.idToken = this.jwtHelper.decodeToken(rawIdToken);
        this.tokenModel.upn = this.idToken.upn;
        this.tokenModel.email = this.idToken.emailAddress;
        this.tokenModel.displayName = this.idToken.displayName;
        if (this.tokenModel.displayName.length > 13) {
          this.tokenModel.displayName = this.tokenModel.displayName.substr(0, 12) + '...';
        }
        this.tokenModel.cutomers = this.idToken.customer;
        this.idToken$.next(this.idToken);
        let user ={
          id : this.idToken.upn,
          email : this.idToken.emailAddress,
          firstName : this.idToken.firstName,
          lastName : this.idToken.lastName,
          userUPNId : this.idToken.upn,
          name:this.idToken.displayName,
          upn : this.idToken.upn
        }
        this.upn$.next(this.idToken.upn);
        this.userInfo$.next(user);
      } else {
        this.loggerService.logger({
          type: 'error',
          class: 'OktaService',
          method: 'getTheIdToken',
          message: 'Error, no user identity found.',
        });
      }
    },error => {
      this.loggerService.logger({
        type: 'error',
        class: 'OktaService',
        method: 'getTheIdToken',
        message: 'Error getting user identity.',
        error
      });
    });

    // Get Access Token
    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      if(!isAuthenticated){
        return;
      }
      const accessToken = this.getAccessToken();
      // localStorage.setItem('accessToken', accessToken);
      this.tokenModel.token = 'Bearer ' + accessToken;
      this.accessToken$.next(accessToken);
    },error => {
      this.logMsg = {
        type: 'error',
        page: 'oktaService',
        method: 'getAccessToken',
        message: 'Error getting access token.',
        error
      };
    });
  }

  

  //get session for azure b2c
  getSession() {
    try{
      this.upn = this.authService.upn;
      this.upn$.next(this.upn);
      this.getTheIdToken();
    }
    catch(err){
      this.logout();
    }
  }

}
