import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IoMessageBarService } from '@io-angular/core';
import * as moment from 'moment';
import { env } from 'process';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { OktaService } from 'src/app/factory/okta.service';
import { ClientModel } from 'src/app/models/client.model';
import { DownloadItem, DownloadItemStatus } from 'src/app/models/download-item.model';
import { MenuService } from 'src/app/services/menu.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { environment } from '../../environments/environment';
import { AgingReportGridConstants } from '../rli/mrr/m-shared/m-constants/h-aging-report-constants';
import { OverviewSharedService } from '../rli/mrr/m-shared/m-services/overview-shared.service';
import { ApiKeywords } from '../rli/shared/constants/api-keywords';
import { HomeConstants } from '../rli/shared/constants/home-constants';
import { AppSetting } from '../rli/shared/constants/rli-global';
import { LookerApiService } from '../rli/shared/services/looker-api.service';
import { LogTypes } from '../shared/constants/log-types';
import { LookerConstants } from '../shared/constants/looker-constants';
import { RequestCategory } from '../shared/constants/request-category';
import { FileDownloadPopupComponent } from '../shared/download/file-download-popup/file-download-popup.component';
import { HttpErrorHandler } from '../shared/helpers/http-error-handler';
import { LoggerModel } from '../shared/models/logger-model';
import { formatNumber } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
export enum DOWNLOAD_FILE_TYPE {
    EXCEL = "excel",
    PDF = "pdf",
    CSV = "csv"
}

export enum DOWNLOAD_SELECTED_TAB {
    OVERVIEW = 'overview',
    ACTUAL_VS_FORECAST = 'actual-vs-forecast',
    AGING_REPORT = 'aging-report',
    ACTIVITY_LOG = 'activity-log',
    TREND = 'trend',
    EXTRACT = 'extract'
}

export enum DOWNLOAD_OPPORTUNITY_TAB {
    RISK_OPPORTUNITY = 'Risk Opportunity Identified',
    EXTRACT = 'oi-extract',
}

export enum DOWNLOAD_ES_TAB {
    EXTRACT = 'es-extract',
}

export enum DOWNLOAD_CONVERGED_TAB {
    CONVERGED_CARE_GAPS = 'Converged Care Gaps',
    EXTRACT = 'ccg-extract',
}
export enum DOWNLOAD_QPI_EXTRACT_TAB {
    EXTRACT = 'rm-extract',
}

export enum DOWNLOAD_MP_TAB {
    MEASURE_PERFORMANCE = 'Measure Performance',
    EXTRACT = 'mp-extract',
}

export enum DOWNLOAD_QPI_RM_RO_TAB {
    RATE_OVERVIEW = 'Rate Overview'
}

export enum DOWNLOAD_QPI_RM_RC_TAB {
    ANALYTICS_RUN = 'Analytics Run',
    BENCHMARKS = 'Benchmarks'
}

export enum DOWNLOAD_MD_TAB {
    MEASURE_DEMOGRAPHICS = 'Measure Demographics',
    EXTRACT = 'mp-extract',
    GEOGRAPHIC_DISTRIBUTION = 'Geographic Distribution',
    GENDER_DISTRIBUTION = 'Age-Gender Distribution',
    RACE_DISTRIBUTION = 'Race Distribution',
    ETHNICITY_DISTRIBUTION = 'Ethnicity Distribution'
}

export enum DOWNLOAD_VBPI_MP_PD {
    PD_BY_MEASURE_REQUEST_NAME = 'Patient Measure Data'
}
export enum DOWNLOAD_VBPI_PD_BY_PATIENT {
    PD_BY_PATIENT_REQUEST_NAME = 'Patient Detial By Patient Data'
}
export enum DOWNLOAD_VBPI_PO_OS {
    PO_OS_REQUEST_NAME = 'Overall Summary Data'
}

export enum DOWNLOAD_VBPI_PO_PM {
    PM_FOR_PS_REQUEST_NAME = 'Program Measures - Full List'
}

export enum DOWNLOAD_VBPI_PP_MG {
    MC_BY_MEASURE_REQUEST_NAME = 'Medical Group Scorecard Data'
}
export enum DOWNLOAD_VBPI_PP_CS {
    CLINIC_SCORECARD_REQUEST_NAME = 'Clinic Scorecard Data'
}
export enum DOWNLOAD_VBPI_PP_PS {
    PRACTITIONER_SCORECARD_REQUEST_NAME = 'Practitioner Scorecard Data'
}
export enum DOWNLOAD_MSI_TAB {
    MD_AGE_GENDER_DISTRIBUTION = 'Age-Gender Distribution by Line of Business',
    MD_ENROLLMENT_BY_GEOGRAPHY = 'Enrollment By Geography',
    PD_MEDICAL_GROUP = 'No. of Members by Medical Group',
    PD_CLINIC = 'No. of Members by Clinic',
    PD_PROVIDER = 'No. of Members by Provider',
    ET_ADDED_DROPPED_MEMBER_COUNT = 'Added and Dropped Member Counts',
    ET_ENROLLMENT_COUNT = 'Enrollment Count',
    ET_ENROLLMENT_TREND_DETAIL = 'Enrollment Trend Detail',
    EXTRACT = 'mi-extract'
}

export enum DOWNLOAD_FIA_EXTRACT_TAB {
    OVERVIEW = 'overview',
    EXTRACT = 'extract'
}

@Injectable({
    providedIn: 'root'
})
export class DownloadFileService {
    private readonly DAS_POLLING_URL: string = environment.DAS_REQ_PARAM_URL;
    private readonly EXCEL_URL: string = environment.EXCEL_URL;
    private readonly ES_EXCEL_URL: string = environment.ES_DEV_API_URL;
    private readonly FRS_URL: string = environment.DEV_FILE_URL;
    private readonly downloadPath: string = "/v1/file"
    public static readonly FILE_EXPIRY_HOURS: number = 72;
    private _availableCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0)
    availableCount$: Observable<number> = this._availableCount$.asObservable()
    upn = '';
    isRM = true;
    client: ClientModel;
    chartsOrPursuits: string = HomeConstants.CHARTS_TEXT;
    vbpiProdOrPreProd: string = "";
    contractedToggle: boolean = false;
    qualityToggle:boolean = false;
    constructor(
        private oktaService: OktaService,
        private http: HttpClient,
        private menuService: MenuService,
        private loggerService: LoggerService,
        private ioMessageBarService: IoMessageBarService,
        private overviewSharedService: OverviewSharedService,
        private httpErrorHandler: HttpErrorHandler,
        private lookerApiService: LookerApiService
    ) {
        this.oktaService.client$.subscribe((client) => {
            this.client = client;
        });

        this.oktaService.upn$.subscribe((upn) => {
            if (upn !== '') {
                this.upn = upn;
            }
        });

        this.overviewSharedService.getChartsOrPursuits().subscribe(chartsOrPursuits => {
            this.chartsOrPursuits = chartsOrPursuits;
        });

    }

    getHeaders() {
        let headerOptions = { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.oktaService.getAccessToken()) };
        return headerOptions
    }

    triggerFileDownload(requestName: string, requestType: DOWNLOAD_FILE_TYPE, filters: any, count: number, coverpageFields: any[], userFilters: any, pivots: string[] = [], sort: any, comment: string = "", type: string = "", comparisonFilters?, metricToCompare?: string): Observable<any> {
        let filename = '';
        let uiDisplayName = '';
        if (requestName == LookerConstants.INDICES_MRR_SITE_CHARTS || requestName == LookerConstants.INDICES_MRR_SITE_PURSUITS) {
            uiDisplayName = 'Site Details';
        } else if (requestName == LookerConstants.INDICES_MRR_SITE_MRR_STATUS_CHARTS || requestName == LookerConstants.INDICES_MRR_SITE_MRR_STATUS_PURSUITS) {
            uiDisplayName = 'Status Breakdown';
        } else if (requestName == LookerConstants.INDICES_MRR_AF_CHARTS || requestName == LookerConstants.INDICES_MRR_AF_PURSUITS) {
            uiDisplayName = 'Actuals vs Forecasts';
        } else if (requestName == LookerConstants.INDICES_MRR_AGING_CHARTS || requestName == LookerConstants.INDICES_MRR_AGING_PURSUITS) {
            uiDisplayName = 'Aging Report';
        } else if (requestName == LookerConstants.INDICES_MRR_ACTIVITY_LOG_CHARTS || requestName == LookerConstants.INDICES_MRR_ACTIVITY_LOG_PURSUITS) {
            uiDisplayName = 'Site Activity Details';
        } else if (requestName == LookerConstants.INDICES_MRR_EXTRACT || requestName == LookerConstants.INDICES_MRR_EXTRACT_ACTIVITY_DRILL_DOWN) {
            uiDisplayName = 'Extract';
        } else if (requestName == LookerConstants.INDICES_MRR_TREND_WEEKLY_ABS_CHARTS
            || requestName == LookerConstants.INDICES_MRR_TREND_WEEKLY_CHNG_CHARTS
            || requestName == LookerConstants.INDICES_MRR_TREND_MONTHLY_ABS_CHARTS
            || requestName == LookerConstants.INDICES_MRR_TREND_MONTHLY_CHNG_CHARTS
            || requestName == LookerConstants.INDICES_MRR_TREND_WEEKLY_ABS_PURSUITS
            || requestName == LookerConstants.INDICES_MRR_TREND_WEEKLY_CHNG_PURSUITS
            || requestName == LookerConstants.INDICES_MRR_TREND_MONTHLY_ABS_PURSUITS
            || requestName == LookerConstants.INDICES_MRR_TREND_MONTHLY_CHNG_PURSUITS
        ) {
            uiDisplayName = 'Trend';
        }
        // <--OPPORTUNITY IDENTIFIED
        else if (requestName == LookerConstants.OPPORTUNITY_BY_GAP_CONFIDENCE_LEVEL) {
            uiDisplayName = LookerConstants.OI_OPPORTUNITY_BY_GAP_CONFIDENCE_SHEETNAME;
        }
        else if (requestName == LookerConstants.MEMBER_LEVEL_POTENTIAL_FACTOR) {
            uiDisplayName = LookerConstants.OI_MEMBER_LEVEL_POTENTIAL_FACTOR_SHEETNAME;
        }
        else if (requestName == LookerConstants.OPPORTUNITY_BY_CONDITION_CATEGORY) {
            uiDisplayName = LookerConstants.OI_OPPORTUNITY_BY_CONDITION_CATEGORY_SHEETNAME;
        }
        else if (requestName == LookerConstants.EXTRACT_MEMBER_DETAIL) {
            uiDisplayName = LookerConstants.OI_EXTRACT_MEMBER_DETAIL_SHEETNAME;
        }
        // OPPORTUNITY IDENTIFIED-->

        // <--CONVERGED CARE GAPS
        else if (requestName == LookerConstants.CCG_EXTRACT_MEMBER_DETAIL) {
            uiDisplayName = LookerConstants.CCG_EXTRACT_MEMBER_DETAIL_SHEETNAME;
        }

        else if (requestName == LookerConstants.CCG_TOP_GAPS_BY_GAP_DESCRIPTION) {
            uiDisplayName = LookerConstants.CCG_TOP_GAPS_BY_GAP_DESCRIPTION_FILENAME;
        }

        else if (requestName == LookerConstants.TOP_MCP_GRID_PROVIDER) {
            uiDisplayName = LookerConstants.TOP_MCP_GRID_PROVIDER_SHEETNAME;
        }
        else if (requestName == LookerConstants.TOP_MCP_GRID_CLINIC) {
            uiDisplayName = LookerConstants.TOP_MCP_GRID_CLINIC_REPORT_SHEETNAME;
        }
        else if (requestName == LookerConstants.TOP_MCP_GRID_MEDICAL_GROUP) {
            uiDisplayName = LookerConstants.TOP_MCP_GRID_MEDICAL_GROUP_SHEETNAME;
        }
        else if (requestName == LookerConstants.CCG_QUALITY_GAPS) {
            uiDisplayName = LookerConstants.CCG_QUALITY_GAPS_SHEETNAME;
        }
        else if (requestName == LookerConstants.CCG_RISK_GAPS) {
            uiDisplayName = LookerConstants.CCG_RISK_GAPS_SHEETNAME;
        }
        // CONVERGED CARE GAPS-->
        // QPI MEASURE PERFORMANCE MP TAB
        else if (requestName == LookerConstants.QPI_MP_CURRENT_MEASURE_PERFORMANCE) {
            uiDisplayName = LookerConstants.QPI_MP_CURRENT_MEASURE_PERFORMANCE_SHEETNAME;
        }

        // QPI MEASURE PERFORMANCE MD TAB
        else if (requestName == LookerConstants.QPI_MD_CURRENT_MEASURE_PERFORMANCE) {
            uiDisplayName = LookerConstants.QPI_MD_CURRENT_MEASURE_PERFORMANCE_SHEETNAME;
        } else if (requestName == LookerConstants.QPI_MP_ADMINISTRATIVE_RATE_TREND) {
            uiDisplayName = LookerConstants.QPI_MP_ADMINISTRATIVE_RATE_TREND_SHEETNAME;
        } else if (requestName == LookerConstants.QPI_MP_ADMINISTRATIVE_RATE_TREND_CHILD) {
            uiDisplayName = LookerConstants.QPI_MP_ADMINISTRATIVE_RATE_TREND_CHILD_SHEETNAME;
        } else if (requestName == LookerConstants.QPI_MP_COMPARE_CURRENT_RATE) {
            uiDisplayName = LookerConstants.QPI_MP_COMPARE_CURRENT_RATE_SHEETNAME;
        }

        //QPI Rate Comparison
        else if (requestName == LookerConstants.RM_ANALYTIC_RUN_DOWNLOAD) {
            uiDisplayName = LookerConstants.RM_ANALYTIC_RUN_SHEETNAME;
        }

        else if (requestName == LookerConstants.RM_BENCHMARKS_DOWNLOAD) {
            uiDisplayName = LookerConstants.RM_BENCHMARKS_SHEETNAME;
        }

        //QPI Rate Overview
        else if (requestName == LookerConstants.QPI_RM_RATE_OVERVIEW_DOWNLOAD) {
            uiDisplayName = LookerConstants.QPI_RM_RATE_OVERVIEW_SHEETNAME;
        }
        //QPI MP MD GEOGRAPHIC DISTRIBUTION TAB
        else if (requestName == LookerConstants.QPI_MD_GEOGRAPHIC_DISTRIBUTION) {
            uiDisplayName = LookerConstants.QPI_MD_GEOGRAPHIC_DISTRIBUTION_SHEETNAME;
        }
        //QPI MP MD ETHNICITY DISTRIBUTION TAB
        else if (requestName == LookerConstants.QPI_MD_ETHNICITY_DISTRIBUTION) {
            uiDisplayName = LookerConstants.QPI_MD_ETHNICITY_DISTRIBUTION_SHEETNAME;
        }

        //QPI MP MD AGE_GENDER DISTRIBUTION TAB
        else if (requestName == LookerConstants.QPI_MD_AGE_GENDER_DISTRIBUTION) {
            uiDisplayName = LookerConstants.QPI_MD_AGE_GENDER_DISTRIBUTION_SHEETNAME;
        }

        //QPI MP MD RACE DISTRIBUTION TAB
        else if (requestName == LookerConstants.QPI_MD_RACE_DISTRIBUTION) {
            uiDisplayName = LookerConstants.QPI_MD_RACE_DISTRIBUTION_SHEETNAME;
        }

        //MSI MD AGE GENDER DISTRIBUTION GRID
        else if (requestName == LookerConstants.MI_MD_AGE_GENDER_DISTRIBUTION) {
            uiDisplayName = LookerConstants.MI_MD_AGE_GENDER_DISTRIBUTION_SHEETNAME;
        }

        //MSI PD NOS OF MEMBERS BY MEDICAL GROUP GRID
        else if (requestName == LookerConstants.MI_PD_NUMBER_OF_MEMBERS_BY_MEDICAL_GROUP) {
            uiDisplayName = LookerConstants.MI_PD_NUMBER_OF_MEMBERS_BY_MEDICAL_GROUP_SHEETNAME;
        }

        //MSI PD NOS OF MEMBERS BY CLINIC GRID
        else if (requestName == LookerConstants.MI_PD_NUMBER_OF_MEMBERS_BY_CLINIC) {
            uiDisplayName = LookerConstants.MI_PD_NUMBER_OF_MEMBERS_BY_CLINIC_SHEETNAME;
        }

        //MSI PD NOS OF MEMBERS BY PROVIDER GRID
        else if (requestName == LookerConstants.MI_PD_NUMBER_OF_MEMBERS_BY_PROVIDER) {
            uiDisplayName = LookerConstants.MI_PD_NUMBER_OF_MEMBERS_BY_PROVIDER_SHEETNAME;
        }

        //VBPI 4.0 MEASURE PERFORMANCE CP PATIENT GAP WORKLIST DOWNLOAD
        else if (requestName == LookerConstants.VBPI_PD_BY_MEASURE) {
            uiDisplayName = LookerConstants.VBPI_PD_BY_MEASURE_SHEETNAME;
        }
        //VBPI 4.0 MEASURE PERFORMANCE CP PATIENT GAP WORKLIST DOWNLOAD
        else if (requestName == LookerConstants.VBPI_PD_BY_MEASURE_CONFIG) {
            uiDisplayName = LookerConstants.VBPI_PD_BY_MEASURE_CONFIG_SHEETNAME;
        }
        //MSI PD ENROLLMENT BY GEOGRAPHY GRID
        else if (requestName == LookerConstants.MI_MD_ENROLLMENT_BY_GEOGRAPHY) {
            uiDisplayName = LookerConstants.MI_MD_ENROLLMENT_BY_GEOGRAPHY_SHEETNAME;
        }
        else if (requestName == LookerConstants.VBPI_PD_BY_PATIENT) {
            uiDisplayName = LookerConstants.VBPI_BY_PATIENT_SHEETNAME;
        }

        else if (requestName == LookerConstants.VBPI_PP_MEDICAL_CODING_REQUEST_NAME) {
            uiDisplayName = LookerConstants.VBPI_PP_MEDICAL_CODING_SHEETNAME;
        }
        else if (requestName == LookerConstants.VBPI_PP_CLINIC_REQUEST_NAME) {
            uiDisplayName = LookerConstants.VBPI_PP_CLINIC_SHEETNAME;
        }
        else if (requestName == LookerConstants.VBPI_PP_PRACTITIONER_REQUEST_NAME) {
            uiDisplayName = LookerConstants.VBPI_PP_PRACTITIONER_SHEETNAME;
        }
        //MSI ET ADDED AND DROPPED MEMBER COUNTS
        else if (requestName == LookerConstants.MI_ET_ADDED_DROPPED_MEMBER_COUNT) {
            uiDisplayName = LookerConstants.MI_ET_ADDED_DROPPED_MEMBER_COUNT_SHEETNAME;
        }

        //MSI ET ENROLLMENT COUNT
        else if (requestName == LookerConstants.MI_ET_ENROLLMENT_COUNT) {
            uiDisplayName = LookerConstants.MI_ET_ENROLLMENT_COUNT_SHEETNAME;
        }

        //MSI ET ENROLLMENT TREND DETAIL
        else if (requestName == LookerConstants.MI_ET_ENROLLMENT_TREND_DETAIL) {
            uiDisplayName = LookerConstants.MI_ET_ENROLLMENT_TREND_DETAIL_SHEETNAME;
        }

        //MSI ET EXTRACT
        else if (requestName == LookerConstants.MI_ET_EXTRACT) {
            uiDisplayName = LookerConstants.MI_ET_EXTRACT_SHEETNAME;
        }


        else if (requestName == LookerConstants.VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME_DOWNLOAD_TYPE) {
            uiDisplayName = LookerConstants.VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME_SHEETNAME;
        }
        else if (requestName == LookerConstants.VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME) {
            uiDisplayName = LookerConstants.VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME_EXCEL_SHEETNAME;
        } else if (requestName === LookerConstants.VBPI_PO_PROGRAM_MEASURE_STANDARD_SINGLE_REQUEST_NAME
            || requestName === LookerConstants.VBPI_PO_PROGRAM_MEASURE_STANDARD_MULTI_OR_STAR_REQUEST_NAME
            || requestName === LookerConstants.VBPI_PO_PROGRAM_MEASURE_STANDARD_RISK) {
            uiDisplayName = LookerConstants.VBPI_PO_PROGRAM_MEASURE_SHEETNAME;
        }

        if (requestName.includes('indicesvc-', 0)) {
            if (this.vbpiProdOrPreProd === "Pre-Production") {
                filters['envFlag'] = "";
                if (requestName == LookerConstants.VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME) {
                    filters['tsEnvFlag'] = "";
                }
            } else {
                filters['envFlag'] = ["1"];
                if (requestName == LookerConstants.VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME) {
                    filters['tsEnvFlag'] = ["1"];
                }
            }

            if (this.contractedToggle === false) {
                filters['isContracted'] = "";
                //sending null for isContracted in case of Patient Detail By Patient
                if (requestName == LookerConstants.VBPI_PD_BY_PATIENT) {
                    filters['isContracted'] = "null";
                }
                // if (requestName == LookerConstants.VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME) {
                //     filters['tsEnvFlag'] = "";
                // }
            } else {
                filters['isContracted'] = ["1"];
                // if (requestName == LookerConstants.VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME) {
                //     filters['tsEnvFlag'] = ["1"];
                // }
            }
            if (this.qualityToggle === false) {
                filters['isQualityOnly'] = "";
            } else {
                filters['isQualityOnly'] = ["1"];
            }
        }
        //FIA EXTRACT TAB DOWNLOAD SHEET DETAILS
        else if (requestName === LookerConstants.INDICES_FIA_EXTRACT) {
            uiDisplayName = LookerConstants.INDICES_FIA_EXTRACT_SHEETNAME;
        }
        else if (requestName === LookerConstants.MP_EXTRACT_MEMBER_DETAIL) {
            uiDisplayName = LookerConstants.MP_EXTRACT_MEMBER_DETAIL_SHEETNAME;
        } else if(requestName === LookerConstants.RM_EXTRACT_MEMBER_DETAIL) {
            uiDisplayName = LookerConstants.RM_EXTRACT_MEMBER_DETAIL_SHEETNAME
        }

        
        filters = this.createDownloadFormattedFilters(filters);
        filters['username'] = this.upn;
        let requestBody: any = {
            requestName,
            uiDisplayName,
            requestType,
            requestApp: AppSetting.RequestApp,
            upn: this.oktaService.upn,
            client: this.oktaService.client.value.clientName.toLowerCase(),
            clientDescription: (this.menuService.footerText) ? this.menuService.footerText : '',
            filters,
            count,
            type: type,
            userFilters,
            pivots,
            sort,
            comment,
            metricToCompare: metricToCompare
        }
        //delete sort param in case of QPI extract
        if (requestName == LookerConstants.MP_EXTRACT_MEMBER_DETAIL) {
            delete requestBody.sort;
        }
        if (coverpageFields.length) {
            //Changes added for Patient Detail By Patient
            requestBody.coverpageFields = coverpageFields;

        }
        if (requestName == LookerConstants.RM_ANALYTIC_RUN_DOWNLOAD) {
            requestBody.comparisonOneFilters = this.createDownloadFormattedFilters(comparisonFilters.comparisonOneFilters)
        }
        if (requestName == LookerConstants.QPI_MP_COMPARE_CURRENT_RATE) {
            requestBody.comparisonOneFilters = comparisonFilters.comparisonOneFilters
            requestBody.comparisonTwoFilters = comparisonFilters.comparisonTwoFilters
        }

        if (requestName == LookerConstants.QPI_MP_ADMINISTRATIVE_RATE_TREND) {
            requestBody.comparisonOneFilters = this.createDownloadFormattedFilters(comparisonFilters.comparisonOneFilters)
            requestBody.comparisonTwoFilters = this.createDownloadFormattedFilters(comparisonFilters.comparisonTwoFilters)
        }
        //ONLY FOR PATIENT DETAIL BY PATIENT EXTRA COL FIELD
        if (requestName == LookerConstants.VBPI_PD_BY_PATIENT) {
            requestBody.downloadType = 'type2';
        }

        return this.http.post(this.EXCEL_URL, requestBody, this.getHeaders()).pipe(
            tap((response: any) => {
                localStorage.setItem(response['requestId'], '' + this.isRM);
                const messageId = this.ioMessageBarService.addItem("information", `The file is being prepared. Your file will be available in the My Downloads folder shortly.`);
                setTimeout(() => {
                    this.ioMessageBarService.closeItem(messageId);
                }, 7000);
            }),
            catchError((error) => {
                let logObject: any = this.getLogObject(requestName, requestBody, RequestCategory.TRIGGER_DOWNLOAD);
                logObject.type = LogTypes.ERROR;
                this.httpErrorHandler.handleError(error, logObject);
                return of(new Array());
            })
        )
    }

    //this function converts serviceYear string value inside an array to a standalone int value and also formats the type field as well
    formatFilters(obj) {
        const formattedFilters = { ...obj };

        if (formattedFilters && formattedFilters.year && Array.isArray(formattedFilters.year) && formattedFilters.year.length === 1) {
            const yearValue = Number(formattedFilters.year[0]);
            if (!isNaN(yearValue)) {
                formattedFilters.year = yearValue;
            }
        }

        if (formattedFilters && formattedFilters.type && Array.isArray(formattedFilters.type) && formattedFilters.type.length === 1) {
            formattedFilters.type = formattedFilters.type[0];
        }

        if (!formattedFilters.hasOwnProperty("type")) {
            formattedFilters["type"] = "";
        }

        return formattedFilters;
    }


    triggerESFileDownload(requestName: string, requestType: string, filters: any, count: number, coverpageFields: any[], userFilters: any, pivots: string[] = [], sort: any, comment: string = "", type: string = "", comparisonFilters?, metricToCompare?: string): Observable<any> {
        let uiDisplayName = '';
        if (requestName == LookerConstants.ES_EXTRACT_PV_MEMBER_DETAIL) {
            uiDisplayName = LookerConstants.ES_EXTRACT_PV_SHEETNAME;
        } else if (requestName == LookerConstants.ES_EXTRACT_TCV_MEMBER_DETAIL) {
            uiDisplayName = LookerConstants.ES_EXTRACT_TCV_SHEETNAME;
        } else if (requestName == LookerConstants.ES_EXTRACT_VV_MEMBER_DETAIL) {
            uiDisplayName = LookerConstants.ES_EXTRACT_VV_SHEETNAME;
        }
        filters = this.formatFilters(filters);
        filters['username'] = this.upn;
        let requestBody: any = {
            requestName,
            uiDisplayName,
            requestType,
            requestApp: environment.REQUEST_APP_INDICES_ES,
            upn: this.oktaService.upn,
            client: this.oktaService.client.value.clientName.toLowerCase(),
            clientDescription: (this.menuService.footerText) ? this.menuService.footerText : '',
            filters,
            count,
            type: type,
            userFilters,
            pivots,
            comment,
            metricToCompare: metricToCompare,
            fields: coverpageFields
        }


        return this.http.post(this.ES_EXCEL_URL, requestBody, this.getHeaders()).pipe(
            tap((response: any) => {
                //localStorage.setItem(response['requestId'], '' + this.isRM);
                const messageId = this.ioMessageBarService.addItem("information", `The file is being prepared. Your file will be available in the My Downloads folder shortly.`);
                setTimeout(() => {
                    this.ioMessageBarService.closeItem(messageId);
                }, 7000);
            }),
            catchError((error) => {
                let logObject: any = this.getLogObject(requestName, requestBody, RequestCategory.TRIGGER_DOWNLOAD);
                logObject.type = LogTypes.ERROR;
                this.httpErrorHandler.handleError(error, logObject);
                return of(new Array());
            })
        )
    }

    updateFilter(appliedFilters, key, value) {
        let values = value.join(',')
        if (appliedFilters.hasOwnProperty(key)) {
            // scenario
            // Where filter is already applied from header filters, we will replace it with search filters
            if (value.length > 0) {
                appliedFilters[key] = values
            }
        } else {
            // Where filter were not applied. we are now creating it.

            if (value.length > 0) {
                appliedFilters[key] = values
            }
        }
        return appliedFilters
    }

    getCoverPageFiltersForDownload(appliedFilters, selectedTab, subtype = "", trendSuffix: string = "", tabLabels = {}) {
        let filters = {}
        let file_name: string = ""
        if (selectedTab == DOWNLOAD_SELECTED_TAB.OVERVIEW && subtype == "") {
            tabLabels = LookerConstants.OVERVIEW_COVER_PAGE_LABELS;
            file_name = LookerConstants.OVERVIEW_SITE_DETAILS_CHARTS_FILENAME;
            if (this.chartsOrPursuits === HomeConstants.PURSUITS_TEXT) {
                file_name = LookerConstants.OVERVIEW_SITE_DETAILS_PURSUITS_FILENAME;
            }
        }

        else if (selectedTab == DOWNLOAD_SELECTED_TAB.OVERVIEW && subtype == LookerConstants.INDICES_DOWNLOAD_OVERVIEW_SUBTYPE) {
            tabLabels = LookerConstants.OVERVIEW_COVER_PAGE_LABELS
            file_name = LookerConstants.OVERVIEW_MRR_STATUS_CHARTS_FILENAME;
            if (this.chartsOrPursuits === HomeConstants.PURSUITS_TEXT) {
                file_name = LookerConstants.OVERVIEW_MRR_STATUS_PURSUITS_FILENAME;
            }
        }

        else if (selectedTab == DOWNLOAD_SELECTED_TAB.ACTUAL_VS_FORECAST) {
            tabLabels = LookerConstants.ACTUAL_FORECAST_LABELS
            file_name = LookerConstants.AF_FILENAME
        }
        else if (selectedTab == DOWNLOAD_SELECTED_TAB.AGING_REPORT) {
            tabLabels = LookerConstants.AGING_COVER_PAGE_LABELS;
            file_name = LookerConstants.AGING_CHARTS_FILENAME;
            if (this.chartsOrPursuits === HomeConstants.PURSUITS_TEXT) {
                file_name = LookerConstants.AGING_PURSUITS_FILENAME;
            }
            // incase of aging, we need to send `vendor` in UI fields, while on other places its going in vendorId,
            //  so updating vendorId values to vendor in UiFields
            if (appliedFilters.hasOwnProperty(AgingReportGridConstants.Fields.VENDOR_ID)) {
                appliedFilters[LookerConstants.AGING_VENDOR_ID] = appliedFilters[AgingReportGridConstants.Fields.VENDOR_ID]
                delete appliedFilters[AgingReportGridConstants.Fields.VENDOR_ID]
            }
        }
        else if (selectedTab == DOWNLOAD_SELECTED_TAB.ACTIVITY_LOG) {
            tabLabels = LookerConstants.ACTIVITY_LOG_COVER_PAGE_LABELS
            file_name = LookerConstants.ACTIVITY_LOG_SITE_DETAILS_FILENAME

        }
        else if (selectedTab == DOWNLOAD_SELECTED_TAB.TREND) {
            tabLabels = LookerConstants.TREND_COVER_PAGE_LABELS
            file_name = LookerConstants.TREND_FILENAME + " " + trendSuffix
        }

        //FIA EXTRACT TAB
        else if (selectedTab === DOWNLOAD_FIA_EXTRACT_TAB.EXTRACT && subtype == LookerConstants.INDICES_DOWNLOAD_FIA_SUBTYPE) {
            tabLabels = LookerConstants.FIA_EXTRACT_COVERPAGE_LABELS;
            file_name = LookerConstants.INDICES_FIA_EXTRACT_FILENAME;
        }

        else if (selectedTab == DOWNLOAD_SELECTED_TAB.EXTRACT) {
            tabLabels = LookerConstants.EXTRACT_COVER_PAGE_LABELS;
            file_name = LookerConstants.EXTRACT_OVERVIEW_FILENAME;
            if (subtype == LookerConstants.INDICES_DOWNLOAD_ACTIVITY_DRILL_DOWN_TYPE) {
                delete tabLabels['healthplanMemberId'];
                file_name = LookerConstants.EXTRACT_ACTIVITY_DRILL_DOWN_FILENAME;
            }
        }

        // <--OPPORTUNITY IDENTIFIED
        else if (selectedTab === DOWNLOAD_OPPORTUNITY_TAB.RISK_OPPORTUNITY) {
            tabLabels = LookerConstants.OPPORTUNITY_RISK_COVERPAGE_LABELS;
            file_name = LookerConstants.OI_OPPORTUNITY_BY_GAP_CONFIDENCE_FILENAME;

            if (subtype == LookerConstants.OI_MEMBER_LEVEL_POTENTIAL_FACTOR_DOWNLOAD_TYPE) {
                file_name = LookerConstants.OI_MEMBER_LEVEL_POTENTIAL_FACTOR_FILENAME;
            }
            if (subtype == LookerConstants.OI_OPPORTUNITY_BY_CONDITION_CATEGORY_TYPE) {
                file_name = LookerConstants.OI_OPPORTUNITY_BY_CONDITION_CATEGORY_FILENAME;
            }
        } else if (selectedTab === DOWNLOAD_OPPORTUNITY_TAB.EXTRACT) {
            tabLabels = LookerConstants.OPPORTUNITY_RISK_COVERPAGE_LABELS;
            file_name = LookerConstants.OI_EXTRACT_MEMBER_DETAIL_FILENAME;
        }
        // OPPORTUNITY IDENTIFIED-->

        //MSI
        //MSI Measure Demographics
        else if (selectedTab === DOWNLOAD_MSI_TAB.MD_AGE_GENDER_DISTRIBUTION) {
            tabLabels = LookerConstants.MEMBERSHIP_INSIGHT_MD_COVERPAGE_LABELS;
            file_name = LookerConstants.MI_MD_AGE_GENDER_DISTRIBUTION_FILENAME;
        }

        else if (selectedTab === DOWNLOAD_MSI_TAB.PD_MEDICAL_GROUP) {
            tabLabels = LookerConstants.MEMBERSHIP_INSIGHT_PD_COVERPAGE_LABELS;
            file_name = LookerConstants.MI_PD_NUMBER_OF_MEMBERS_BY_MEDICAL_GROUP_FILENAME;
        }

        else if (selectedTab === DOWNLOAD_MSI_TAB.PD_CLINIC) {
            tabLabels = LookerConstants.MEMBERSHIP_INSIGHT_PD_COVERPAGE_LABELS;
            file_name = LookerConstants.MI_PD_NUMBER_OF_MEMBERS_BY_CLINIC_FILENAME;
        }

        else if (selectedTab === DOWNLOAD_MSI_TAB.PD_PROVIDER) {
            tabLabels = LookerConstants.MEMBERSHIP_INSIGHT_PD_COVERPAGE_LABELS;
            file_name = LookerConstants.MI_PD_NUMBER_OF_MEMBERS_BY_PROVIDER_FILENAME;
        }

        else if (selectedTab === DOWNLOAD_MSI_TAB.ET_ENROLLMENT_COUNT) {
            tabLabels = LookerConstants.MEMBERSHIP_INSIGHT_ET_COVERPAGE_LABELS;
            file_name = LookerConstants.MI_ET_ENROLLMENT_COUNT_FILENAME;
        }

        else if (selectedTab === DOWNLOAD_MSI_TAB.MD_ENROLLMENT_BY_GEOGRAPHY) {
            tabLabels = LookerConstants.MEMBERSHIP_INSIGHT_COVERPAGE_LABELS;
            file_name = LookerConstants.MI_MD_ENROLLMENT_BY_GEOGRAPHY_FILENAME;
        }

        else if (selectedTab === DOWNLOAD_MSI_TAB.ET_ADDED_DROPPED_MEMBER_COUNT) {
            tabLabels = LookerConstants.MEMBERSHIP_INSIGHT_ET_COVERPAGE_LABELS;
            file_name = LookerConstants.MI_ET_ADDED_DROPPED_MEMBER_COUNT_FILENAME;
        }

        else if (selectedTab === DOWNLOAD_MSI_TAB.ET_ENROLLMENT_TREND_DETAIL) {
            tabLabels = LookerConstants.MEMBERSHIP_INSIGHT_ET_COVERPAGE_LABELS;
            file_name = LookerConstants.MI_ET_ENROLLMENT_TREND_DETAIL_FILENAME;
        }

        else if (selectedTab === DOWNLOAD_MSI_TAB.EXTRACT) {
            tabLabels = LookerConstants.MEMBERSHIP_INSIGHT_EXTRACT_COVERPAGE_LABELS;
            file_name = LookerConstants.MI_ET_EXTRACT_FILENAME;
        }

        // <--CONVERGED CARE GAPS
        else if (selectedTab === DOWNLOAD_CONVERGED_TAB.CONVERGED_CARE_GAPS) {
            tabLabels = LookerConstants.CONVERGED_CARE_GAPS_COVERPAGE_LABELS;
            file_name = LookerConstants.CCG_TOP_GAPS_BY_GAP_DESCRIPTION_FILENAME;

            if (subtype == LookerConstants.CCG_TOP_GAPS_BY_GAP_DESCRIPTION_DOWNLOAD_TYPE) {
                file_name = LookerConstants.CCG_TOP_GAPS_BY_GAP_DESCRIPTION_FILENAME;
            }

            if (subtype == LookerConstants.TOP_MCP_GRID_PROVIDER) {
                file_name = LookerConstants.TOP_MCP_GRID_PROVIDER_REPORT_NAME;
            }
            if (subtype == LookerConstants.TOP_MCP_GRID_CLINIC) {
                file_name = LookerConstants.TOP_MCP_GRID_CLINIC_REPORT_NAME;
            }
            if (subtype == LookerConstants.TOP_MCP_GRID_MEDICAL_GROUP) {
                file_name = LookerConstants.TOP_MCP_GRID_MEDICAL_GROUP_REPORT_NAME;
            }
            if (subtype == LookerConstants.CCG_QUALITY_GAPS_DOWNLOAD_TYPE) {
                file_name = LookerConstants.CCG_QUALITY_GAPS_FILENAME;
            }
            if (subtype == LookerConstants.CCG_RISK_GAPS_DOWNLOAD_TYPE) {
                file_name = LookerConstants.CCG_RISK_GAPS_FILENAME;
            }
        } else if (selectedTab === DOWNLOAD_CONVERGED_TAB.EXTRACT) {
            tabLabels = LookerConstants.CONVERGED_CARE_GAPS_COVERPAGE_LABELS;
            Object.assign(tabLabels, LookerConstants.CCG_EXTRACT_MEMBERS_COVERPAGE_LABELS);
            file_name = LookerConstants.CCG_EXTRACT_MEMBER_DETAIL_REPORTNAME;
        }
        // CONVERGED CARE GAPS-->

        //MEASURE PERFORMANCE
        else if (selectedTab === DOWNLOAD_MP_TAB.MEASURE_PERFORMANCE) {
            tabLabels = LookerConstants.MEASURE_PERFORMANCE_COVERPAGE_LABELS;
            file_name = LookerConstants.QPI_MP_CURRENT_MEASURE_PERFORMANCE_FILENAME;

            if (subtype == LookerConstants.QPI_MP_CURRENT_MEASURE_PERFORMANCE_DOWNLOAD_TYPE) {
                file_name = LookerConstants.QPI_MP_CURRENT_MEASURE_PERFORMANCE_FILENAME;
            }

            if (subtype == LookerConstants.QPI_MP_ADMINISTRATIVE_RATE_TREND_CHILD_DOWNLOAD_TYPE) {
                file_name = LookerConstants.QPI_MP_ADMINISTRATIVE_RATE_TREND_CHILD_FILENAME;
            }
            if (subtype == LookerConstants.QPI_MP_ADMINISTRATIVE_RATE_TREND_DOWNLOAD_TYPE) {
                file_name = LookerConstants.QPI_MP_ADMINISTRATIVE_RATE_TREND_FILENAME;
            }
            if (subtype == LookerConstants.QPI_MP_COMPARE_CURRENT_RATE_DOWNLOAD_TYPE) {
                file_name = LookerConstants.QPI_MP_COMPARE_CURRENT_RATE_DOWNLOAD_FILENAME;
            }
            //  if (subtype == LookerConstants.QPI_MP_ADMINISTRATIVE_RATE_TREND_DOWNLOAD_TYPE) {
            //     file_name = LookerConstants.QPI_MP_ADMINISTRATIVE_RATE_TREND_FILENAME;
            // }
        } else if (selectedTab === DOWNLOAD_MP_TAB.MEASURE_PERFORMANCE) {
            tabLabels = LookerConstants.MEASURE_PERFORMANCE_COVERPAGE_LABELS;
            file_name = LookerConstants.QPI_MP_CURRENT_MEASURE_PERFORMANCE_FILENAME;

            if (subtype == LookerConstants.QPI_MP_CURRENT_MEASURE_PERFORMANCE_DOWNLOAD_TYPE) {
                file_name = LookerConstants.QPI_MP_CURRENT_MEASURE_PERFORMANCE_FILENAME;
            }
        } else if (selectedTab === DOWNLOAD_QPI_EXTRACT_TAB.EXTRACT) {
            tabLabels = LookerConstants.MEASURE_PERFORMANCE_COVERPAGE_LABELS;
            if (subtype == 'mp') {
                file_name = LookerConstants.MP_EXTRACT_MEMBER_DETAIL_FILENAME;
            } else {
                file_name = LookerConstants.RM_EXTRACT_MEMBER_DETAIL_FILENAME;
            }
        }

        //RATE OVERVIEW
        else if (selectedTab === DOWNLOAD_QPI_RM_RO_TAB.RATE_OVERVIEW) {
            tabLabels = LookerConstants.RATE_MONITORING_COVERPAGE_LABELS;
            file_name = LookerConstants.QPI_RM_RATE_OVERVIEW_FILENAME;
        } else if (selectedTab === DOWNLOAD_QPI_RM_RC_TAB.ANALYTICS_RUN) {
            tabLabels = LookerConstants.RATE_MONITORING_COVERPAGE_LABELS;
            file_name = LookerConstants.RM_ANALYTIC_RUN_FILENAME;
        } else if (selectedTab === DOWNLOAD_QPI_RM_RC_TAB.BENCHMARKS) {
            tabLabels = LookerConstants.RATE_MONITORING_COVERPAGE_LABELS;
            file_name = LookerConstants.RM_BENCHMARKS_FILENAME;
        }
        //Measure Demographics
        else if (selectedTab === DOWNLOAD_MD_TAB.MEASURE_DEMOGRAPHICS) {
            tabLabels = LookerConstants.MEASURE_PERFORMANCE_COVERPAGE_LABELS;
            file_name = LookerConstants.QPI_MD_CURRENT_MEASURE_PERFORMANCE_FILENAME;
        }
        else if (selectedTab === DOWNLOAD_MD_TAB.GEOGRAPHIC_DISTRIBUTION) {
            tabLabels = LookerConstants.MEASURE_PERFORMANCE_COVERPAGE_LABELS;
            file_name = LookerConstants.QPI_MD_GEOGRAPHIC_DISTRIBUTION_FILENAME;
        }
        else if (selectedTab === DOWNLOAD_MD_TAB.ETHNICITY_DISTRIBUTION) {
            tabLabels = LookerConstants.MEASURE_PERFORMANCE_COVERPAGE_LABELS;
            file_name = LookerConstants.QPI_MD_ETHNICITY_DISTRIBUTION_FILENAME;
        }
        else if (selectedTab === DOWNLOAD_MD_TAB.GENDER_DISTRIBUTION) {
            tabLabels = LookerConstants.MEASURE_PERFORMANCE_COVERPAGE_LABELS;
            file_name = LookerConstants.QPI_MD_AGE_GENDER_DISTRIBUTION_FILENAME;
        }
        else if (selectedTab === DOWNLOAD_MD_TAB.RACE_DISTRIBUTION) {
            tabLabels = LookerConstants.MEASURE_PERFORMANCE_COVERPAGE_LABELS;
            file_name = LookerConstants.QPI_MDRACE_DISTRIBUTION_FILENAME;
        }
        //VBPI 4.0 MEASURE PERFORMANCE CP PATIENT GAP WORKLIST DOWNLOAD
        else if (selectedTab === DOWNLOAD_VBPI_MP_PD.PD_BY_MEASURE_REQUEST_NAME && subtype == LookerConstants.VBPI_PD_BY_MEASURE) {
            tabLabels = tabLabels; // dynamically created from filter configuration
            file_name = LookerConstants.VBPI_PD_BY_MEASURE_FILENAME;
        }
        //VBPI 4.0 MEASURE PERFORMANCE CP PATIENT GAP WORKLIST DOWNLOAD
        else if (selectedTab === DOWNLOAD_VBPI_MP_PD.PD_BY_MEASURE_REQUEST_NAME && subtype == LookerConstants.VBPI_PD_BY_MEASURE_CONFIG) {
            tabLabels = tabLabels; // dynamically created from filter configuration
            file_name = LookerConstants.VBPI_PD_BY_MEASURE_CONFIG_FILENAME;
        }
        else if (selectedTab === DOWNLOAD_VBPI_PP_MG.MC_BY_MEASURE_REQUEST_NAME) {
            tabLabels = tabLabels; // dynamically created from filter configuration
            file_name = LookerConstants.VBPI_PP_MEDICAL_CODING_FILENAME;
        }
        else if (selectedTab === DOWNLOAD_VBPI_PP_CS.CLINIC_SCORECARD_REQUEST_NAME) {
            tabLabels = tabLabels; // dynamically created from filter configuration
            file_name = LookerConstants.VBPI_PP_CLINIC_FILENAME;
        }
        else if (selectedTab === DOWNLOAD_VBPI_PP_PS.PRACTITIONER_SCORECARD_REQUEST_NAME) {
            tabLabels = tabLabels; // dynamically created from filter configuration
            file_name = LookerConstants.VBPI_PP_PRACTITIONER_FILENAME;
        }
        //VBPI 4.0 PROGRAM OVERVIEW PROGRAM MEASURES FOR PROGRAM SUMMARY DOWNLOAD
        else if (selectedTab === DOWNLOAD_VBPI_PO_PM.PM_FOR_PS_REQUEST_NAME) {
            tabLabels = tabLabels;
            file_name = LookerConstants.VBPI_PO_PROGRAM_MEASURE_FILENAME;
        }
        else if (selectedTab === DOWNLOAD_VBPI_PD_BY_PATIENT.PD_BY_PATIENT_REQUEST_NAME) {
            tabLabels = tabLabels;
            file_name = LookerConstants.VBPI_PD_BY_PATIENT_FILENAME;
        }
        else if (selectedTab === DOWNLOAD_VBPI_PO_OS.PO_OS_REQUEST_NAME) {
            tabLabels = tabLabels;
            file_name = LookerConstants.VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME_FILENAME;
        }

        for (const key in tabLabels) {
            if (key == LookerConstants.REPORTING_POPULATION_ID) {
                filters[key] = [{
                    name: tabLabels[key],
                    value: LookerConstants.FILTERS_SHOW_ALL_POPULATION
                }];
            }
            else {
                filters[key] = [{
                    name: tabLabels[key],
                    value: LookerConstants.FILTERS_ALL
                }];
            }
        }

        for (let key in appliedFilters) {
            const optionsList = appliedFilters[key];
            if (optionsList.length > 0) {
                if (key === LookerConstants.CATALOG_NAME_ID) {
                    key = LookerConstants.CATALOG_ID;
                }
                if (key === LookerConstants.PROJECT_NAME_ID) {
                    key = LookerConstants.PROJECT_ID;
                }
                filters[key] = [];
                optionsList.forEach((option, idx) => {
                    let name = '';
                    if (idx == 0) {
                        name = tabLabels[key];
                    }
                    let obj = {
                        name: name,
                        value: option[option.displayField]
                    }
                    filters[key].push(obj)
                });
            }
        }


        for (const key in LookerConstants.COMMON_COVER_PAGE_LABELS) {
            if (key == LookerConstants.PAYER) {
                filters[key] = {
                    name: LookerConstants.COMMON_COVER_PAGE_LABELS[key],
                    value: this.client.clientName
                }

            }
            if (key == LookerConstants.REPORT) {
                filters[key] = {
                    name: LookerConstants.COMMON_COVER_PAGE_LABELS[key],
                    value: file_name
                }

            }
            if (key == LookerConstants.DOWNLOAD) {
                filters[key] = {
                    name: LookerConstants.COMMON_COVER_PAGE_LABELS[key],
                    value: new Date().toLocaleDateString()
                }

            }
        }
        return filters
    }

    openDownloadDialog(dialog: MatDialog, card_title = "", displayViewFullData = true) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true
        dialogConfig.closeOnNavigation = true
        dialogConfig.minWidth = "600px"
        dialogConfig.data = { "card_title": card_title, displayViewFullData: displayViewFullData }
        dialogConfig.disableClose = true
        return dialog.open(FileDownloadPopupComponent, dialogConfig)
    }

    getAllDownloadItems(): Observable<DownloadItem[]> {

        const wait$: Subject<[string, ClientModel]> = new Subject()

        combineLatest([this.oktaService.upn$, this.oktaService.client$]).pipe(debounceTime(100), takeUntil(wait$)).subscribe(([upn, client]) => {
            if (upn && client) {
                wait$.next([upn, client])
                wait$.complete()
            }
        }, err => {
            wait$.error(err)
        })
        return wait$.pipe(
            switchMap(([upn, client]) => {
                const requestBodyMRR: any = {
                    applicationNames: [environment.REQUEST_APP_INDICES, environment.REQUEST_APP_INDICES_OPPORTUNITY, environment.REQUEST_APP_INDICES_CA, environment.REQUEST_APP_INDICES_QPI, environment.REQUEST_APP_INDICES_VBPI, environment.REQUEST_APP_INDICES_MSI, environment.REQUEST_APP_INDICES_FIA, environment.REQUEST_APP_INDICES_ES],
                    upn: this.oktaService.upn,
                    clientName: this.oktaService.client.value.clientName.toLowerCase(),
                }
                return this.http.post<[]>(this.DAS_POLLING_URL, requestBodyMRR, this.getHeaders())
                    .pipe(
                        map((data) => {
                            if (data) {
                                const list: DownloadItem[] = data.map(item => {
                                    return <DownloadItem>{
                                        comment: item["comment"],
                                        completionDate: (item["completionDate"]) ? moment.utc(item["completionDate"]) : null,
                                        extractPath: item["extractPath"],
                                        id: item["requestId"],
                                        requestName: item["requestName"],
                                        requestDate: moment.utc(new Date(item["requestDate"])),
                                        status: item["requestStatus"]
                                    }
                                })
                                let filteredList: DownloadItem[] = list.filter((item: DownloadItem) => {
                                    if (item.completionDate) {
                                        const hoursDiff: number = moment().diff(item.completionDate, 'hours')
                                        if (hoursDiff >= DownloadFileService.FILE_EXPIRY_HOURS) {
                                            return false
                                        }
                                    }
                                    return true
                                })

                                filteredList = filteredList.sort((a: DownloadItem, b: DownloadItem) => {
                                    if (a.requestDate.isAfter(b.requestDate)) {
                                        return -1
                                    } else if (a.requestDate.isBefore(b.requestDate)) {
                                        return 1
                                    }
                                    return 0
                                })

                                const availableCount: number = filteredList.filter(item => item.status == DownloadItemStatus.COMPLETED).length
                                this._availableCount$.next(availableCount)
                                return filteredList
                            }
                            return []
                        })
                    )
            })
        )
    }

    downloadFile(downloadedItem: DownloadItem, fileName: string): Observable<any> {
        const requestId = downloadedItem.id;
        const requestStatus: DownloadItemStatus = downloadedItem.status;
        const fileExt = downloadedItem.extractPath && downloadedItem.extractPath.split(".")[1];
        const fullFileName = `${fileName}.${fileExt}`;
        let contentType = '';
        switch (fileExt) {
            case "zip":
                contentType = "application/zip";
                break;
            default:
                contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                break;
        }

        const url: string = this.FRS_URL + this.downloadPath;
        const requestBody: any = {
            requestId,
            requestStatus
        }
        let header = this.getHeaders();
        header['observe'] = 'response';
        header['responseType'] = 'blob' as 'json';

        return this.http.post(url, requestBody, header).pipe(
            tap((response: any) => {
                let binaryData = [];
                binaryData.push(response.body);
                let downloadLink = document.createElement('a');
                const fileData = new Blob(binaryData, { type: contentType });
                downloadLink.href = window.URL.createObjectURL(fileData);
                downloadLink.setAttribute('download', fullFileName);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }),
            catchError(error => {
                /* this.loggerService.logger({
                     type: 'error',
                     class: 'DownloadFileService',
                     method: 'downloadFile',
                     message: 'Error downloading file.',
                     err
                 });
                 throw err*/
                let logObject: any = this.getLogObject(url, requestBody, RequestCategory.DOWNLOAD_FILE);
                logObject.type = LogTypes.ERROR;
                logObject.url = url;
                this.httpErrorHandler.handleError(error, logObject);
                return of(new Array());
            })
        );
    }

    createDownloadFormattedFilters(filters?: any) {
        let formattedFilter = {};
        let keys = (filters) ? Object.keys(filters) : [];
        keys.forEach((key) => {
            if (Array.isArray(filters[key])) {
                let vals = filters[key].map(k => {
                    if (typeof k !== 'string') {
                        return k;
                    }

                    k = k.replace('^', '^^');
                    k = k.replace(/([,%_])/g, '^$1');
                    if (k && k.charAt(0) === '-') {
                        k = ('^').concat(k);
                    }
                    return k;
                });
                filters[key] = vals;
                let values = filters[key].join(ApiKeywords.COMMA);
                if (values)
                    formattedFilter[key] = values;
            }
            else if (key === 'envFlag' || key === 'tsEnvFlag') {
                formattedFilter[key] = "";
            }
            else {
                if (filters[key]) {
                    formattedFilter[key] = filters[key].toString().replace('^', '^^').replace(/([,%_])/g, '^$1');
                    if (formattedFilter[key] && formattedFilter[key].charAt(0) === '-') {
                        formattedFilter[key] = ('^').concat(formattedFilter[key]);
                    }
                }
            }
        });
        return formattedFilter;
    }

    getLogObject(requestName: string, requestBody: any, requestCategory: string = RequestCategory.TRIGGER_DOWNLOAD) {
        let logObject = new LoggerModel();
        logObject.lookName = requestName;
        logObject.requestCategory = requestCategory;
        logObject.requestBody = requestBody;
        return logObject;
    }

    showMaxRecordsMessage(message) {
        const messageId = this.ioMessageBarService.addItem('warning', message);
        setTimeout(() => {
            this.ioMessageBarService.closeItem(messageId);
        }, 7000);
    }

    showMaxRecordsMessageForQPIandMI(recordsCount : number) {
        const message = `The ${formatNumber(recordsCount, 'en-US')} records exceeded the maximum download limit. Only the first 1,000,000 records will be downloaded. Apply additional filters to see full results.`;
        const messageId = this.ioMessageBarService.addItem('warning', message);
        setTimeout(() => {
            this.ioMessageBarService.closeItem(messageId);
        }, 7000);
    }

}
