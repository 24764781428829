
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IndicesSearchService {
  baseUrl = localStorage.getItem('indicesAppUrl');
  constructor() { }

  redirectToIndicesSearch(formValues: any, action: string) {
    const queryString = this.getQueryString(formValues);
    const idp = localStorage.getItem("idp");
    if(idp && ["okta","b2c","b2c-federated"].includes(idp)){
      window.location.href = this.baseUrl + action + '?' + queryString + '&chkClt=1'+`&idp=${idp}`;
    }else{
      window.location.href = this.baseUrl + action + '?' + queryString + '&chkClt=1';
    } 
  }

  getQueryString(formObject): string {
    const queryString = Object.keys(formObject).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(formObject[key]);
    }).join('&');
    return queryString;
  }
}
