
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Component, OnInit, HostListener, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IndicesSearchService } from 'src/app/services/indices-search.service';
import { IoNotificationService } from '@io-angular/core';

@Component({
  selector: 'app-search-menu-item',
  templateUrl: './search-menu-item.component.html',
  styleUrls: ['./search-menu-item.component.css']
})
export class SearchMenuItemComponent implements OnInit {
  constructor(private eRef: ElementRef,
              private indicesSearchService: IndicesSearchService,
              private notificationService: IoNotificationService) { }

  @Input() searchMenu: any[];
  isSearchMenuOpen = false;

  providerProfileEnabled = false;
  memberProfileEnabled = false;
  providerGroupEnabled = false;
  portalSearchEnabled = false;

  providerProfileExpanded = false;
  memberProfileExpanded = false;
  providerGroupExpanded = false;
  portalSearchExpanded = false;

  providerProfileForm: UntypedFormGroup = new UntypedFormGroup({
    providerfirstname: new UntypedFormControl(''),
    providerlastname: new UntypedFormControl(''),
    providernpi: new UntypedFormControl('')
  }, this.AtLeastOneFieldValidator);

  providerGroupForm: UntypedFormGroup = new UntypedFormGroup({
    provider_group_id: new UntypedFormControl(''),
    providergroup: new UntypedFormControl('')
  }, this.AtLeastOneFieldValidator);

  memberProfileForm: UntypedFormGroup = new UntypedFormGroup({
    firstname: new UntypedFormControl(''),
    lastname: new UntypedFormControl(''),
    memberid: new UntypedFormControl(''),
    subscriberkey: new UntypedFormControl('')
  }, this.AtLeastOneFieldValidator);

  portalSearchForm: UntypedFormGroup = new UntypedFormGroup({
    keyword: new UntypedFormControl('')
  }, this.AtLeastOneFieldValidator);

  ngOnInit() {
    this.viewSearchMenu();
  }

  viewSearchMenu() {
    if (this.searchMenu && this.searchMenu[0] && this.searchMenu[0].children.length > 0) {
      const searchMenuItems = this.searchMenu[0].children;
      this.providerProfileEnabled = this.hasProp(searchMenuItems, 'Find Provider Profile');
      this.providerGroupEnabled = this.hasProp(searchMenuItems, 'Find Provider Group');
      this.memberProfileEnabled = this.hasProp(searchMenuItems, 'Find Member Profile');
      this.portalSearchEnabled = this.hasProp(searchMenuItems, 'Search Portal');
    }
  }

  hasProp(searchMenuItems, searchMenuName) {
    return searchMenuItems.filter(menuItem => menuItem.name === searchMenuName).length > 0;
  }

  onSubmit(form: UntypedFormGroup, element: Element) {
    if (form.errors) {
      this.notificationService.error('Please input at least one field');
    } else {
      this.indicesSearchService.redirectToIndicesSearch(form.value, element.getAttribute('action'));
    }
  }

  AtLeastOneFieldValidator(group: UntypedFormGroup): { [key: string]: any } {
    let isAtLeastOne = false;
    if (group && group.controls) {
      for (const control in group.controls) {
        if (group.controls.hasOwnProperty(control) && group.controls[control].valid && group.controls[control].value) {
          isAtLeastOne = true;
          break;
        }
      }
    }
    return isAtLeastOne ? null : { required: true };
  }

  @HostListener('window:click', ['$event'])
  hideMenu(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isSearchMenuOpen = false;
    }
  }

}


