import * as moment from 'moment';

export interface DownloadItem {
    extractPath:string,
    completionDate: moment.Moment,
    comment: string
    requestDate:moment.Moment,
    status: DownloadItemStatus,
    id:string,
    requestName:string
}

export enum DownloadItemStatus {
    COMPLETED = "Available",
    INITIALIZED = "Processing",
    DOWNLOADED = "Downloaded",
    FAILED = "Failed"
}