<!--
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
-->

<div class="side-menu" id="sideMenu" [class.menu-collapsed]="isMenuCollapsed">
    <!-- Menu Display Toggle -->
    <div class="side-menu__controls clearfix">
        <label>
            <input id="cbKeepOpen" type="checkbox" [checked]="menuService.keepOpen"
                (change)="menuService.keepOpen = !menuService.keepOpen">
            <span class="keep-open">Keep Open</span>
        </label>
        <button class="side-menu__toggle-menu-btn" (click)="toggleMenu()">
            <app-svg-icon name="expandarrow"></app-svg-icon>
        </button>
    </div>

    <div *ngIf="(menuService.isMenuLoaded$ | async)">
        <!-- Product Toggle -->
        <app-product-menu-item [products]="menuService.productMenus"></app-product-menu-item>

        <!-- Client Logo -->
        <div class="side-menu__logo--client">
            <div *ngIf="menuService.clientLogo">
                <a [attr.href]="redirectUrl">
                    <img [src]="domSanitizer.bypassSecurityTrustUrl(menuService.clientLogo)" alt="">
                </a>
            </div>
        </div>

        <!-- PowerBI Menu-->
        <app-utility-menu-item cssClass="primary-nav" [utilityMenu]="menuService.powerBIMenus"></app-utility-menu-item>
        
        <!-- Value-based Dashboard Menu -->
        <app-utility-menu-item cssClass="primary-nav" [utilityMenu]="menuService.valueBasedDashboardMenus">
        </app-utility-menu-item>
        
        <app-utility-menu-item cssClass="primary-nav" [utilityMenu]="menuService.valueCompass4DashboardMenu">
        </app-utility-menu-item>

        <!-- Membership Insights Menu -->
        <app-utility-menu-item cssClass="primary-nav" [utilityMenu]="menuService.membershipInsightsDashboardMenus">
        </app-utility-menu-item>

        <!-- Quality Performance Menu -->
        <app-utility-menu-item cssClass="primary-nav" [utilityMenu]="menuService.qualityPerfDashboardMenus">
        </app-utility-menu-item>
        
        <!-- Converged Gaps Menu -->
        <app-utility-menu-item cssClass="primary-nav" [utilityMenu]="menuService.convergedGapsDashboardMenus">
        </app-utility-menu-item>
        
        <!-- Risk Lifecycle Insights Menu -->
        <app-utility-menu-item cssClass="primary-nav" [utilityMenu]="menuService.riskLifecycleDashbaordMenus">
        </app-utility-menu-item>
        
        <!-- Dashboard Menu -->
        <app-dashboard-menu-item [dashboardMenu]="menuService.dashboardMenus"></app-dashboard-menu-item>

        <!-- Search Menu -->
        <app-search-menu-item [searchMenu]="menuService.searchMenus"></app-search-menu-item>

        <!-- Utility menu -->
        <app-utility-menu-item cssClass="side-menu__utility" [utilityMenu]="menuService.utilityMenus">
        </app-utility-menu-item>
    </div>
    <!-- User menu -->
    <app-user-menu-item></app-user-menu-item>

    <!-- Menu Footer Logo -->
    <div class="side-menu__logo--inovalon">
        <a href="https://www.inovalon.com/" target="_blank" data-url="" data-caption="Inovalon Logo">
            <img src="../../../assets/svg/poweredbyinovalon.svg" alt="Powered by Inovalon">
        </a>
    </div>

</div>