
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BreakpointState, BreakpointObserver } from '@angular/cdk/layout';
import { MenuService } from 'src/app/services/menu.service';
import { NavigationStart, Router } from '@angular/router';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { takeUntil } from 'rxjs/operators';
import { DownloadFileService } from 'src/app/services/download-file.service';
import { MenuConfigConstants } from 'src/app/shared/constants/menu-config';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})
export class TopNavComponent implements OnInit, OnDestroy {
  @Input() applicationTitle: string;
  @ViewChild('menuTrigger', { static: false }) trigger: MatMenuTrigger;
  isHandset: Observable<BreakpointState>;
  destroyer$: Subject<null> = new Subject()
  // availableCount$:Observable<number> = null
  applicationMenuTitle: string;

  private _availableCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  availableCount$: Observable<number> = this._availableCount$.asObservable()

  constructor(
    private breakpointObserver: BreakpointObserver,
    public menuService: MenuService,
    public router: Router,
    private loggerService: LoggerService,
    private downloadFileService: DownloadFileService
  ) {
    this.isHandset = this.breakpointObserver.observe('(max-width: 839px)');

    this.availableCount$ = this.downloadFileService.availableCount$
    this.availableCount$.subscribe(val => { 
    })

    this.menuService.routeUrl$.subscribe(routeUrl => {
      if(routeUrl)
      {
        switch(routeUrl.split('/')[0]){
          case 'mi': 
            this.applicationMenuTitle = MenuConfigConstants.ConvergedDataIngestion;
            break;
          case 'qpi':
            this.applicationMenuTitle = MenuConfigConstants.ConvergedQuality;
            break;
          case 'rli':
            const subMenu = routeUrl.split('/')[1];
            if(subMenu && subMenu === 'oi')
            {
              this.applicationMenuTitle = MenuConfigConstants.ConvergedRisk;
            } else {
              this.applicationMenuTitle = MenuConfigConstants.DataInsights;
            }
          
            break;
          case 'vbpi':
            this.applicationMenuTitle = MenuConfigConstants.ProviderEnablement;
            break;
          default:
            this.applicationMenuTitle = MenuConfigConstants.DataInsights;
            break;

        }
       } else
        {
          this.applicationMenuTitle = MenuConfigConstants.DataInsights;
        }
    });
  }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.destroyer$)).subscribe(val => {
      if (val instanceof NavigationStart && this.trigger) {
        this.trigger.closeMenu()
      }
    })
  }

  toggleLoggerServiceDebug() {
    this.loggerService.isDebug = !this.loggerService.isDebug;
  }

  ngOnDestroy() {
    this.destroyer$.next(null)
    this.destroyer$.complete()
  }

}
