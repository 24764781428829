
<!--
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
-->

<div class="side-menu__product-toggle"
     [class.current]="isProductMenuOpen">
    <div style="cursor: pointer;"
         (click)="isProductMenuOpen = !isProductMenuOpen"
         >
        <app-svg-icon name="indices"></app-svg-icon>
        <span class="link-text">Data Insights</span>
        <app-svg-icon class="pull-right hide-on-collapsed"
                      name="profil_portal_menu">
        </app-svg-icon>
    </div>
    <!-- <div>
        <app-svg-icon name="indices"></app-svg-icon>
        <span class="link-text" style="cursor: default;">Data Insights</span>
    </div> -->
    <div class="side-menu__product-toggle__list"
         [style.display]="isProductMenuOpen ? 'block' : 'none'">
        <div class="side-menu__product-toggle__list__header">
            Switch Product
        </div>
        <ul>
            <li *ngFor="let product of products">
                <a [href]="product.url">{{product.name}}</a>
            </li>
        </ul>
    </div>
</div>