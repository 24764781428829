<!--
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
-->

<nav mat-tab-nav-bar class="nav-bar-pagination">
    <a mat-tab-link *ngFor="let link of ioRoutList" [routerLink]="link.path" routerLinkActive #rla="routerLinkActive"
        [active]="activeRouteTab(rla, link)">
        {{link.label}}
    </a>
</nav>