<mat-card 
  class="mat-elevation-z0"
  [ngClass]="{
    'mat-elevation-z0': !ioHasShadow,
    'mat-elevation-z6': ioHasShadow,
    'no-border-radius': !ioHasBorderRadius,
    'has-border-radius': ioHasBorderRadius,
    'no-top-padding': hasNoTopPadding  || ioHasNoTopPadding,
    'no-right-padding': ioHasNoRightPadding ,
    'no-bottom-padding': ioHasNoBottomPadding ,
    'no-left-padding': ioHasNoLeftPadding }">
    <ng-content></ng-content>
</mat-card>