
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Component, OnInit, Input, ElementRef, HostListener, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-dashboard-menu-item',
  templateUrl: './dashboard-menu-item.component.html',
  styleUrls: ['./dashboard-menu-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardMenuItemComponent implements OnInit {

  @Input() dashboardMenu: any[];

  mainMenu: boolean[] = [];
  secondaryMenu: boolean[] = [];
  idp:string = "";
  domainHint:string = "";
  constructor(private eRef: ElementRef) { }

  ngOnInit() {
  }

  isValidIdp(){
    const idpItem = localStorage.getItem("idp");
    if(idpItem && ["okta","b2c","b2c-federated"].includes(idpItem)){
        this.idp = idpItem
        return true;
    }
    this.idp="";
    return false;
  }

  isValidDomainHint(){
    const domainHintItem = localStorage.getItem("domain_hint");
    const idp = localStorage.getItem("idp");
    if(idp && ["b2c-federated"].includes(idp) && domainHintItem){
      this.domainHint = domainHintItem;
      return true;
    }
    return false;
  }

  getFormattedURL(url:any){
    const idp = localStorage.getItem("idp");
    const domainHint = localStorage.getItem("domain_hint");
    let navURL = "";
    if(url.indexOf('?') == -1){
      navURL = url+"?";
    }else{
      navURL = url;
    }
    if(idp && ["okta","b2c","b2c-federated"].includes(idp)){
      navURL = navURL + 'chkClt=1'+`&idp=${idp}`;
      if(domainHint && ["b2c-federated"].includes(idp)){
        navURL += `&domain_hint=${domainHint}`;
      }
    }else{
      navURL = navURL+'chkClt=1';
    } 
    return navURL;  
  }

  setActiveMenu(id: number, event: Event) {
    const existingVal = this.mainMenu[id];
    this.mainMenu = this.secondaryMenu = [];
    this.mainMenu[id] = !existingVal;
  }

  getActiveMenu(id: number) {
    return this.mainMenu[id];
  }

  setActiveSubMenu(id: number, event: Event) {
    const existingVal = this.secondaryMenu[id];
    this.secondaryMenu = [];
    this.secondaryMenu[id] = !existingVal;
  }

  getActiveSubMenu(id: number) {
    return this.secondaryMenu[id];
  }

  @HostListener('window:click', ['$event'])
  hideMenu(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.mainMenu = this.secondaryMenu = [];
    }
  }

}
