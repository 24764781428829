import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OktaService } from 'src/app/factory/okta.service';

@Component({
  selector: 'app-invalidurl',
  templateUrl: './invalidurl.component.html',
  styleUrls: ['./invalidurl.component.css']
})
export class InvalidurlComponent implements OnInit {

  constructor(private router:Router) {
   }

  ngOnInit() { 
  }
}
