export enum AppConfig {
	PRODUCT_NAME = 'Data Insights',

	PORTAL_BASE_DOMAIN = 'inovalon.com',
	PORTAL_SUBDOMAIN_PREFIX = 'datainsights',
	PORTAL_LOCAL_URL = 'localhost:4200',
	OVERRIDE_IDP_METADATA = 'true',

	BASE_ROUTE_PATH = 'datainsights',

	COMPANY_HOME_URL = 'https://www.inovalon.com',
	OUR_COMPANY_URL = 'https://www.inovalon.com/our-company/',
	SUPPORT_URL = 'https://support.inovalon.com/hc/en-us',
	PRIVACY_POLICY_URL = 'https://www.inovalon.com/privacy-policy/',
	TERMS_OF_SERVICE_URL = 'https://www.inovalon.com/terms-of-use/',
	NEWS_URL = 'https://www.inovalon.com/our-company/news/',
	EVENTS_URL = 'https://www.inovalon.com/our-company/events/',
	FACEBOOK_URL = 'https://www.facebook.com/Inovalon',
	LINKEDIN_URL = 'https://www.linkedin.com/company/inovalon',
	TWITTER_URL = 'https://twitter.com/InovalonInc',
	REQUEST_DEMO_URL = 'https://www.inovalon.com/products/request-a-demo',
	SUPPORT_TICKET_URL = 'https://support.inovalon.com'
}

export class AppConfigOkta {
	static readonly SCOPES = ['openid', 'email', 'profile'];
}

/**
 * environment names
 */
export enum AppEnvironments {
	LOCAL = 'local',
	DEV = 'dev',
	QA = 'qa',
	UAT = 'uat',
	BETA = 'beta',
	PROD = 'prod'
}
