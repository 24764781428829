
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Component, OnInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { OktaService } from 'src/app/factory/okta.service';
import { TokenModel } from 'src/app/models/token.model';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MenuService } from 'src/app/services/menu.service';
import { ClientModel } from 'src/app/models/client.model';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { Azureb2cService } from 'src/app/factory/azureb2c.service';

@Component({
  selector: 'app-user-menu-item',
  templateUrl: './user-menu-item.component.html',
  styleUrls: ['./user-menu-item.component.css']
})
export class UserMenuItemComponent implements OnInit {

  isUserMenuOpen = false;
  canSwitchClients = false;
  switchClientsExpanded = false;
  clients: ClientModel[] = [];
  clientSelect = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  clientNames: string[];
  selectedClient: string;

  switchClientForm: UntypedFormGroup = new UntypedFormGroup({
    client: new UntypedFormControl('')
  });

  constructor(private eRef: ElementRef,
              public oktaService: OktaService,
              public tokenModel: TokenModel,
              public menuService: MenuService,
              private loggerService: LoggerService,
              ) { }

  ngOnInit() {
    this.loadClients();
    this.filteredOptions = this.clientSelect.valueChanges
      .pipe(
        startWith(''),
        map(val => val.length >= 3 ? this.filter(val) : [])
      );
  }

  @HostListener('window:click', ['$event'])
  hideUserMenu(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)
      && ((event.target as Element).className !== 'mat-option-text' )) {
      this.isUserMenuOpen = false;
    }
  }

  loadClients() {
    this.menuService.userClients$.subscribe((clients) => {
      if (clients && clients.length > 1) {
        this.clients = clients;
        this.canSwitchClients = true;
        this.clientNames  = clients.map(x => x.clientName);
      }
    }, err => {
      this.loggerService.logger({
        type: 'error',
        class: 'UserMenuItemComponent',
        method: 'loadClients',
        message: 'Failed to get the list of customers you are associated with.',
        error: err
      });
    });
  }
  clientSelected($event) {
      this.selectedClient = $event.source.value;
  }

  onSubmit() {
    if (this.selectedClient !== '') {
      // this.menuService.isMenuLoaded.next(false);
      this.menuService.changeClient(this.selectedClient);
      this.loggerService.customerName = this.selectedClient;
      this.loggerService.logger({
        type: 'info',
        class: 'UserMenuItemComponent',
        method: 'onSubmit',
        message: `${this.loggerService.upn} switched customer to ${this.selectedClient}`,
      });
    }
    this.switchClientsExpanded = this.isUserMenuOpen = false;
    this.switchClientForm.reset();
  }

  logout() {
    this.oktaService.logout();
  }
  filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.clientNames.filter(option => option.toLowerCase().includes(filterValue));
  }

}
