<div class="banner"
    [ngClass]="{
        'information': isInformation,
        'error': isError,
        'success': isSuccess,
        'warning': isWarning
    }">
    
    <div class="row-wrapper">
        <div fxLayout.xs="column" fxLayout.gt-xs="row" fxFlex.xs="grow" fxFlex.gt-xs="grow">
            <div
                class="cell-margin" 
                fxFlex.xs="100"
                fxFlex.gt-xs="95"
                fxFlex.gt-sm="95"
                fxFlex.gt-md="95"
                fxFlex.lg="95"
                fxFlex.gt-lg="95"
                fxFlex.lt-xl="95"
                fxFlex.xl="95">
                <ng-content></ng-content>
            </div>

            <div
                class="toggle-button-container"
                fxFlex.xs="100"
                fxFlex.gt-xs="5"
                fxFlex.gt-sm="5"
                fxFlex.gt-md="5"
                fxFlex.lg="5"
                fxFlex.gt-lg="5"
                fxFlex.lt-xl="5"
                fxFlex.xl="5">
                <div class="toggle-button">
                    <button mat-icon-button
                        style="color: #4A4A4A; font-size: 14px; margin:0; padding: 0; font-weight: 700;"
                        (click)="onClose()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
