import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TabsConstants } from 'src/app/rli/shared/constants/home-constants';
import { HomeSharedService } from 'src/app/rli/mrr/m-shared/m-services/home-shared.service';
import { AppSetting } from 'src/app/rli/shared/constants/rli-global';
import { environment } from 'src/environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-file-download-popup',
  templateUrl: './file-download-popup.component.html',
  styleUrls: ['./file-download-popup.component.css']
})
export class FileDownloadPopupComponent implements OnInit {
  form: UntypedFormGroup;
  mrrSelectedTab = "";
  tabs = TabsConstants;
  requestApp: string = AppSetting.RequestApp;
  requestAppIndicesOpportunity: string = environment.REQUEST_APP_INDICES_OPPORTUNITY;
  requestAppIndicesQPI: string = environment.REQUEST_APP_INDICES_QPI;

  constructor(public dialogRef: MatDialogRef<FileDownloadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { "card_title": "", displayViewFullData: true },
    public homeSharedService: HomeSharedService) {
    this.mrrSelectedTab = this.homeSharedService.$selectedTab.value.label;
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      comment: new UntypedFormControl('', [Validators.maxLength(200)]),
      fileType: new UntypedFormControl('excel'),
    });
  }

  viewFullData() {
    this.dialogRef.close({ cancel: true, comment: '', navigateToExtract: true })
  }

  download() {
    this.dialogRef.close({ cancel: false, comment: this.form.value.comment, navigateToExtract: false, fileType: this.form.value.fileType })
  }

  close() {
    this.dialogRef.close({ cancel: true })
  }
}
