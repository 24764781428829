import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiEndPoints } from '../m-constants/api-endpoints';
import { HomeConstants, TabsConstants } from '../../../shared/constants/home-constants';
import { SelectedTab } from '../../../shared/models/home.model';
import { LookerApiService } from '../../../shared/services/looker-api.service';
import { FilterFields, FiltersBinding } from '../m-constants/filters-binding';
import { IFiltersConfig } from 'src/app/rli/shared/models/filter-config';
@Injectable({
  providedIn: 'root'
})
export class HomeSharedService {
  $chartsOrPursuits: BehaviorSubject<string>;
  $selectedTab: BehaviorSubject<SelectedTab>;
  gainSightLogStrings: any = HomeConstants.gainSightLogStrings;

  $latestYear: BehaviorSubject<any>;
  $latestProject: BehaviorSubject<any>;
  $latestYearLoading: BehaviorSubject<boolean>;

  filtersBinding = FiltersBinding.bindingInfo;
  serviceYearIdField = this.filtersBinding[FilterFields.SERVICE_YEAR].idField;
  serviceYearDisplayField = FilterFields.SERVICE_YEAR;
  projectIdField = this.filtersBinding[FilterFields.PROJECT_NAME].idField;
  projectdisplayField = FilterFields.PROJECT_NAME;

  serviceYearFilter: IFiltersConfig<any> = {
    endPointName: ApiEndPoints.SERVICE_YEARS,
    filterLabel: this.filtersBinding[FilterFields.SERVICE_YEAR].label,
    displayField: FilterFields.SERVICE_YEAR,
    idField: this.filtersBinding[FilterFields.SERVICE_YEAR].idField,
    order: 1,
    show: true,
    showClearFilter : true,
  };
  stateFilter: IFiltersConfig<any> = {
    endPointName: ApiEndPoints.STATES,
    filterLabel: this.filtersBinding[FilterFields.STATE].label,
    displayField: FilterFields.STATE,
    idField: this.filtersBinding[FilterFields.STATE].idField,
    show: true,
    showClearFilter: true,
    order: 2,
  };
  serviceNameFilter: IFiltersConfig<any> = {
    endPointName: ApiEndPoints.SERVICES,
    filterLabel: this.filtersBinding[FilterFields.SERVICE_NAME].label,
    displayField: FilterFields.SERVICE_NAME,
    idField: this.filtersBinding[FilterFields.SERVICE_NAME].idField,
    show: true,
    showClearFilter: true,
    order: 3,
  };
  projectNameFilter: IFiltersConfig<any> = {
    endPointName: ApiEndPoints.PROJECTS,
    filterLabel: this.filtersBinding[FilterFields.PROJECT_NAME].label,
    displayField: FilterFields.PROJECT_NAME,
    idField: this.filtersBinding[FilterFields.PROJECT_NAME].idField,
    show: true,
    showClearFilter: true,
    order: 4
  };
  medicalGroupFilter: IFiltersConfig<any> = {
    endPointName: ApiEndPoints.MEDICAL_GROUPS,
    filterLabel: this.filtersBinding[FilterFields.MEDICAL_GROUP].label,
    displayField: FilterFields.MEDICAL_GROUP,
    idField: this.filtersBinding[FilterFields.MEDICAL_GROUP].idField,
    show: true,
    showClearFilter: true,
    order: 5
  };
  siteNameFilter: IFiltersConfig<any> = {
    endPointName: ApiEndPoints.SITES,
    filterLabel: this.filtersBinding[FilterFields.SITE_NAME].label,
    displayField: FilterFields.SITE_NAME,
    idField: this.filtersBinding[FilterFields.SITE_NAME].idField,
    show: true,
    showClearFilter: true,
    order: 6
  };
  providerNameFilter: IFiltersConfig<any> = {
    endPointName: ApiEndPoints.PROVIDERS,
    filterLabel: this.filtersBinding[FilterFields.PROVIDER_NAME].label,
    displayField: FilterFields.PROVIDER_NAME,
    idField: this.filtersBinding[FilterFields.PROVIDER_NAME].idField,
    show: true,
    showClearFilter: true,
    order: 7
  };
  providerSpecialityFilter: IFiltersConfig<any> = {
    endPointName: ApiEndPoints.PROVIDER_SPECIALITIES,
    filterLabel: this.filtersBinding[FilterFields.PROVIDER_SPECIALITY].label,
    displayField: FilterFields.PROVIDER_SPECIALITY,
    idField: this.filtersBinding[FilterFields.PROVIDER_SPECIALITY].idField,
    show: true,
    showClearFilter: true,
    order: 8
  };
  //Aging
  vendorNameFilter: IFiltersConfig<any> = {
    endPointName: ApiEndPoints.VENDORS_FOR_AGING,
    filterLabel: this.filtersBinding[FilterFields.VENDOR_NAME].label,
    displayField: FilterFields.VENDOR_NAME,
    idField: this.filtersBinding[FilterFields.VENDOR_NAME].idField,
    show: true,
    showClearFilter: true,
    order: 9
  };
  pursuitGroupedStatusFilter: IFiltersConfig<any> = {
    endPointName: ApiEndPoints.PURSUIT_GROUPED_STATUSES_FOR_AGING,
    filterLabel: this.filtersBinding[FilterFields.PURSUIT_GROUPED_STATUSII].label,
    displayField: FilterFields.PURSUIT_GROUPED_STATUSII,
    idField: this.filtersBinding[FilterFields.PURSUIT_GROUPED_STATUSII].idField,
    show: true,
    showClearFilter: true,
    order: 10
  };
  pursuitStatusFilter: IFiltersConfig<any> = {
    endPointName: ApiEndPoints.PURSUIT_STATUSES_FOR_AGING,
    filterLabel: this.filtersBinding[FilterFields.PURSUIT_STATUS].label,
    displayField: FilterFields.PURSUIT_STATUS,
    idField: this.filtersBinding[FilterFields.PURSUIT_STATUS].idField,
    show: true,
    showClearFilter: true,
    order: 11
  };
  //Activity Log
  latestActivityStatusFilter: IFiltersConfig<any> = {
    endPointName: ApiEndPoints.STATUSES_FOR_ACTIVITY_LOG,
    filterLabel: this.filtersBinding[FilterFields.ACTIVITY_STATUS].label,
    displayField: FilterFields.ACTIVITY_STATUS,
    idField: this.filtersBinding[FilterFields.ACTIVITY_STATUS].idField,
    show: true,
    showClearFilter: true,
    order: 12
  };
  //Common
  dataImportDateFilter: IFiltersConfig<any> = {
    endPointName: ApiEndPoints.DATA_IMPORT_DATES,
    filterLabel: this.filtersBinding[FilterFields.DATA_IMPORT_DATE].label,
    displayField: FilterFields.DATA_IMPORT_DATE,
    idField: this.filtersBinding[FilterFields.DATA_IMPORT_DATE].idField,
    show: true,
    showClearFilter: true,
    order: 13
  };

  constructor(
    private lookerApiService: LookerApiService
  ) {
    this.$chartsOrPursuits = new BehaviorSubject<string>(HomeConstants.CHARTS_TEXT);
    this.$selectedTab = new BehaviorSubject<SelectedTab>(TabsConstants.OVERVIEW);
    this.$latestYear = new BehaviorSubject<any>(null);
    this.$latestProject = new BehaviorSubject<any>(null);
    this.$latestYearLoading = new BehaviorSubject<boolean>(false);
  }



  getOverviewfiltersArr() {
    const filtersConfigArr = [
      this.serviceYearFilter = Object.assign(this.serviceYearFilter, { isSingleSelect: true }),
      this.stateFilter,
      this.serviceNameFilter,
      this.projectNameFilter,
      this.medicalGroupFilter,
      this.siteNameFilter,
      this.providerNameFilter,
      this.providerSpecialityFilter,
      this.dataImportDateFilter
    ]
    return filtersConfigArr;
  }

  getActualsVsForcastsfiltersArr() {
    const filtersConfigArr = [
      this.serviceYearFilter = Object.assign(this.serviceYearFilter, { isSingleSelect: true }),
      this.stateFilter,
      this.serviceNameFilter,
      this.projectNameFilter,
      this.dataImportDateFilter
    ]
    return filtersConfigArr;
  }

  getAgingfiltersArr() {
    const filtersConfigArr = [...this.getOverviewfiltersArr(), this.vendorNameFilter, this.pursuitGroupedStatusFilter, this.pursuitStatusFilter];
    return filtersConfigArr;
  }

  getActivityLogFiltersArr() {
    const filtersConfigArr = [...this.getOverviewfiltersArr(), this.latestActivityStatusFilter]
    return filtersConfigArr;
  }

  getTrendFiltersArr() {
    const filtersConfigArr = [...this.getOverviewfiltersArr()]
    return filtersConfigArr;
  }

  getExtractFiltersArr() {
    const filtersConfigArr = [...this.getOverviewfiltersArr()]
    return filtersConfigArr;
  }

  getFiltersConfig(selectedOptions, filtersConfig: IFiltersConfig<any>[] = []) {
    filtersConfig = filtersConfig.map(item => {
      return {
        ...item,
        selectedValues: selectedOptions[item.idField] ? selectedOptions[item.idField] : [],
        isSingleSelect: item.isSingleSelect ? item.isSingleSelect : false,
        additionalField: item.additionalField ? item.additionalField : "",
        sort: item.sort ? item.sort : [],
      }
    });
    return of(filtersConfig.sort((a: any, b: any) => a.order - b.order));
  }


  setChartsOrPursuits = (chartsOrPursuits: string) => {
    this.$chartsOrPursuits.next(chartsOrPursuits);
  }

  getChartsOrPursuits = () => {
    return this.$chartsOrPursuits;
  }

  setSelectedTab = (selectedTab: SelectedTab) => {
    this.$selectedTab.next(selectedTab);
  }

  getSelectedTab = () => {
    return this.$selectedTab;
  }

  getLatestYear = () => {
    return this.$latestYear;
  }

  fetchLatestServiceYear() {
    this.$latestYearLoading.next(true);
    this.lookerApiService.getWithFilter(ApiEndPoints.SERVICE_YEARS, {}, [], [], -1).pipe(
      map((data) => {
        return (data && data.length > 0) ? data.sort().reverse()[0] : data;
      })
    ).subscribe(
      latestYearObj => {
        if (Object.keys(latestYearObj).length) {
          latestYearObj['idField'] = this.serviceYearIdField;
          latestYearObj['displayField'] = this.serviceYearDisplayField;
        }
        this.$latestYear.next(latestYearObj);
        this.fetchLatestProject(latestYearObj);
      },
      (error) => {
        this.$latestYearLoading.next(false);
      }
    );
  }

  fetchLatestProject(latestYearObj) {
    if (Object.keys(latestYearObj).length) {
      let filter = {};
      filter[this.serviceYearIdField] = [];
      filter[this.serviceYearIdField].push(latestYearObj[this.serviceYearIdField]);
      this.lookerApiService.getWithFilter(ApiEndPoints.PROJECTS, filter, [], [], -1).pipe(
        map((data) => {
          return data.sort().reverse()[0]
        })
      ).subscribe(
        latestProjectObj => {
          if (Object.keys(latestProjectObj).length) {
            latestProjectObj['idField'] = this.projectIdField;
            latestProjectObj['displayField'] = this.projectdisplayField;
          }
          this.$latestProject.next(latestProjectObj);
          this.$latestYearLoading.next(false);
        },
        (error) => {
          this.$latestYearLoading.next(false);
        }
      );
    } else {
      this.$latestYearLoading.next(false);
    }
  }

  getLatestServiceYear() {
    return this.$latestYear;
  }

  addDefaultYearToOptions(options) {
    const defaultYear = this.getLatestServiceYear().value;
    if (!options || !options[this.serviceYearIdField]) {
      if (!options) {
        options = {};
      }
      if (defaultYear !== null) {
        options[this.serviceYearIdField] = [defaultYear];
      }
    }
    return options;
  }

  addDefaultProjectToOptions(options) {
    const latestProjectObj = this.getLatestProject().value;
    if (!options || !options[this.projectIdField]) {
      if (!options) {
        options = {};
      }
      options[this.projectIdField] = [latestProjectObj];
    }
    return options;
  }

  getLatestProject() {
    return this.$latestProject;
  }

}
