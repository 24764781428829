
<!--
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
-->

<div class="logout-background">
    <div class="logout-body">
        <div class="logout-dialog">
            <div style="border-bottom: 1px Solid #cecbcb;font-size: x-large;">
                <div style="padding: 4%;">
                    <span>Signed Off</span>
                </div>
            </div>

            <p style="margin: 4%;">You have signed out of this application.</p>
        </div>
    </div>
    <div class="logo--inovalon">
        <img src="../../../assets/svg/poweredbyinovalon.svg"
             align="bottom"
             style="float: left; padding: 3%;">
    </div>
</div>