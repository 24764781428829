import { Component, Input} from '@angular/core';

@Component({
  selector: 'io-content',
  templateUrl: './io-content.component.html',
  styleUrls: ['./io-content.component.css']
})
export class IoContentComponent {
  @Input() hasNoTopPadding = false; // Depreciate
  @Input() ioHasNoTopPadding = false;
  @Input() ioHasNoRightPadding = false;
  @Input() ioHasNoBottomPadding = false;
  @Input() ioHasNoLeftPadding = false;
  @Input() ioHasBorderRadius = false;
  @Input() ioHasShadow = false;

  constructor() {
    this.hasNoTopPadding = false; // Depreciate
    this.ioHasNoTopPadding = false;
    this.ioHasNoRightPadding = false;
    this.ioHasNoBottomPadding = false;
    this.ioHasNoLeftPadding = false;
    this.ioHasBorderRadius = false;
    this.ioHasShadow = false;
  }

}
