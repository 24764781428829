import { Component, OnInit } from '@angular/core';
import { OktaService } from 'src/app/factory/okta.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  userInfo: any;
  constructor(private oktaService: OktaService) {
    this.oktaService.userInfo$.subscribe((userInfo) => {
      this.userInfo = userInfo;
    });
  }

  ngOnInit() {
  }

}
