import { Pipe, PipeTransform } from '@angular/core';
import { DownloadItem, DownloadItemStatus } from '../models/download-item.model';


@Pipe({
  name: 'downloadsFilter'
})
export class DownloadsFilterPipe implements PipeTransform {

  transform(items: DownloadItem[], status:DownloadItemStatus) {
    if(status == null)
      return items
    return items.filter(item => item.status == status);
  }

}
