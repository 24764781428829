import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class MessageBarItem {
  id: number;
  type: string; // error, information, success, warning
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class IoMessageBarService {
  currentId = 0;
  messages = [];
  $messages: BehaviorSubject<any>;

  constructor() {
    this.$messages = new BehaviorSubject<any>(null);
  }

  getNextId() {
    this.currentId++;
    return this.currentId;
  }

  addItem(itemType, itemMessage) {
    const item = {id: this.getNextId(), type: itemType, message: itemMessage};
    this.messages.push(item);
    this.$messages.next(this.messages);
    return item.id;
  }

  closeItem(id) {
    const itemIndex = this.messages.map( item => item.id ).indexOf(id);
    this.messages.splice(itemIndex, 1); // removes message with id
    this.$messages.next(this.messages);
  }

  getMessages(): [MessageBarItem] {
    let returnValue: [MessageBarItem];
    this.$messages.subscribe( value => {
      returnValue = value;
    });
    return returnValue;
  }

}
