
<!--
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
-->

<div *ngIf="tokenModel.displayName !== ''">
    <div class="side-menu__user"
         data-toggle="popover"
         data-placement="right"
         data-content="Profile Details"
         [class.current]="isUserMenuOpen">
        <div class="side-menu__user__outer"
             style="cursor: pointer;"
             (click)="isUserMenuOpen = !isUserMenuOpen">
            <app-svg-icon name="profile"
                          class="side-menu__user__profile-icon"></app-svg-icon>
            <app-svg-icon name="profil_portal_menu"
                          class="pull-right hide-on-collapsed"></app-svg-icon>
            <div class="side-menu__user__container link-text">
                <span>{{tokenModel.displayName}}</span>
            </div>
        </div>
        <div class="side-menu__profile__list"
             [style.display]="isUserMenuOpen ? 'block' : 'none'">
            <div class="side-menu__profile__list__header">
                Profile Details
            </div>
            <div *ngIf="canSwitchClients"
                 class="panel-group menu-search"
                 aria-multiselectable="true">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <div class="panel-title">
                            <a (click)="switchClientsExpanded = !switchClientsExpanded"
                               [class.collapsed]="!switchClientsExpanded">
                                Switch client
                                <app-svg-icon [name]="switchClientsExpanded? 'expander_close' : 'expander_open'">
                                </app-svg-icon>
                            </a>
                        </div>
                    </div>
                    <div class="panel-collapse"
                         [style.display]="switchClientsExpanded ? 'block':'none'">
                        <div class="panel-body">
                            <form #switchClients
                                  [formGroup]="switchClientForm"
                                  (ngSubmit)="onSubmit()">
                                <div class="mat-form-field-infix">
                                    <mat-form-field>
                                        <input type="text" name ="txtClient" placeholder="Client" aria-label="Number" matInput [formControl]="clientSelect"  [matAutocomplete]="auto">
                                        <mat-autocomplete #auto="matAutocomplete" >
                                          <mat-option class="clientSwitch" (onSelectionChange)="clientSelected($event)" *ngFor="let clientName of filteredOptions | async" [value]="clientName">
                                            {{clientName}}
                                          </mat-option>
                                        </mat-autocomplete>
                                      </mat-form-field>
                                </div>
                                <io-primary-button>Change
                                </io-primary-button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ul>
                <li><a (click)="logout()">Logout</a></li>
            </ul>
        </div>
    </div>
</div>