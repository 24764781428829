import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OktaService } from 'src/app/factory/okta.service';
import { Observable, of, throwError } from 'rxjs';
import { ClientModel } from '../../../models/client.model';
import { catchError, retryWhen } from 'rxjs/operators';
import { httpRetryStrategy } from 'src/app/rli/shared/helpers/http-retry-strategy';
import { ApiKeywords } from '../../shared/constants/api-keywords';
import { environment } from '../../../../environments/environment';
import { LogTypes } from '../../../shared/constants/log-types';
import { HttpErrorHandler } from '../../../shared/helpers/http-error-handler';
import { LoggerModel } from 'src/app/shared/models/logger-model';
import { AppSetting } from '../../shared/constants/rli-global';
import { OffsetQueryParams } from '../models/offset-query-params';

@Injectable({
  providedIn: 'root'
})
export class LookerApiService {
  baseUrl = environment.DEV_API_URL;
  upn = '';
  client: ClientModel;
  constructor(
    private http: HttpClient,
    private oktaService: OktaService,
    private httpErrorHandler: HttpErrorHandler) {

    this.oktaService.client$.subscribe((client) => {
      this.client = client;
    });

    this.oktaService.upn$.subscribe((upn) => {
      if (upn !== '') {
        this.upn = upn;
      }
    });

  }

  getAll(endPoint: string, fields: any = [], sort: any = []): Observable<any> {
    let requestBody = this.formatRequest(endPoint, {}, fields, sort);
    return this.http.post<any>(this.baseUrl, requestBody, this.getHeaders()).pipe(
      retryWhen(httpRetryStrategy({})),
      catchError(error => {
        let logObject: any = this.getLogObject(endPoint, requestBody);
        this.httpErrorHandler.handleError(error, logObject);
        return of(new Array());
      }
      ));
  }

  getWithFilter(endPoint: string, filters: any = {}, fields: any = [], sort: any = [], count = environment.DEFAULT_COUNT, offsetQueryParams: OffsetQueryParams = {
    queryType: '',
    offset: -1,
    limit: -1,
    sort: []
  }): Observable<any> {
    filters = this.createFilters(filters);
    let requestBody = this.formatRequest(endPoint, filters, fields, sort, count, offsetQueryParams);
    return this.http.post<any>(this.baseUrl, requestBody, this.getHeaders()).pipe(
      retryWhen(httpRetryStrategy({})),
      catchError(error => {
        let logObject: any = this.getLogObject(endPoint, requestBody);
        logObject.type = LogTypes.ERROR;
        this.httpErrorHandler.handleError(error, logObject);
        return throwError(error);
      })
    );
  }

  createFilters(filters?: any) {
    let newFilters = JSON.parse(JSON.stringify(filters));
    let formattedFilter = {};
    let keys = (newFilters) ? Object.keys(newFilters) : [];

    keys.forEach((key) => {
      if (Array.isArray(newFilters[key])) {
        if (newFilters[key]) {
          let vals = newFilters[key].map(k => {
            if (typeof k !== 'string') {
              return k;
            }

            k = k.replace('^', '^^');
            k = k.replace(/([,%_])/g, '^$1');
            if (k && k.charAt(0) === '-') {
              k = ('^').concat(k);
            }
            return k;
          });
          newFilters[key] = vals;
        }
        let values = newFilters[key].join(ApiKeywords.COMMA);
        if (values)
          formattedFilter[key] = values;
      } else if (key === ApiKeywords.REQUESTOR_PARAM) {
        formattedFilter[key] = null;
      } else {
        if (newFilters[key]) {
          formattedFilter[key] = newFilters[key].toString().replace('^', '^^').replace(/([,%_])/g, '^$1');
          if (formattedFilter[key] && formattedFilter[key].charAt(0) === '-') {
            formattedFilter[key] = ('^').concat(formattedFilter[key]);
          }
        }
      }
    });
    return formattedFilter;
  }

  getLogObject(endPoint: string, requestBody: any) {
    let logObject = new LoggerModel();
    logObject.lookName = endPoint;
    logObject.requestBody = requestBody;
    return logObject;
  }

  formatRequest(endPoint: string, filters: any, fields: any, sort: any, count?: number, offsetQueryParams?: OffsetQueryParams) {
    filters['username'] = this.upn;
    let requestBody = {
      requestName: endPoint,
      upn: this.upn,
      requestType: environment.REQUEST_TYPE_DATA,
      requestApp: AppSetting.RequestApp,
      client: this.client.clientName.toLowerCase(),
      filters: filters,
      fields: fields,
      count: count,
      sort: offsetQueryParams.sort.length ? offsetQueryParams.sort : sort,
      queryType: offsetQueryParams.queryType,
      offset: offsetQueryParams.offset,
      limit: offsetQueryParams.limit,
    };

    if (!fields || fields.length < 1) {
      delete requestBody.fields;
    }
    if ((!sort || sort.length < 1) && offsetQueryParams.sort.length < 1) {
      delete requestBody.sort;
    }
    if (!count || count == -1) {
      delete requestBody.count;
    }
    if (!offsetQueryParams.queryType || offsetQueryParams.queryType.length < 1) {
      delete requestBody.queryType;
    }
    if (offsetQueryParams.offset == -1) {
      delete requestBody.offset;
    }
    if (offsetQueryParams.limit == -1) {
      delete requestBody.limit;
    }
    return requestBody;
  }

  getHeaders() {
    let headerOptions = { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.oktaService.getAccessToken()) };
    return headerOptions
  }

}