import { GridConstants, OverviewSummaryConstants } from 
"../m-constants/h-overview-constants";
import { HomeConstants } from "../../../shared/constants/home-constants";

export const getGridDefaultDimensionFields = () => {
  return [
    GridConstants.Fields.STATE,
    GridConstants.Fields.STATE_CODE,
    GridConstants.Fields.SITE_ID,
    GridConstants.Fields.SITE_NAME,
    GridConstants.Fields.SORT_SITE_NAME
  ];
};

export const getGridDefaultFields = (
  chartsOrPursuits = HomeConstants.CHARTS_TEXT
) => {
  return [
    GridConstants.Fields[chartsOrPursuits].COUNT,
    GridConstants.Fields[chartsOrPursuits].PURSUABLE,
    GridConstants.Fields[chartsOrPursuits].CLOSED,
    GridConstants.Fields[chartsOrPursuits].ISSUED,
    GridConstants.Fields[chartsOrPursuits].COMPLETED,
    GridConstants.Fields[chartsOrPursuits].COMPLETED_PERCENT,
    GridConstants.Fields[chartsOrPursuits].AVAILABLE,
    GridConstants.Fields[chartsOrPursuits].AVAILABLE_PERCENT
  ];
};

export const getOverviewSummaryDefaultFields = (
  chartsOrPursuits = HomeConstants.CHARTS_TEXT
) => {
  return [
    OverviewSummaryConstants.Keys[chartsOrPursuits].PROJECT_COMPLETION,
    OverviewSummaryConstants.Keys[chartsOrPursuits].TOTAL,
    OverviewSummaryConstants.Keys[chartsOrPursuits].PURSUABLE,
    OverviewSummaryConstants.Keys[chartsOrPursuits].COMPLETED,
    OverviewSummaryConstants.Keys[chartsOrPursuits].AVAILABLE,
  ];
};

export const getGridDefaultSort = (
  chartsOrPursuits = HomeConstants.CHARTS_TEXT
) => {
  return [
    `${GridConstants.Fields[chartsOrPursuits].COUNT} desc`
  ]
}
