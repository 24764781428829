import { environment } from "src/environments/environment";
import { LogTypes } from "../constants/log-types";
import { RequestCategory } from "../constants/request-category";

export class LoggerModel {
    type: string;
    url: string;
    requestCategory: string;
    lookName: string;
    requestBody: string;
    messageToDisplay: string;
    message: string;
    hitrust: boolean = false;
    isToLog: boolean = true;

    constructor(type = LogTypes.RESPONSE, requestType = RequestCategory.DATA, url = environment.DEV_API_URL) {
    }
}