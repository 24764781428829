import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IoMessageBarComponent } from './io-message-bar/io-message-bar.component';
import { IoMessageBarItemComponent } from './io-message-bar-item/io-message-bar-item.component';
import { IoContentComponent } from './io-content/io-content.component';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    IoMessageBarComponent,
    IoMessageBarItemComponent,
    IoContentComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports:[
    IoMessageBarComponent
  ]
})
export class MessageBarModule { }
