<!--
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
-->

<!-- dialog -->
<div class="info-icon-dialog-container">
  <mat-toolbar class="page-title">
    <mat-toolbar-row class="page-title-button-row">
      <div style="width: 100%">
        <span class="page-name">{{ data.card_title }}</span>
        <button class="close-button" (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <form [formGroup]="form">
    <div class="content-container">
      <div fxLayout="row" *ngIf="false && (requestApp === requestAppIndicesOpportunity || requestApp === requestAppIndicesQPI)">
        <mat-radio-group aria-label="Select an option" formControlName="fileType">
          <mat-radio-button value="excel">Excel</mat-radio-button>
          <mat-radio-button value="csv">CSV</mat-radio-button>
        </mat-radio-group>
      </div>
      <div fxLayout="row">
        <label>File Comment: (Optional)</label>
      </div>
      <div fxLayout="row">
        <textarea
          class="comment-input"
          formControlName="comment"
          rows="5"
        ></textarea>
      </div>
    </div>
  </form>

  <mat-toolbar class="page-footer">
    <mat-toolbar-row class="page-title-button-row">
      <span class="toolbar-spacer"></span>
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<!-- *ngIf="selectedTab !== tabs.EXTRACT.label" -->
<ng-template #buttons>
  <io-secondary-button mat-dialog-close (click)="close()">
    Cancel
  </io-secondary-button>
  <io-secondary-button *ngIf="data.displayViewFullData" (click)="viewFullData()">
    View Full Data
  </io-secondary-button>
  <io-primary-button (click)="download()">Download </io-primary-button>
</ng-template>
