
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Component, OnInit, ElementRef, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-product-menu-item',
  templateUrl: './product-menu-item.component.html',
  styleUrls: ['./product-menu-item.component.css']
})
export class ProductMenuItemComponent implements OnInit {
  isProductMenuOpen = false;
  @Input() products: any[];

  constructor(private eRef: ElementRef) { }

  ngOnInit() {
  }

  @HostListener('window:click', ['$event'])
  hideProductMenu(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isProductMenuOpen = false;
    }
  }

}
