export class OIFilterLabels {
    static readonly ALL = "All";
}

export class OIFilterFields {
    static readonly ANALYTICS_RUN_NAME = "analyticsRunName";
    static readonly MEASUREMENT_PERIOD = "measurementPeriod";
    static readonly REPORTING_POPULATION = "reportingPopulation";
    static readonly PROVIDER_NAME = "providerName";
    static readonly MEDICAL_GROUP = "medicalGroup";
    static readonly ANALYTICS_RUN_DATE = "analyticsRunDate";
    static readonly LINE_OF_BUSINESS = "lineOfBusiness";
    static readonly CONDITION_CATEGORY_DESCRIPTION = "conditionCategoryDescription";
    static readonly CLINIC_NAME = "clinicName";
    static readonly MEMBER_NAME = "memberName";
    static readonly MEMBER_ID = "memberId";
    static readonly PROJECT_NAME = 'projectName';
    static readonly CATALOG_NAME = 'catalogName';
    static readonly HEALTH_PLAN_MEMBERID = 'healthplanMemberId';
}

export class OIFiltersBinding {
    static readonly bindingInfo = {
        [OIFilterFields.ANALYTICS_RUN_NAME]: {
            idField: "analyticsRunId",
            label: "Analytics Run Name (AnalyticsRunDate)"
        },
        [OIFilterFields.MEASUREMENT_PERIOD]: {
            idField: "measurementPeriodId",
            label: "Measurement Period"
        },
        [OIFilterFields.REPORTING_POPULATION]: {
            idField: "reportingPopulationId",
            label: "Reporting Population"
        },
        [OIFilterFields.PROVIDER_NAME]: {
            idField: "providerId",
            label: "Attributed Provider Name (ID)"
        },
        [OIFilterFields.MEDICAL_GROUP]: {
            idField: "medicalGroupId",
            label: "Medical Group (ID)"
        },
        [OIFilterFields.ANALYTICS_RUN_DATE]: {
            idField: "analyticsRunDateId",
            label: "Analytics Run Date"
        },
        [OIFilterFields.LINE_OF_BUSINESS]: {
            idField: "lineOfBusinessId",
            label: "Line of Business"
        },
        [OIFilterFields.CONDITION_CATEGORY_DESCRIPTION]: {
            idField: "conditionCategoryDescription",
            additionalField: "conditionCategoryId",
            //This has to be a different key than id, and display, in order to send the params through filters correctly
            additionalDisplayField: "conditionCategory",
            label: "Condition Category Description (Code)"
        },
        [OIFilterFields.CLINIC_NAME]: {
            idField: "clinicId",
            label: "Clinic Name (ID)"
        },
        [OIFilterFields.PROJECT_NAME]: {
            idField: "projectNameId",
            label: "Project Name"
        },
        [OIFilterFields.CATALOG_NAME]: {
            idField: "catalogNameId",
            label: "Catalog Name"
        }
    }
}
