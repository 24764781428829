import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AppConfig, AppEnvironments } from '../shared/constants/app-config';

@Injectable({
	providedIn: 'root'
})
export class EnvironmentService {
	currentUrl: string;
	subDomain: string;
	environment: string;
	productName = AppConfig.PRODUCT_NAME;
	overrideIdpMetadata = AppConfig.OVERRIDE_IDP_METADATA.toString() == 'true';
	_portalName: string;
	portalApi: string;
	baseRoute: string;

	portalApiBaseUrls = {
		local: {
			portalApi: `https://uis-api-dev.inovalon.com/api/v1`
		},
		dev: {
			portalApi: `https://uis-api-dev.inovalon.com/api/v1`
		},
		qa: {
			portalApi: `https://uis-api-qa.inovalon.com/api/v1`
		},
		uat: {
			portalApi: `https://uis-api-uat.inovalon.com/api/v1`
		},
		beta:{
			portalApi: `https://uis-api.inovalon.com/api/v1`
		},
		prod: {
			portalApi: `https://uis-api.inovalon.com/api/v1`
		}
	};

	portalApiBaseUrl = {
		local: 'https://uis-api-dev.inovalon.com/api/v1',
		dev:   'https://uis-api-dev.inovalon.com/api/v1',
		qa:    'https://uis-api-qa.inovalon.com/api/v1',
		uat:   'https://uis-api-uat.inovalon.com/api/v1',
		beta:  'https://uis-api.inovalon.com/api/v1',
		prod:  'https://uis-api.inovalon.com/api/v1'
	};

	risApiBaseUrl = {
        local: 'https://apis-dev.inovalon.com/ris',
        dev:   'https://apis-dev.inovalon.com/ris',
        qa:    'https://apis-qa.inovalon.com/ris',
        uat:   'https://apis-uat.inovalon.com/ris',
		beta:  'https://apis.inovalon.com/ris',
        prod:  'https://apis.inovalon.com/ris'
    };

	uisApiBaseUrl = {
		local: 'https://uis-api-dev.inovalon.com/api/v1',
		dev:   'https://uis-api-dev.inovalon.com/api/v1',
		qa:    'https://uis-api-qa.inovalon.com/api/v1',
		uat:   'https://uis-api-uat.inovalon.com/api/v1',
		beta:  'https://uis-api.inovalon.com/api/v1',
		prod:  'https://uis-api.inovalon.com/api/v1'
	};

	ioumApiBaseUrl = {
		local: 'https://ioum-api-dev.inovalonone.com/api/v1',
		dev:   'https://ioum-api-dev.inovalonone.com/api/v1',
		qa:    'https://ioum-api-qa.inovalonone.com/api/v1',
		uat:   'https://ioum-api-uat.inovalonone.com/api/v1',
		beta:  'https://ioum-api.inovalonone.com/api/v1',
		prod:  'https://ioum-api.inovalonone.com/api/v1'
	};

	portalProfileApiBaseUrls = {
		local: this.portalApiBaseUrls.local.portalApi,
		dev:   this.portalApiBaseUrls.dev.portalApi,
		qa:    this.portalApiBaseUrls.qa.portalApi,
		uat:   this.portalApiBaseUrls.uat.portalApi,
		beta:  this.portalApiBaseUrls.prod.portalApi,
		prod:  this.portalApiBaseUrls.prod.portalApi
	};

	iapPortalBaseUrl = {
		local: 'https://apps-dev.inovalon.com?applicationId=PH_193',
		dev:   'https://apps-dev.inovalon.com?applicationId=PH_193',
		qa:    'https://apps-qa.inovalon.com?applicationId=PH_193',
		uat:   'https://apps-uat.inovalon.com?applicationId=PH_193',
		beta:  'https://apps.inovalon.com?applicationId=PH_194',
		prod:  'https://apps.inovalon.com?applicationId=PH_193'
	};

	applicationBaseUrl = {
		local: 'http://localhost:4200',
		dev:   'https://datainsights-dev.inovalon.com',
		qa:    'https://datainsights-qa.inovalon.com',
		uat:   'https://datainsights-uat.inovalon.com',
		beta:  'https://datainsights-beta.inovalon.com',
		prod:  'https://datainsights.inovalon.com'
	}

	oktaAuthority = {
		clientIds: {
			local: '0oa27odyg6kqUgYEl0h8',
			dev:   '0oa27odyg6kqUgYEl0h8',
			qa:    '0oa27vzwf7bsOjYzl0h8',
			uat:   '0oa27w0g7tmLeclK30h8',
			beta:  '0oatqrzj5w7TkOdQZ297',
			prod:  '0oatmo7wae67vNNUs297'
		},
		issuerUrls: {
			local:
				 'https://inovalononedev.oktapreview.com/oauth2/ausfwtb2inw6HxWUO0h7',
			dev: 'https://inovalononedev.oktapreview.com/oauth2/ausfwtb2inw6HxWUO0h7',
			qa:  'https://inovalononeqa.oktapreview.com/oauth2/ausfpx1cbnJyd7qwO0h7',
			uat: 'https://inovalononeuat.oktapreview.com/oauth2/ausfpx1cfndo72pqR0h7',
			beta:'https://inovalonone.okta.com/oauth2/ausxhy0xy4qxPDi7N296',
			prod:'https://inovalonone.okta.com/oauth2/ausxhy0xy4qxPDi7N296'
		},
		scopes: {
			local: 'openid email profile offline_access',
			dev:   'openid email profile offline_access',
			qa:    'openid email profile offline_access',
			uat:   'openid email profile offline_access',
			beta:  'openid email profile offline_access',
			prod:  'openid email profile offline_access'
		 },
		 extraParams: {
			local: {  }, 
			dev:   {  }, 
			qa:    {  },
			uat:   {  },
			beta:  {  },
			prod:  {  }
		 }
	};

	b2cFedAuthority = {
		issuerUrls: {
			local:
				 'https://sso-dev.inovalon.com/259abab8-c159-4a2f-b745-25b8e6d48ecb/B2C_1A_INOVALON_FED_SIGNIN/v2.0/',
			dev: 'https://sso-dev.inovalon.com/259abab8-c159-4a2f-b745-25b8e6d48ecb/B2C_1A_INOVALON_FED_SIGNIN/v2.0/',
			qa:  'https://sso-qa.inovalon.com/560c4d3e-1e67-494d-99d9-30fb963e1edc/B2C_1A_INOVALON_FED_SIGNIN/v2.0/',
			uat: 'https://sso-uat.inovalon.com/bbe13b47-43a7-471e-b5fd-dff544effa3a/B2C_1A_INOVALON_FED_SIGNIN/v2.0/',
			beta:'https://sso.inovalon.com/5ab8c739-fe36-4bc6-8364-a101db292e3b/B2C_1A_INOVALON_FED_SIGNIN/v2.0/',
			prod:'https://sso.inovalon.com/5ab8c739-fe36-4bc6-8364-a101db292e3b/B2C_1A_INOVALON_FED_SIGNIN/v2.0/'
		},
		clientIds: {
			local:  'c59e2e48-0df2-4b7b-8656-746445491378',
			dev:    'c59e2e48-0df2-4b7b-8656-746445491378',
			qa:     'b2bc24ed-04e0-4bfc-a935-d417c25e1983',
			uat:    '3f082996-32ac-4988-b4bc-1e22620a8720',
			beta:   '7640e74b-9f4a-4121-8436-0d4156b8ce30',
			prod:   '00747c24-61e3-40cc-a4af-6864ade022e7'
		},
		scopes: {
			local: 'openid email profile offline_access c59e2e48-0df2-4b7b-8656-746445491378',
			dev:   'openid email profile offline_access c59e2e48-0df2-4b7b-8656-746445491378',
			qa:    'openid email profile offline_access b2bc24ed-04e0-4bfc-a935-d417c25e1983',
			uat:   'openid email profile offline_access 3f082996-32ac-4988-b4bc-1e22620a8720',
			beta:  'openid email profile offline_access 7640e74b-9f4a-4121-8436-0d4156b8ce30',
			prod:  'openid email profile offline_access 00747c24-61e3-40cc-a4af-6864ade022e7'
		  },
		  extraParams: {
			local: { },
			dev:   { },
			qa:    { },
			uat:   { },
			beta:  { },
			prod:  { }
		  },
		  signoutUrls: {
			local:
				 'https://sso-dev.inovalon.com/259abab8-c159-4a2f-b745-25b8e6d48ecb/B2C_1A_INOVALON_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=http://localhost:4200/logout',
			dev: 'https://sso-dev.inovalon.com/259abab8-c159-4a2f-b745-25b8e6d48ecb/B2C_1A_INOVALON_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=https://datainsights-dev.inovalon.com/logout',
			qa:  'https://sso-qa.inovalon.com/560c4d3e-1e67-494d-99d9-30fb963e1edc/B2C_1A_INOVALON_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=https://datainsights-qa.inovalon.com/logout',
			uat: 'https://sso-uat.inovalon.com/bbe13b47-43a7-471e-b5fd-dff544effa3a/B2C_1A_INOVALON_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=https://datainsights-uat.inovalon.com/logout',
			beta:'https://sso.inovalon.com/5ab8c739-fe36-4bc6-8364-a101db292e3b/B2C_1A_INOVALON_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=https://datainsights-beta.inovalon.com/logout',
			prod:'https://sso.inovalon.com/5ab8c739-fe36-4bc6-8364-a101db292e3b/B2C_1A_INOVALON_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=https://datainsights.inovalon.com/logout'
		},
		resetPasswordUrls: {
			local:
				 'https://sso-dev.inovalon.com/259abab8-c159-4a2f-b745-25b8e6d48ecb/oauth2/v2.0/authorize?p=B2C_1A_INOVALON_ENROLLMENT_PASSWORD_RESET&client_id=59dd5406-55e7-453b-bd1a-da7652835b79&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost:4200&scope=openid&response_type=id_token&prompt=login',
			dev: 'https://sso-dev.inovalon.com/259abab8-c159-4a2f-b745-25b8e6d48ecb/oauth2/v2.0/authorize?p=B2C_1A_INOVALON_ENROLLMENT_PASSWORD_RESET&client_id=59dd5406-55e7-453b-bd1a-da7652835b79&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fdatainsights-dev.inovalon.com&scope=openid&response_type=id_token&prompt=login',
			qa:  'https://sso-qa.inovalon.com/560c4d3e-1e67-494d-99d9-30fb963e1edc/oauth2/v2.0/authorize?p=B2C_1A_INOVALON_ENROLLMENT_PASSWORD_RESET&client_id=89c61787-6db4-4543-93fd-874d7f309d0a&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fdatainsights-qa.inovalon.com&scope=openid&response_type=id_token&prompt=login',
			uat: 'https://sso-uat.inovalon.com/bbe13b47-43a7-471e-b5fd-dff544effa3a/oauth2/v2.0/authorize?p=B2C_1A_INOVALON_ENROLLMENT_PASSWORD_RESET&client_id=684c3c7d-272d-4703-902e-93104e0de750&nonce=defaultNonce&redirect_uri=https%3A%2F%2F2Fdatainsights-uat.inovalon.com&scope=openid&response_type=code&prompt=login',
			beta:'https://sso.inovalon.com/5ab8c739-fe36-4bc6-8364-a101db292e3b/oauth2/v2.0/authorize?p=B2C_1A_INOVALON_ENROLLMENT_PASSWORD_RESET&client_id=ec1ffd62-2f2c-4bd7-a969-da903fa8dd9f&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fdatainsights-beta.inovalon.com&scope=openid&response_type=id_token&prompt=login',
			prod:'https://sso.inovalon.com/5ab8c739-fe36-4bc6-8364-a101db292e3b/oauth2/v2.0/authorize?p=B2C_1A_INOVALON_ENROLLMENT_PASSWORD_RESET&client_id=ec1ffd62-2f2c-4bd7-a969-da903fa8dd9f&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fdatainsights.inovalon.com&scope=openid&response_type=id_token&prompt=login'
		}
	};

	b2cAuthority = {
		
		issuerUrls: {
			local:
				 'https://sso-dev.inovalon.com/259abab8-c159-4a2f-b745-25b8e6d48ecb/B2C_1A_INOVALON_SIGNIN/v2.0/',
			dev: 'https://sso-dev.inovalon.com/259abab8-c159-4a2f-b745-25b8e6d48ecb/B2C_1A_INOVALON_SIGNIN/v2.0/',
			qa:  'https://sso-qa.inovalon.com/560c4d3e-1e67-494d-99d9-30fb963e1edc/B2C_1A_INOVALON_SIGNIN/v2.0/',
			uat: 'https://sso-uat.inovalon.com/bbe13b47-43a7-471e-b5fd-dff544effa3a/B2C_1A_INOVALON_SIGNIN/v2.0/',
			beta:'https://sso.inovalon.com/5ab8c739-fe36-4bc6-8364-a101db292e3b/B2C_1A_INOVALON_SIGNIN/v2.0/',
			prod:'https://sso.inovalon.com/5ab8c739-fe36-4bc6-8364-a101db292e3b/B2C_1A_INOVALON_SIGNIN/v2.0/'
		},
		clientIds: {
			local: 'c59e2e48-0df2-4b7b-8656-746445491378',
			dev:   'c59e2e48-0df2-4b7b-8656-746445491378',
			qa:    'b2bc24ed-04e0-4bfc-a935-d417c25e1983',
			uat:   '3f082996-32ac-4988-b4bc-1e22620a8720',
			beta:  '7640e74b-9f4a-4121-8436-0d4156b8ce30',
			prod:  '00747c24-61e3-40cc-a4af-6864ade022e7'
		},
		scopes: {
			local: 'openid email profile offline_access c59e2e48-0df2-4b7b-8656-746445491378',
			dev:   'openid email profile offline_access c59e2e48-0df2-4b7b-8656-746445491378',
			qa:    'openid email profile offline_access b2bc24ed-04e0-4bfc-a935-d417c25e1983',
			uat:   'openid email profile offline_access 3f082996-32ac-4988-b4bc-1e22620a8720',
			beta:  'openid email profile offline_access 7640e74b-9f4a-4121-8436-0d4156b8ce30',
			prod:  'openid email profile offline_access 00747c24-61e3-40cc-a4af-6864ade022e7'
		  },
		  extraParams: {
			local: { },
			dev:   { },
			qa:    { },
			uat:   { },
			beta:  { },
			prod:  { }
		  },
		signoutUrls: {
			local:
				 'https://sso-dev.inovalon.com/259abab8-c159-4a2f-b745-25b8e6d48ecb/B2C_1A_INOVALON_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=http://localhost:4200/logout',
			dev: 'https://sso-dev.inovalon.com/259abab8-c159-4a2f-b745-25b8e6d48ecb/B2C_1A_INOVALON_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=https://datainsights-dev.inovalon.com/logout',
			qa:  'https://sso-qa.inovalon.com/560c4d3e-1e67-494d-99d9-30fb963e1edc/B2C_1A_INOVALON_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=https://datainsights-qa.inovalon.com/logout',
			uat: 'https://sso-uat.inovalon.com/bbe13b47-43a7-471e-b5fd-dff544effa3a/B2C_1A_INOVALON_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=https://datainsights-uat.inovalon.com/logout',
			beta:'https://sso.inovalon.com/5ab8c739-fe36-4bc6-8364-a101db292e3b/B2C_1A_INOVALON_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=https://datainsights-beta.inovalon.com/logout',
			prod:'https://sso.inovalon.com/5ab8c739-fe36-4bc6-8364-a101db292e3b/B2C_1A_INOVALON_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=https://datainsights.inovalon.com/logout'
		},
		resetPasswordUrls: {
			local:
				 'https://sso-dev.inovalon.com/259abab8-c159-4a2f-b745-25b8e6d48ecb/oauth2/v2.0/authorize?p=B2C_1A_INOVALON_ENROLLMENT_PASSWORD_RESET&client_id=59dd5406-55e7-453b-bd1a-da7652835b79&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost:4200&scope=openid&response_type=id_token&prompt=login',
			dev: 'https://sso-dev.inovalon.com/259abab8-c159-4a2f-b745-25b8e6d48ecb/oauth2/v2.0/authorize?p=B2C_1A_INOVALON_ENROLLMENT_PASSWORD_RESET&client_id=59dd5406-55e7-453b-bd1a-da7652835b79&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fdatainsights-dev.inovalon.com&scope=openid&response_type=id_token&prompt=login',
			qa:  'https://sso-qa.inovalon.com/560c4d3e-1e67-494d-99d9-30fb963e1edc/oauth2/v2.0/authorize?p=B2C_1A_INOVALON_ENROLLMENT_PASSWORD_RESET&client_id=89c61787-6db4-4543-93fd-874d7f309d0a&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fdatainsights-qa.inovalon.com&scope=openid&response_type=id_token&prompt=login',
			uat: 'https://sso-uat.inovalon.com/bbe13b47-43a7-471e-b5fd-dff544effa3a/oauth2/v2.0/authorize?p=B2C_1A_INOVALON_ENROLLMENT_PASSWORD_RESET&client_id=684c3c7d-272d-4703-902e-93104e0de750&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fdatainsights-uat.inovalon.com&scope=openid&response_type=code&prompt=login',
			beta:'https://sso.inovalon.com/5ab8c739-fe36-4bc6-8364-a101db292e3b/oauth2/v2.0/authorize?p=B2C_1A_INOVALON_ENROLLMENT_PASSWORD_RESET&client_id=ec1ffd62-2f2c-4bd7-a969-da903fa8dd9f&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fdatainsights-beta.inovalon.com&scope=openid&response_type=id_token&prompt=login',
			prod:'https://sso.inovalon.com/5ab8c739-fe36-4bc6-8364-a101db292e3b/oauth2/v2.0/authorize?p=B2C_1A_INOVALON_ENROLLMENT_PASSWORD_RESET&client_id=ec1ffd62-2f2c-4bd7-a969-da903fa8dd9f&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fdatainsights.inovalon.com&scope=openid&response_type=id_token&prompt=login'
		}
	};

	appConfig = {};
	constructor(@Inject(DOCUMENT) private document: Document) {
		this.currentUrl = this.document.location.href.split('/')[2];
		this.subDomain = this.currentUrl.split('.')[0].split(':')[0];
		this.setEnvironmentAndProduct();
		this.appConfig = {
			currentUrl: this.currentUrl,
			subDomain: this.subDomain,
			overrideIdpMetadata: this.overrideIdpMetadata,
			oktaClientId: this.oktaAuthority.clientIds[this.environment],
			b2cClientId: this.b2cAuthority.clientIds[this.environment],
			b2cFedClientId: this.b2cFedAuthority.clientIds[this.environment],
			oktaIssuerUrl: this.oktaAuthority.issuerUrls[this.environment],
			b2cIssuerUrl: this.b2cAuthority.issuerUrls[this.environment],
			b2cFedIssuerUrl : this.b2cFedAuthority.issuerUrls[this.environment],
			oktaScope: this.oktaAuthority.scopes[this.environment],
			b2cScope: this.b2cAuthority.scopes[this.environment],
			b2cFedScope:this.b2cFedAuthority.scopes[this.environment],
			oktaExtraParams: this.oktaAuthority.extraParams[this.environment],
			b2cExtraParams: this.b2cAuthority.extraParams[this.environment],
			b2cFedExtraParams: this.b2cFedAuthority.extraParams[this.environment],
			productName: this.productName,
			portalName: this.portalName,
			environment: this.environment,
			okta: null,
			applicationsUrls: null,
			refreshInterval: 10,
			portalProfileApiBaseUrl: this.portalProfileApiBaseUrls[this.environment],
			b2cSignoutUrl: this.b2cAuthority.signoutUrls[this.environment],
			b2cResetPasswordUrl:
				this.b2cAuthority.resetPasswordUrls[this.environment],
			portalApiBaseUrl: this.portalApiBaseUrl[this.environment],
			risApiBaseUrl: this.risApiBaseUrl[this.environment],
			uisApiBaseUrl: this.uisApiBaseUrl[this.environment],
			ioumApiBaseUrl: this.ioumApiBaseUrl[this.environment],
			iapPortalBaseUrl: this.iapPortalBaseUrl[this.environment],
			applicationBaseUrl:this.applicationBaseUrl[this.environment],
			idps:{
				b2c:{
					signoutUrl: this.b2cAuthority.signoutUrls[this.environment]
				}
			}
		};
		// console.log("appCOnfig : ",this.appConfig)
	}

	public get portalName() {
		return this._portalName;
	}

	public set portalName(portalName) {
		this._portalName = portalName;
	}

	setEnvironmentAndProduct() {
		let environment = AppEnvironments.LOCAL;
		let portalName = '';
		switch (this.currentUrl) {
			case AppConfig.PORTAL_LOCAL_URL:
				environment = AppEnvironments.LOCAL;
				portalName = AppConfig.PRODUCT_NAME;
				this.baseRoute = AppConfig.BASE_ROUTE_PATH;
				break;
			case `${AppConfig.PORTAL_SUBDOMAIN_PREFIX}-${AppEnvironments.DEV}.${AppConfig.PORTAL_BASE_DOMAIN}`:
				environment = AppEnvironments.DEV;
				portalName = AppConfig.PRODUCT_NAME;
				this.baseRoute = AppConfig.BASE_ROUTE_PATH;
				break;
			case `${AppConfig.PORTAL_SUBDOMAIN_PREFIX}-${AppEnvironments.QA}.${AppConfig.PORTAL_BASE_DOMAIN}`:
				environment = AppEnvironments.QA;
				portalName = AppConfig.PRODUCT_NAME;
				this.baseRoute = AppConfig.BASE_ROUTE_PATH;
				break;
			case `${AppConfig.PORTAL_SUBDOMAIN_PREFIX}-${AppEnvironments.UAT}.${AppConfig.PORTAL_BASE_DOMAIN}`:
				environment = AppEnvironments.UAT;
				portalName = AppConfig.PRODUCT_NAME;
				this.baseRoute = AppConfig.BASE_ROUTE_PATH;
				break;
			case `${AppConfig.PORTAL_SUBDOMAIN_PREFIX}-${AppEnvironments.BETA}.${AppConfig.PORTAL_BASE_DOMAIN}`:
				environment = AppEnvironments.BETA;
				portalName = AppConfig.PRODUCT_NAME;
				this.baseRoute = AppConfig.BASE_ROUTE_PATH;
				break;
			case `${AppConfig.PORTAL_SUBDOMAIN_PREFIX}.${AppConfig.PORTAL_BASE_DOMAIN}`:
				environment = AppEnvironments.PROD;
				portalName = AppConfig.PRODUCT_NAME;
				this.baseRoute = AppConfig.BASE_ROUTE_PATH;
				break;

			default:
				environment = AppEnvironments.DEV;
				portalName = AppConfig.PRODUCT_NAME;
				this.baseRoute = AppConfig.BASE_ROUTE_PATH;
		}
		this.environment = environment;
		this.portalName = portalName;
		console.log("current environment : ",this.environment);
	}
}
