
<!--
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
-->

<ul class="{{cssClass}}">
    <li *ngFor="let menuItem of utilityMenu"
        [class.current]="getActiveMenu(menuItem.id)">
        <a (click)="menuItem.children ? setActiveMenu(menuItem.id, $event) : null">
            <app-svg-icon [name]="menuItem.icon">
            </app-svg-icon>
            <span class="link-text">
                {{menuItem.name}}
            </span>
        </a>
        <div *ngIf="menuItem.children && menuItem.children.length > 0">
            <ul class="flyout-nav"
                [style.display]="getActiveMenu(menuItem.id) ? 'block' : 'none'">
                <div class="flyout-nav-controls">
                    <button class="close-btn"
                            (click)="setActiveMenu(menuItem.id, $event)">
                        <app-svg-icon name="close_x"></app-svg-icon>
                    </button>
                </div>
                <!-- first flyout -->
                <li *ngFor="let firstLevelFlyout of menuItem.children"
                    [class.current-sub]="getActiveSubMenu(firstLevelFlyout.id)">
                    <ng-container *ngIf="firstLevelFlyout.children && firstLevelFlyout.children.length > 0; else route">
                        <a (click)="setActiveSubMenu(firstLevelFlyout.id, $event)">
                            {{firstLevelFlyout.name}}
                        </a>
                            <ul class="flyout-nav"
                                [style.display]="getActiveSubMenu(firstLevelFlyout.id) ? 'block' : 'none'">
                                <div class="flyout-nav-controls">
                                    <button class="close-btn"
                                            (click)="setActiveSubMenu(firstLevelFlyout.id, $event)">
                                        <app-svg-icon name="close_x"></app-svg-icon>
                                    </button>
                                </div>
                                <!-- second flyout -->
                                <li *ngFor="let secondLevelFlyout of firstLevelFlyout.children">
                                    <ng-container *ngTemplateOutlet="secondLevelFlyout.routerLink === '' ? externalRoute: internalRoute;
                                    context:{link:secondLevelFlyout}">
                                    </ng-container>
                                </li>
                            </ul>
                    </ng-container>
                    <ng-template #route>
                        <ng-container *ngTemplateOutlet="firstLevelFlyout.routerLink === '' ? externalRoute: internalRoute;
                                      context:{link:firstLevelFlyout}">
                        </ng-container>
                    </ng-template>
                </li>
            </ul>
        </div>
    </li>
</ul>
<ng-template #externalRoute
             let-link='link'>
    <a [href]="getRedirectUrl(link.url)" (click)="closeAllFlyout()">
        {{link.name}}
    </a>
</ng-template>
<ng-template #internalRoute
             let-link='link'>
    <a routerLink="{{link.routerLink}}" (click)="closeAllFlyout()">
        {{link.name}}
    </a>
</ng-template>