/*
 * Copyright (c) 2020 – 2021, INOVALON, INC. All Rights Reserved.
 * This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
 * The receipt or possession of this program does not convey any rights to use,
 * reproduce or disclose its contents in whole or in part, without the specific
 * written consent of Inovalon, Inc. Any use, reproduction or disclosure of
 * this program without the express written consent of Inovalon, Inc., is a
 * violation of the copyright laws and may subject you to criminal prosecution.
 */

import { Injectable } from '@angular/core';
import { APP_LOADER_HIDE_DELAY } from '../../constants/loader';

@Injectable({
  providedIn: 'root'
})

/**
 * * This service provides few methods to show, hide & get loading status
 * @class AppLoaderService
 * @method showLoader
 * @method hideLoader
 * @method hideLoaderWithDelay
 * @method get isLoading
 */

export class AppLoaderService {

  private loading = false;

  constructor() {}

  get isLoading() {
    return this.loading;
  }

  showLoader() {
    this.loading = true;
  }

  hideLoader() {
    this.loading = false;
  }

  /**
   * Used to provide a delay of 1s before hiding the loader
   * Useful for hiding loaders before redirects
   * @method hideLoaderWithDelay
   */
  hideLoaderWithDelay(delay: number = APP_LOADER_HIDE_DELAY) {
    setTimeout(() => {
      this.loading = false;
    }, delay);
  }
}
