import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RequestCategory } from "../constants/request-category";
import { LoggerModel } from "../models/logger-model";
import { LoggerService } from "../services/logger.service";

@Injectable({
    providedIn: 'root'
})
export class HttpErrorHandler {
    errorStrings: any = {
        UN_AUTHORIZED: "Unauthorized",
        NOT_FOUND: "Not Found",
        INTERNAL_SERVER_ERROR: "Internal Server Error",
        ERROR: "Error",
        TRIGGER_DOWNLOAD_FAILED: "Triggering download failed",
        POLLING_DOWNLOAD_FAILED: "Polling failed for downloads",
        FILE_DOWNLOAD_FAILED: "Link to download failed"
    }
    constructor(private loggerService: LoggerService) {
    }

    handleError(error, logData: LoggerModel): void {
        if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
                logData.message = `${this.errorStrings.ERROR}: ${error.error}`;
                logData.messageToDisplay = `${this.errorStrings.ERROR}: `
            } else {
                switch (error.status) {
                    case 400:
                        logData.messageToDisplay = `${this.errorStrings.ERROR}: `;
                        break;
                    case 403:
                        logData.messageToDisplay = `${this.errorStrings.UN_AUTHORIZED}: `;     
                        break;
                    case 404:
                        logData.messageToDisplay = `${this.errorStrings.NOT_FOUND}: `;
                        break;
                    case 500:
                         logData.messageToDisplay = `${this.errorStrings.INTERNAL_SERVER_ERROR}: `;
                        break;
                }
                let additionalInfo = "";
                if (logData.requestCategory !== RequestCategory.DATA) {
                    
                    switch(logData.requestCategory) {
                        case RequestCategory.DOWNLOAD_FILE:
                            additionalInfo = ` (${this.errorStrings.FILE_DOWNLOAD_FAILED}) `;
                            break;

                        case RequestCategory.POLLING_DOWNLOAD:
                            additionalInfo = ` (${this.errorStrings.POLLING_DOWNLOAD_FAILED}) `;
                            break;

                        case RequestCategory.TRIGGER_DOWNLOAD:
                            additionalInfo = ` (${this.errorStrings.TRIGGER_DOWNLOAD_FAILED}) `;
                            break;
                    }
                }
                logData.message = `${logData.messageToDisplay} ${additionalInfo} ${error.message}`;
            }
        } else {
            logData.message = error.error;
            logData.messageToDisplay = this.errorStrings.ERROR;
        }
        if (logData.isToLog) {
            this.loggerService.logger(logData);
        } else {
            console.error(logData);
        }
        
    }
}