
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-local-io-rout-tab-nav-bar',
  templateUrl: './local-io-rout-tab-nav-bar.component.html',
  styleUrls: ['./local-io-rout-tab-nav-bar.component.css']
})
export class LocalIoRoutTabNavBarComponent implements OnInit {
  @Input() ioRoutList: [any];

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  activeRouteTab(rla, link) {
    const isMeasurePerformanceRoute = link.path.includes('qpi/measure-performance') && rla.router.url.includes('qpi/measure-performance');
    const isRateMonitoringRoute = link.path.includes('qpi/rate-monitoring') && rla.router.url.includes('qpi/rate-monitoring');
    const isMembershipInsightsRoute = link.path.includes('mi/membership-insights') && rla.router.url.includes('mi/membership-insights');
    const isESRoute = link.path.includes('es/patient-assessment-insights') && rla.router.url.includes('es/patient-assessment-insights');
    return rla.isActive || isMeasurePerformanceRoute || isRateMonitoringRoute || isMembershipInsightsRoute || isESRoute;
  }
}
