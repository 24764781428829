export class ApiEndPoints {
    /** 
     * Overview summary 
     * Commenting them out as these are for mock api, will throw away in future
     * */
    /*static readonly SERVICE_YEARS = 'serviceYears';
    static readonly SERVICES = 'services';
    static readonly STATES = 'states';
    static readonly PROJECTS = 'projects';
    static readonly MEDICAL_GROUPS = 'medicalGroups';
    static readonly SITES = 'sites';
    static readonly PROVIDERS = 'providers';
    static readonly PROVIDER_SPECIALITIES = 'providerSpecialities';
    static readonly DATA_IMPORT_DATES = 'dataImportDates';*/
    //Just to suppress mock api call error
    static readonly SITES_MOCK = 'sites';

    static readonly PROJECT_REPORTS = 'projectReports';
    static readonly PURSUIT_REPORTS = 'pursuitsReport';
    static readonly CHART_REPORTS = 'chartsReport';
    //static readonly SITE_PURSUITS = 'sitePursuits';
    //static readonly SITE_CHARTS = 'siteCharts';
    static readonly CHART_STATUSES = 'chartStatuses';
    static readonly PURSUIT_STATUSES = 'pursuitStatuses';

    /*
    Filters endpoints
    */
    static readonly SERVICE_YEARS = 'indicesmrr-serviceyear';
    static readonly SERVICES = 'indicesmrr-service';
    static readonly STATES = 'indicesmrr-state';
    static readonly PROJECTS = 'indicesmrr-project';
    static readonly MEDICAL_GROUPS = 'indicesmrr-medicalgroup';
    static readonly SITES = 'indicesmrr-site';
    static readonly PROVIDERS = 'indicesmrr-provider';
    static readonly PROVIDER_SPECIALITIES = 'indicesmrr-providerspeciality';
    static readonly DATA_IMPORT_DATES = 'indicesmrr-dataimportdates';
    static readonly VENDORS_FOR_AGING = 'indicesmrr-aging-vendorsforaging';
    static readonly PURSUIT_STATUSES_FOR_AGING = 'indicesmrr-aging-pursuitstatusforaging';
    static readonly PURSUIT_GROUPED_STATUSES_FOR_AGING = 'indicesmrr-aging-pursuitgroupedstatusesforaging';

    /*
    Filters for opportunity identified
    */
   /*static readonly ANALYTICS_RUN_NAME = 'indicesopportunity-analyticsrunname';
   static readonly MEASUREMENT_PERIOD = 'indicesopportunity-measurementperiod';
   static readonly REPORTING_POPULATION = 'indicesopportunity-reportingpopulation';
   static readonly PROVIDERS_OI = 'indicesopportunity-provider';
   static readonly MEDICAL_GROUPS_OI = 'indicesopportunity-medicalgroup';
   static readonly ANALYTICS_RUN_DATE = 'indicesopportunity-analyticsrundate';
   static readonly LINE_OF_BUSINESS = 'indicesopportunity-lineofbusiness';
   static readonly CONDITION_CATEGORY_DESCRIPTION = 'indicesopportunity-conditioncategory';
   static readonly CLINIC_NAME = 'indicesopportunity-clinic';*/

    /*
    Overview
    */
    static readonly PURSUITS_SUMMARY_REPORT_FOR_OVERVIEW = 'indicesmrr-pursuitsreport';
    static readonly CHARTS_SUMMARY_REPORT_FOR_OVERVIEW = 'indicesmrr-chartsreport';
    static readonly SITE_PURSUITS = 'indicesmrr-sitepursuits';
    static readonly SITE_CHARTS = 'indicesmrr-sitecharts';
    static readonly PURSUIT_STATUSES_FOR_OVERVIEW = 'indicesmrr-pursuitstatuses';
    static readonly CHART_STATUSES_FOR_OVERVIEW = 'indicesmrr-chartstatuses';
    static readonly CDE_TOTAL_TARGETS_SENT = 'indicesmrr-overview-totaltargetssentforcde';
    static readonly CDE_CURRENT_TARGETS = 'indicesmrr-overview-currentcdetargets';

    /*
    Api Endpoints for actual apis - Aging report
    indicesmrr-agingreport-topfivechartsbyvendor
    indicesmrr-agingreport-chartsreportforaging
    indicesmrr-agingreport-chartsissuedbyaging
    indicesmrr-agingreport-chartsinprogressbyaging
    indicesmrr-agingreport-agingofcharts
    */
    static readonly PURSUITS_IN_PROGRESS_BY_AGING = 'indicesmrr-agingreport-pursuitsinprogressbyaging';
    static readonly PURSUITS_ISSUED_BY_AGING = 'indicesmrr-agingreport-pursuitsissuedbyaging';
    static readonly PURSUITS_REPORT_FOR_AGING = 'indicesmrr-agingreport-pursuitsreportforaging';
    static readonly TOP_FIVE_PURSUITS_BY_VENDOR = 'indicesmrr-agingreport-topfivepursuitsbyvendor';
    static readonly AGING_OF_PURSUITS = 'indicesmrr-agingreport-agingofpursuits';
    //Charts
    static readonly CHARTS_REPORT_FOR_AGING = 'indicesmrr-agingreport-chartsreportforaging';
    static readonly CHARTS_IN_PROGRESS_BY_AGING = 'indicesmrr-agingreport-chartsinprogressbyaging';
    static readonly CHARTS_ISSUED_BY_AGING = 'indicesmrr-agingreport-chartsissuedbyaging';
    static readonly TOP_FIVE_CHARTS_BY_VENDOR = 'indicesmrr-agingreport-topfivechartsbyvendor';
    static readonly AGING_OF_CHARTS = 'indicesmrr-agingreport-agingofcharts';
    /**
     *  Aging report 
     */
    static readonly SERVICE_YEARS_FOR_AGING = 'serviceYearsForAging';
    static readonly SERVICES_FOR_AGING = 'servicesForAging';
    static readonly STATES_FOR_AGING = 'statesForAging';
    static readonly PROJECTS_FOR_AGING = 'projectsForAging';
    static readonly MEDICAL_GROUPS_FOR_AGING = 'medicalGroupsForAging';
    static readonly SITES_FOR_AGING = 'sitesForAging';
    static readonly PROVIDERS_FOR_AGING = 'providersForAging';
    static readonly PROVIDER_SPECIALITIES_FOR_AGING = 'providerSpecialitiesForAging';
    //static readonly PURSUIT_GROUPED_STATUSES_FOR_AGING = 'pursuitGroupedStatusesForAging';
    //static readonly PURSUIT_STATUSES_FOR_AGING = 'pursuitStatusesForAging';
    static readonly DATA_IMPORT_DATES_FOR_AGING = 'dataImportDatesForAging';
    //static readonly AGING_OF_PURSUITS = 'agingOfPursuits';
    //static readonly AGING_OF_CHARTS = 'agingOfCharts';
    //static readonly VENDORS_FOR_AGING = 'vendorsForAging';
    //static readonly PURSUITS_REPORT_FOR_AGING = 'pursuitsReportForAging';
    //static readonly CHARTS_REPORT_FOR_AGING = 'chartsReportForAging';
    //static readonly TOP_FIVE_PURSUITS_BY_VENDOR = 'topFivePursuitsByVendor';
    //static readonly PURSUITS_IN_PROGRESS_BY_AGING = 'pursuitsInProgressByAging';
    //static readonly PURSUITS_ISSUED_BY_AGING = 'pursuitsIssuedByAging';
    /*static readonly TOP_FIVE_CHARTS_BY_VENDOR = 'topFiveChartsByVendor';
    static readonly CHARTS_IN_PROGRESS_BY_AGING = 'chartsInProgressByAging';*/

    /**
     * Actuals vs Forecasts
     */
    static readonly SERVICE_YEARS_FOR_FORECASTS = 'serviceYearsForForecasts';
    static readonly SERVICES_FOR_FORECASTS = 'servicesForForecasts';
    static readonly PROJECTS_FOR_FORECASTS = 'projectsForForecasts';
    static readonly DATA_IMPORT_DATES_FOR_FORECASTS = 'dataImportDatesForForecasts';
    //static readonly PURSUITS_COMPLETIONS_AND_FORECASTS = 'indicesmrr-actualforecast-pursuitscompletionsandforecasts';

    static readonly PURSUITS_FORECAST_REPORT = 'indicesmrr-actualvsforecast-pursuitforecastreport';
    static readonly CHARTS_FORECAST_REPORT = 'indicesmrr-actualvsforecast-chartsforecastreport';
    static readonly PURSUITS_COMPLETIONS_AND_FORECASTS = 'indicesmrr-actualvsforecast-pursuitsgrid';
    static readonly CHARTS_COMPLETIONS_AND_FORECASTS = 'indicesmrr-actualvsforecast-chartsgrid';
    static readonly PURSUITS_COMPLETIONS_TO_DATE = 'indicesmrr-actualvsforecast-pursuitstodate';
    static readonly CHARTS_COMPLETIONS_TO_DATE = 'indicesmrr-actualvsforecast-chartstodate';

    //static readonly CHARTS_COMPLETIONS_AND_FORECASTS = 'chartsCompletionsAndForecasts';
    static readonly FORECAST_GOALS_FOR_PURSUITS = 'forecastedGoalsForPursuits';
    static readonly FORECAST_GOALS_FOR_CHARTS = 'forecastedGoalsForCharts';
    //static readonly PURSUITS_FORECAST_REPORT = 'pursuitsForecastReport';
    //static readonly CHARTS_FORECAST_REPORT = 'chartsForecastReport';
    //static readonly PURSUITS_COMPLETIONS_TO_DATE = 'indicesmrr-actualvsforecast-pursuitscompletionstodate';
    //static readonly CHARTS_COMPLETIONS_TO_DATE = 'indicesmrr-actualforecast-chartstodate';
    static readonly PURSUITS_COMPLETIONS_ANNUAL = 'indicesmrr-actualvsforecast-pursuitscompletionsannual';
    static readonly CHARTS_COMPLETIONS_ANNUAL = 'indicesmrr-actualvsforecast-chartscompletionsannual';

    /**
     * Activity log
     */
    static readonly SERVICE_YEARS_FOR_ACTIVITY_LOG = 'serviceYearsForActivityLog';
    static readonly SERVICES_FOR_ACTIVITY_LOG = 'servicesForActivityLog';
    static readonly STATES_FOR_ACTIVITY_LOG = 'statesForActivityLog';
    static readonly PROJECTS_FOR_ACTIVITY_LOG = 'projectsForActivityLog';
    static readonly MEDICAL_GROUPS_FOR_ACTIVITY_LOG = 'indicesmrr-medicalgroup';
    static readonly SITES_FOR_ACTIVITY_LOG = 'sitesForActivityLog';
    static readonly PROVIDERS_FOR_ACTIVITY_LOG = 'providersForActivityLog';
    static readonly PROVIDER_SPECIALITIES_FOR_ACTIVITY_LOG = 'providerSpecialitiesForActivityLog';
    static readonly STATUSES_FOR_ACTIVITY_LOG = 'indicesmrr-activitylog-latestactivitystatus';
    static readonly DATA_IMPORT_DATES_FOR_ACTIVITY_LOG = 'dataImportDatesForActivityLog';
    static readonly SITE_DETAILS_FOR_ACTIVITY_LOG = 'indicesmrr-activitylog-sitedetails';
    // static readonly SITE_DETAILS_CHILD_FOR_ACTIVITY_LOG = 'indicesmrr-activitylog-sitedetailschild';
    static readonly SITE_ATTEMPTS_FOR_ACTIVITY_LOG = 'indicesmrr-activitylog-siteattempts';
    static readonly CHARTS_SITE_ACTIVITY_SUMMARY_FOR_ACTIVITY_LOG = 'indicesmrr-activitylog-chartssiteactivitysummary';
    static readonly PURSUITS_SITE_ACTIVITY_SUMMARY_FOR_ACTIVITY_LOG = 'indicesmrr-activitylog-siteactivitysummary';
    static readonly SITE_PURSUITS_AND_CHARTS_FOR_ACTIVITY_LOG = 'indicesmrr-activitylog-sitepursuitsandcharts';
    static readonly CHARTS_SITES_BY_ATTEMPTS_REPORT_FOR_ACTIVITY_LOG = 'indicesmrr-activitylog-chartssitesbyattempts';
    static readonly PURSUITS_SITES_BY_ATTEMPTS_REPORT_FOR_ACTIVITY_LOG = 'indicesmrr-activitylog-sitesbyattempts';
    static readonly SITES_BY_LAST_ACTIVITY_REPORT_FOR_ACTIVITY_LOG = 'indicesmrr-activitylog-sitesbylastactivity';

    /**
     *  Trends 
     */
    static readonly SANKEY_DIAGRAM_DATA_FOR_TREND = 'indicesmrr-trend-trendsankychart';
    static readonly SANKEY_DIAGRAM_CHARTS_DATA_FOR_TREND = 'indicesmrr-trend-trendchartssankychart'
    static readonly COLUMN_CHART_DATA_FOR_TREND = 'indicesmrr-trend-trendbarchart';
    static readonly COLUMN_CHART_CHARTS_DATA_FOR_TREND = 'indicesmrr-trend-trendchartsbarchart';
    static readonly MAX_ACTIVITY_DATE_FOR_TREND = 'indicesmrr-trend-maxactivitydate';

    // pursuit
    static readonly PURSUITS_PRIOR_MONTHLY_GRID_DATA_FOR_TREND = 'indicesmrr-trend-pursuitgroupchangecountsmonthly';
    static readonly PURSUITS_PRIOR_MONTHLY_SUMMARY_TOTAL_PURSUITS_FOR_TREND = 'indicesmrr-trend-summarymonthlytotalpursuitschange';
    static readonly PURSUITS_PRIOR_MONTHLY_SUMMARY_COMPLETED_PURSUITS_FOR_TREND = 'indicesmrr-trend-summarymonthlycompletedpursuitschange';
    static readonly PURSUITS_PRIOR_MONTHLY_SUMMARY_COMPLETED_PERCENTAGE_FOR_TREND = 'indicesmrr-trend-summarymonthlycompletedpercentagechange';
    static readonly PURSUITS_PRIOR_MONTHLY_SUMMARY_AVAILABLE_PURSUITS_FOR_TREND = 'indicesmrr-trend-summarymonthlyavailablepursuitschange';

    static readonly PURSUITS_PRIOR_WEEKLY_GRID_DATA_FOR_TREND = 'indicesmrr-trend-pursuitgroupchangecounts';
    static readonly PURSUITS_PRIOR_WEEKLY_SUMMARY_TOTAL_PURSUITS_FOR_TREND = 'indicesmrr-trend-summarytotalpursuitschange';
    static readonly PURSUITS_PRIOR_WEEKLY_SUMMARY_COMPLETED_PURSUITS_FOR_TREND = 'indicesmrr-trend-summarycompletedpursuitschange';
    static readonly PURSUITS_PRIOR_WEEKLY_SUMMARY_COMPLETED_PERCENTAGE_FOR_TREND = 'indicesmrr-trend-summarycompletedpercentagechange';
    static readonly PURSUITS_PRIOR_WEEKLY_SUMMARY_AVAILABLE_PURSUITS_FOR_TREND = 'indicesmrr-trend-summaryavailablepursuitschange';

    static readonly PURSUITS_WEEKLY_GRID_DATA_FOR_TREND = 'indicesmrr-trend-pursuitgroupcounts';
    static readonly PURSUITS_WEEKLY_SUMMARY_TOTAL_PURSUITS_FOR_TREND = 'indicesmrr-trend-summarytotalpursuits';
    static readonly PURSUITS_WEEKLY_SUMMARY_COMPLETED_PURSUITS_FOR_TREND = 'indicesmrr-trend-summarycompletedpursuits';
    static readonly PURSUITS_WEEKLY_SUMMARY_COMPLETED_PERCENTAGE_FOR_TREND = 'indicesmrr-trend-summarycompletedpercentage';
    static readonly PURSUITS_WEEKLY_SUMMARY_AVAILABLE_PURSUITS_FOR_TREND = 'indicesmrr-trend-summaryavailablepursuits';

    static readonly PURSUITS_MONTHLY_GRID_DATA_FOR_TREND = 'indicesmrr-trend-pursuitgroupcountsmonthly';
    static readonly PURSUITS_MONTHLY_SUMMARY_TOTAL_PURSUITS_FOR_TREND = 'indicesmrr-trend-summarymonthlytotalpursuits';
    static readonly PURSUITS_MONTHLY_SUMMARY_COMPLETED_PURSUITS_FOR_TREND = 'indicesmrr-trend-summarymonthlycompletedpursuits';
    static readonly PURSUITS_MONTHLY_SUMMARY_COMPLETED_PERCENTAGE_FOR_TREND = 'indicesmrr-trend-summarymonthlycompletedpercentage';
    static readonly PURSUITS_MONTHLY_SUMMARY_AVAILABLE_PURSUITS_FOR_TREND = 'indicesmrr-trend-summarymonthlyavailablepursuits';

    // charts
    static readonly CHARTS_PRIOR_MONTHLY_GRID_DATA_FOR_TREND = 'indicesmrr-trend-pursuitgroupchangechartcountsmonthly';
    static readonly CHARTS_PRIOR_MONTHLY_SUMMARY_TOTAL_CHARTS_FOR_TREND = 'indicesmrr-trend-summarymonthlytotalchartschange';
    static readonly CHARTS_PRIOR_MONTHLY_SUMMARY_COMPLETED_CHARTS_FOR_TREND = 'indicesmrr-trend-summarymonthlycompletedpursuitschangecharts';
    static readonly CHARTS_PRIOR_MONTHLY_SUMMARY_COMPLETED_PERCENTAGE_FOR_TREND = 'indicesmrr-trend-summarymonthlycompletedpercentagechangecharts';
    static readonly CHARTS_PRIOR_MONTHLY_SUMMARY_AVAILABLE_CHARTS_FOR_TREND = 'indicesmrr-trend-summarymonthlyavailablechartschange';

    static readonly CHARTS_PRIOR_WEEKLY_GRID_DATA_FOR_TREND = 'indicesmrr-trend-pursuitgroupchangechartcounts';
    static readonly CHARTS_PRIOR_WEEKLY_SUMMARY_TOTAL_CHARTS_FOR_TREND = 'indicesmrr-trend-summarytotalchartschange';
    static readonly CHARTS_PRIOR_WEEKLY_SUMMARY_COMPLETED_CHARTS_FOR_TREND = 'indicesmrr-trend-summarycompletedchartschange';
    static readonly CHARTS_PRIOR_WEEKLY_SUMMARY_COMPLETED_PERCENTAGE_FOR_TREND = 'indicesmrr-trend-summarycompletedpercentagechangecharts';
    static readonly CHARTS_PRIOR_WEEKLY_SUMMARY_AVAILABLE_CHARTS_FOR_TREND = 'indicesmrr-trend-summaryavailablechartsschange';

    static readonly CHARTS_WEEKLY_GRID_DATA_FOR_TREND = 'indicesmrr-trend-pursuitgroupcountscharts';
    static readonly CHARTS_WEEKLY_SUMMARY_TOTAL_CHARTS_FOR_TREND = 'indicesmrr-trend-summarytotalcharts';
    static readonly CHARTS_WEEKLY_SUMMARY_COMPLETED_CHARTS_FOR_TREND = 'indicesmrr-trend-summarycompletedcharts';
    static readonly CHARTS_WEEKLY_SUMMARY_COMPLETED_PERCENTAGE_FOR_TREND = 'indicesmrr-trend-summarycompletedpercentagecharts';
    static readonly CHARTS_WEEKLY_SUMMARY_AVAILABLE_CHARTS_FOR_TREND = 'indicesmrr-trend-summaryavailablecharts';

    static readonly CHARTS_MONTHLY_GRID_DATA_FOR_TREND = 'indicesmrr-trend-pursuitgroupcountsmonthlycharts';
    static readonly CHARTS_MONTHLY_SUMMARY_TOTAL_CHARTS_FOR_TREND = 'indicesmrr-trend-summarymonthlytotalcharts';
    static readonly CHARTS_MONTHLY_SUMMARY_COMPLETED_CHARTS_FOR_TREND = 'indicesmrr-trend-summarymonthlycompletedcharts';
    static readonly CHARTS_MONTHLY_SUMMARY_COMPLETED_PERCENTAGE_FOR_TREND = 'indicesmrr-trend-summarymonthlycompletedpercentagecharts';
    static readonly CHARTS_MONTHLY_SUMMARY_AVAILABLE_CHARTS_FOR_TREND = 'indicesmrr-trend-summarymonthlyavailablecharts';



    /**
    *  Extracts 
    */
    static readonly USER_OVERVIEW_COLUMNS_LAYOUT = 'overview';
    static readonly USER_ACTIVITY_COLUMNS_LAYOUT = 'activity';
    static readonly OVERVIEW_DRILL_DOWN_GRID_DATA_FOR_EXTRACT = 'indicesmrr-extract-download';
    static readonly ACTIVITY_DRILL_DOWN_GRID_DATA_FOR_EXTRACT = 'indicesmrr-activitydrilldown-download';
    static readonly MEMBERS = 'indicesmrr-extract-member';
}