import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@io-angular/auth';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { OktaService } from './okta.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomCookieGuard implements CanActivate {

  constructor(private authService : AuthService,private router :Router,private oktaService:OktaService) { }

  createQueryParams(obj:any) {
    const params = new URLSearchParams(obj);
    const queryString = params.toString();    
    return queryString ? `?${queryString}` : '';
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthenticated = await firstValueFrom(this.authService.isAuthenticated$);
    if(!isAuthenticated){
      const url = new URL(window.location.href);  
      const params = new URLSearchParams(url.search);
      if(params.get('idp') !== null&&["okta","b2c","b2c-federated"].includes(params.get('idp').trim().toLowerCase())){
        localStorage.setItem("cookieRedirectionURL",state.url);      
      }
    }
    return true; 
  }
}
