import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { OktaService } from "../factory/okta.service";
import { InterceptorInclude, isValid } from '@io-angular/classes';

@Injectable({
    providedIn: 'root'
})
export class AuthV2Interceptor implements HttpInterceptor {
    constructor(private oktaService: OktaService) { }


    getModifiedRequest(req: HttpRequest<any>){
        if (req.headers && req.headers.has(InterceptorInclude)){
            const accessToken: string = localStorage.getItem('access_token');
            const idToken: string = localStorage.getItem('id_token');
            if (isValid(accessToken) === true) {
                req = req.clone({
                    headers: req.headers.set('Authorization', `Bearer ${accessToken}`)
                });
            }
    
            if (isValid(idToken) === true) {
                req = req.clone({
                    headers: req.headers.set('idToken', idToken)
                });
            }
            if (!req.headers.has('Content-Type')) {
                req = req.clone({
                    headers: req.headers.set('Content-Type', 'application/json')
                });
            }
    
            req = req.clone({
                headers: req.headers.set('Accept', 'application/json')
            });
        }
        if (req.headers.has(InterceptorInclude)) {
			req = req.clone({
				headers: req.headers.delete(InterceptorInclude)
			});
		}
        return req;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = this.getModifiedRequest(req);
        return next.handle(req)
        .pipe(
            catchError(error => {
                if (error.status === 401) {
                    const accessToken = this.oktaService.getAccessToken();
                    if (!accessToken) {
                        this.oktaService.logout();
                    }
                    const newReq = req.clone({
                        headers: new HttpHeaders({
                            'Authorization': `Bearer ${accessToken}`
                        })
                    });
                    return next.handle(newReq).pipe(
                        catchError(error => {
                            if (error.status === 401) {    //If again error
                                this.oktaService.logout();
                            }
                            throw error;
                        })
                    );
                }
                throw error;
            })
        );
    }
}