export class MIFilterLabels {
    static readonly ALL = "All";
}

export class MIFilterFields {
    static readonly PROJECT_NAME = "projectName";
    static readonly PAYER_STATE = "payerState";
    static readonly LOB = "lob";
    static readonly ENROLLED_MONTH = "enrolledMonth";
    static readonly MEMBER_STATE = "memberState";
    static readonly MEMBER_COUNTY = "memberCounty";
    static readonly POPULATION = "population";
    static readonly MEDICAL_GROUP = "medicalGroup";
    static readonly CLINIC = "clinic";
    static readonly PROVIDER = "provider";
    static readonly COHORT = "cohort";
}

export class MIFiltersBinding {
    static readonly bindingInfo = {
        [MIFilterFields.PROJECT_NAME]: {
            idField: "projectId",
            label: "Project Name"
        },
        [MIFilterFields.PAYER_STATE]: {
            idField: "payerStateId",
            label: "Payer State"
        },
        [MIFilterFields.LOB]: {
            idField: "lobId",
            label: "LOB"
        },
        [MIFilterFields.ENROLLED_MONTH]: {
            idField: "enrolledMonthId",
            label: "Enrolled Month"
        },
        [MIFilterFields.MEMBER_STATE]: {
            idField: "memberStateId",
            label: "Member State"
        },
        [MIFilterFields.MEMBER_COUNTY]: {
            idField: "memberCountyId",
            label: "Member County"
        },
        [MIFilterFields.POPULATION]: {
            idField: "populationId",
            label: "Population"
        },
        [MIFilterFields.COHORT]: {
            idField: "cohortId",
            label: "Cohort"
        },
        [MIFilterFields.MEDICAL_GROUP]: {
            idField: "medicalGroupId",
            label: "Medical Group"
        },
        [MIFilterFields.CLINIC]: {
            idField: "clinicId",
            label: "Clinic"
        },
        [MIFilterFields.PROVIDER]: {
            idField: "providerId",
            label: "Provider"
        }

    }
}
