import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@io-angular/auth';
import { OktaService } from 'src/app/factory/okta.service';


@Injectable({
  providedIn: 'root'
})
export class CloudWatchLoggerService {

  baseUrl: string;

  constructor(
    private http: HttpClient,
    // private oktaService : OktaService
  ) {
    this.baseUrl = `${localStorage.getItem('baseApiUrl')}/${localStorage.getItem('stage')}/cloud-watch-log`;
  }

  saveData(body) {
    const url = `${this.baseUrl}`;
    this.http.post<any>(url, body, this.getHeaders()).subscribe((response) => {
      // do nothing, silently log to cloud watch
    },
      (err) =>
        console.error({
          type: 'error',
          class: 'CloudWatchLoggerService',
          method: 'saveData',
          message: `Log to cloud watch failed.`,
          body,
          error: err
        })
    );
  }

  getHeaders() {
    const accessToken = localStorage.getItem("access_token");
    let headerOptions = { headers: new HttpHeaders().set('Authorization', 'Bearer ' + accessToken) };
    return headerOptions
  }
}
