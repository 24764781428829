
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-definitions',
  templateUrl: './svg-definitions.component.html',
  styles: ['.svg-defs {height: 0;position: absolute;visibility: hidden;width: 0;} .cls-1{fill: none;stroke: #fff;stroke-linecap: round;stroke-miterlimit: 10;stroke-width: 1.25px}']
})
export class SvgDefinitionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
