import { Observable, throwError, timer } from 'rxjs';
import { mergeMap, finalize } from 'rxjs/operators';
import { HttpRetrySettings } from '../constants/http-retry-settings';

export const httpRetryStrategy = (loggerService, {
    maxRetryAttempts = HttpRetrySettings.MAX_HTTP_RETRIES,
    scalingDuration = HttpRetrySettings.RETRY_SCALE_DURATION,
    excludedStatusCodes = HttpRetrySettings.EXCLUDED_CODES,
    logData = {}
}: {
    maxRetryAttempts?: number,
    scalingDuration?: number,
    excludedStatusCodes?: number[],
    logData?: {}
} = {}) => (attempts: Observable<any>) => {
    return attempts.pipe(
        mergeMap((error, i) => {
            const retryAttempt = i + 1;
            if (
                retryAttempt > maxRetryAttempts ||
                excludedStatusCodes.find(e => e === error.status)
            ) {
                return throwError(error);
            }
            /**
             * Need to add logger here
            */
            return timer(retryAttempt * scalingDuration);
        }),
        finalize(() => console.log('Retry completed - http call failed'))
    );
};