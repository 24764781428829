
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { AppSettingsService } from './app-setting.service';
// import { OktaConfig } from '@okta/okta-angular/dist/okta/models/okta.config';
import { Injectable, Injector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { IAppSettings } from './Iapp.settings';
import { environment } from '../../environments/environment';
// import { OktaConfig } from '@okta/okta-angular';

export function preInitServiceFactory(
  initService: PreInitService,
  injector: Injector,
) {
  return () =>
    new Promise(resolve =>
      injector
        .get(LOCATION_INITIALIZED, Promise.resolve(undefined))
        .then(async () => resolve(await initService.loadConfiguration())),
    );
}

@Injectable()
export class PreInitService {

  // oktaConfig: OktaConfig;
  appSettings: IAppSettings;

  constructor(private appSettingsService: AppSettingsService) { }

  public async loadConfiguration() {

    localStorage.setItem('stage', environment.name);
    localStorage.setItem('baseApiUrl', environment.BASE_API_URL);
    localStorage.setItem('indicesMenuApiurl', environment.INDICES_MENU_API_URL);
    localStorage.setItem('indicesAppUrl', environment.INDICES_APP_URL);
    //localStorage.setItem('springbaseApiUrl', environment.SPRINT_BASE_API_URL);
    localStorage.setItem('hitrustBaseUrl', environment.HI_TRUST_BASE_URL);
    this.appSettingsService.setSettings({ aptrinsicTag: environment.APTRINSIC_TAG });

    // this.oktaConfig = {
    //   clientId: environment.CLIENT_ID,
    //   issuer: environment.ISSUER,
    //   redirectUri: environment.REDIRECTURI,
    //   scopes: ['openid', 'profile', 'email'],
    //   pkce: false
    // };

    // this.appSettings = { oktaConfig: this.oktaConfig };
    this.appSettingsService.setSettings(this.appSettings);
  }
}
