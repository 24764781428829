import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@io-angular/auth';
import { OktaService } from 'src/app/factory/okta.service';

@Injectable({
  providedIn: 'root'
})
export class HiTrustLoggerService {

  constructor(
    private http: HttpClient,
    // private oktaService:OktaService
  ) {
  }

  saveData(message, upn, stage) {
    const eventTimeStamp = new Date().toISOString().slice(0, -5) + 'Z';
    const body = {
      action: 'View',
      actionParameters: [{}],
      endTime: eventTimeStamp,
      eventId: null,
      eventType: 'HITRUST',
      logEntryType: 'Single',
      objects: [
        {
          objectParamters: [
            { customerName: message.requestBody.customerName },
            { look: message.requestBody.look },
            { model: message.requestBody.model },
            { view: message.requestBody.view },
          ],
          objectIdType: 'LookerQuery',
          objectIdValue: message.requestBody.look,
          objectType: 'PatientDetailData'
        }
      ],
      productName: 'INDICES VBPI',
      simulatedUserId: null,
      startTime: eventTimeStamp,
      userId: upn,
    };
    body.objects[0].objectParamters.push(message.requestBody.filters);
    const url = `${localStorage.getItem('baseApiUrl')}/${localStorage.getItem('stage')}/hitrust`;
    const requestBody = {
      url: `${localStorage.getItem('hitrustBaseUrl')}`,
      body
    };

    this.http.post<any>(url, requestBody, this.getHeaders()).subscribe((response) => {
      if (stage !== 'prod') {
        console.log('HiTrust', { url, requestBody, response });
      }
    }, (err) =>
      console.error({
        type: 'error',
        class: 'HiTrustLoggerService',
        method: 'saveDataToHiTrust',
        message: `Log to Hitrust failed.`,
        url,
        requestBody,
        error: err
      })
    );
  }

  getHeaders() {
    const accessToken = localStorage.getItem("access_token");
    let headerOptions = { headers: new HttpHeaders().set('Authorization', 'Bearer ' + accessToken) };
    return headerOptions;
  }
}
