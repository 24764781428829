<io-content 
    ioHasNoTopPadding="true"
    ioHasNoRightPadding="true"
    ioHasNoBottomPadding="true"
    ioHasNoLeftPadding="true">
    <div *ngFor="let item of ioMessageBarService.getMessages()">    
        <io-message-bar-item 
            [id]="item.id"
            [type]="item.type">
            {{item.message}}
        </io-message-bar-item>
    </div>   
</io-content>

