import { Inject, Injectable } from '@angular/core';
import { IoMessageBarService } from '../../shared/message-bar/io-message-bar.service';
import { CloudWatchLoggerService } from './cloud-watch-logger.service';
import { HiTrustLoggerService } from './hi-trust-logger.service';
import { AuthService } from '@io-angular/auth';
import { OktaService } from 'src/app/factory/okta.service';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  stage: string;
  isInternalTestEnvironment: boolean;
  isDebug = true;
  upn: string;
  customerName: string;

  constructor(
    public ioMessageBarService: IoMessageBarService,
    public cloudWatchLoggerService: CloudWatchLoggerService,
    public hiTrustLoggerService: HiTrustLoggerService,
    // private oktaService : OktaService
  ) {
    this.stage = localStorage.getItem('stage');
    this.isInternalTestEnvironment = (this.stage === 'local') || (this.stage === 'dev') || (this.stage === 'qa');
  }

  logger(message) {
    // this.isDebug = false;
    message.app = "INDICES 3.0";

    if (this.upn) {
      if (!message.upn) {
        message.upn = this.upn;
      }
    }

    if (this.customerName) {
      if (!message.customerName) {
        message.customerName = this.customerName;
      }
    }

    // if (message.hitrust) {
    //   this.logToHiTrust(message);
    // }

    if (message.type === 'error' && this.stage !== 'prod') {
      console.error(message);
      this.displayMessageBar(message);
      this.logToCloudWatch(message);
    } else {
      if (this.isDebug) {
        if (!message.type) {
          message.type = 'info';
        }
      }
      if (message.type === 'response') {
        if (this.isInternalTestEnvironment && this.isDebug) {
          // display response messages in the browser console in every stage except prod
          console.log(message);
        }
      } else {
        if (this.stage === 'local') {
          if (!message.hitrust && this.isDebug) {
            // only display info messages in the browser console when the stage equals local
            console.log(message);
          }
        }
      }
      this.logToCloudWatch(message);
    }
  }

  displayMessageBar(message) {
    this.ioMessageBarService.messages.forEach((message) => {
      this.ioMessageBarService.closeItem(message.id);
    });
    const messageSuffix = `Please try again or contact your system administrator.`;
    const userMessage = message.messageToDisplay ? `${message.messageToDisplay} ${messageSuffix}` : `There was a system error. ${messageSuffix}`;
    this.ioMessageBarService.addItem(message.type, userMessage);
  }

  logToCloudWatch(message) {
    const accessToken = localStorage.getItem("access_token");
    if (message.response) {
      message.responseRecordCount = message.response.length;
    }

    if (accessToken) {
      this.cloudWatchLoggerService.saveData(message);
    }
  }

  logToHiTrust(message) {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      this.hiTrustLoggerService.saveData(message, this.upn, this.stage);
    }
  }
}
