export class QPIFilterLabels {
    static readonly ALL = "All";
}

export class QPIFilterFields {
    static readonly PROJECT_NAME = "projectName";
    static readonly MEASUREMENT_PERIOD = "measurementPeriod";
    static readonly FLOWCHART_RUN_NAME = "analyticRunNameDate";
    static readonly FLOWCHART_RUN_NAME1 = "analyticRunNameDate1";
    static readonly FLOWCHART_RUN_NAME2 = "analyticRunNameDate2";
    static readonly MEASURE_ACRONYM = "measureAcronym";
    static readonly ELIGIBILITY = "eligibilityStatusName";
    static readonly COHORT = "cohortName";
    static readonly CATALOG_NAME = "catalogName";
    static readonly POPULATION = "reportingPopulationName";
    static readonly MEASURE_DESCRIPTION = "measureDescriptionName";
    static readonly CATEGORY = "measureCategoryName";
}

export class QPIFiltersBinding {
    static readonly bindingInfo = {
        [QPIFilterFields.PROJECT_NAME]: {
            idField: "projectId",
            label: "Project Name"
        },
        [QPIFilterFields.MEASUREMENT_PERIOD]: {
            idField: "measurementPeriodId",
            label: "Measurement Period"
        },
        [QPIFilterFields.FLOWCHART_RUN_NAME]: {
            idField: "analyticRunId",
            label: "Flowchart Run Name"
        },
        [QPIFilterFields.FLOWCHART_RUN_NAME1]: {
            idField: "analyticRunId1",
            label: "Flowchart Run Name"
        },
        [QPIFilterFields.FLOWCHART_RUN_NAME2]: {
            idField: "analyticRunId2",
            label: "Flowchart Run Name"
        },
        [QPIFilterFields.MEASURE_ACRONYM]: {
            idField: "measureAcronymId",
            label: "Measure Acronym"
        },
        [QPIFilterFields.ELIGIBILITY]: {
            idField: "eligibilityStatusId",
            label: "Eligibility"
        },
        [QPIFilterFields.COHORT]: {
            idField: "cohortId",
            label: "Cohort"
        },

        [QPIFilterFields.CATALOG_NAME]: {
            idField: "catalogNameId",
            label: "Catalog Name"
        },
        [QPIFilterFields.POPULATION]: {
            idField: "reportingPopulationName",
            label: "Population"
        },
        [QPIFilterFields.MEASURE_DESCRIPTION]: {
            idField: "measureDescriptionId",
            label: "Measure Description"
        },
        [QPIFilterFields.CATEGORY]: {
            idField: "measureCategoryId",
            label: "Category"
        }
    }
}

export class MemberSearchData {
    static readonly MEMBER_NAME = 'memberName';
    static readonly MEMBER_ID = 'memberId';
    static readonly MEMBER_NAME_ID = 'memberNameId';
}