export class InterTabsNavigationParams {
    fromIdx?: number;
    toIdx?: number;
    data?: any;
    selectedFilters?: SelectedFilters;
    appliedFilters?: SelectedFilters;
    appliedOptions?: SelectedOptions;

    constructor(fromIdx = 0, toIdx = 0, data = {}, selectedFilters = {}, appliedFilters = {}, appliedOptions = {}) {
        this.fromIdx = fromIdx;
        this.toIdx = toIdx;
        this.data = data;
        this.selectedFilters = selectedFilters;
        this.appliedFilters = appliedFilters;
        this.appliedOptions = appliedOptions;
    }
}

export interface InterTabsNavigation {
    navigateToTab(params: InterTabsNavigationParams);
}
export interface SelectedFilters {
    stateCode?: Array<string>;
    projectId?: Array<number>;
    serviceId?: Array<number>;
    yearId?: Array<number>;
    medicalGroupId?: Array<number>;
    siteId?: Array<number>;
    specialityId?: Array<number>;
    vendorId?: Array<number>;
    providerId?: Array<number>;
    statusId?: Array<number>;
    activityStatusId?: Array<number>;
    importDate?: Array<string>;
}
export interface SelectedOptions {
    stateCode?: Array<{}>;
    projectId?: Array<{}>;
    serviceId?: Array<{}>;
    yearId?: Array<{}>;
    medicalGroupId?: Array<{}>;
    siteId?: Array<{}>;
    specialityId?: Array<{}>;
    vendorId?: Array<{}>;
    providerId?: Array<{}>;
    statusId?: Array<{}>;
    activityStatusId?: Array<{}>;
    importDate?: Array<{}>;
}