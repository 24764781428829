
<!--
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
-->

<app-svg-definitions></app-svg-definitions>

<div class="app-loading" *ngIf="appLoaderService.isLoading">
  <div class="logo">
    <svg class="spinner" viewBox="25 25 50 50">
      <circle
        class="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="2"
        stroke-miterlimit="10"
      ></circle>
    </svg>
  </div>
</div>

<div class="page-wrap" *ngIf="!appLoaderService.isLoading">
    <app-left-nav (collapseMenuEvt)="collapseMenu($event)" *ngIf="isAuthenticated"></app-left-nav>
    <div 
        class="page-content"
        [class.content-expanded]="isMenuCollapsed">
            <app-top-nav *ngIf="isAuthenticated"></app-top-nav>
            <io-message-bar></io-message-bar>
            <router-outlet></router-outlet>
            <div class="disclaimer-text" *ngIf="isAuthenticated">{{menuService.footerText}}</div>
    </div>   
</div>
