
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenModel } from '../models/token.model';
import { LoggerService } from '../shared/services/logger.service';
import { environment } from '../../environments/environment';
import { OktaService } from '../factory/okta.service';

@Injectable({
  providedIn: 'root'
})
export class IndicesMenuApiService {

  baseUrl = environment.INDICES_MENU_API_URL; //localStorage.getItem('indicesMenuApiurl');
  isLogging = false;

  constructor(
    private http: HttpClient,
    private tokenModel: TokenModel,
    private loggerService: LoggerService,
    private oktaService: OktaService
  ) {
    this.isLogging = false;
  }

  get(callingMethod, url): Observable<any> {
    url = `${this.baseUrl}${url}`;
    if (this.isLogging) {
      this.log(callingMethod, url, {}, this.tokenModel.token);
    }
    const result = this.http.get<any>(url, this.getHeaders());
    return result;
  }

  post(callingMethod, url, requestBody): Observable<any> {
    url = `${this.baseUrl}${url}`;
    if (this.isLogging) {
      this.log(callingMethod, url, {}, this.tokenModel.token);
    }
    const result = this.http.post<any>(url, requestBody, this.getHeaders());
    return result;
  }

  log(callingMethod = '', apiUrl, reqBody = {}, token) {
    this.loggerService.logger({
      type: 'info',
      class: 'IndicesUserActivityService',
      method: callingMethod,
      url: apiUrl,
      body: reqBody
    });
  }

  getHeaders() {
    let headerOptions = { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.oktaService.getAccessToken()) };
    return headerOptions;
  }
}
