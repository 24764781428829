export class ApiKeywords {
    static readonly SORT_BY = '_sort';
    static readonly SORT_BY_STRING = 'sortBy';
    static readonly ORDER_BY = '_order';
    static readonly ORDER_BY_STRING = 'orderBy';
    static readonly START_AT = '_start';
    static readonly START_AT_STRING = 'start';
    static readonly END_AT = '_end';
    static readonly END_AT_STRING = 'end';
    static readonly ASCENDING_STRING = 'asc';
    static readonly DESCENDING_STRING = 'desc';
    static readonly LIKE_OPERATOR = '_like';
    static readonly EQUAL_OPERATOR = '=';
    static readonly FORWARD_SLASH = '/';
    static readonly AMPERSAND = '&';
    static readonly QUESTION_MARK = '?';
    static readonly EMPTY_STRING = '';
    static readonly COMMA = ',';
    static readonly REQUESTOR_PARAM = 'RequestorUID';
}