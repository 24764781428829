import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';

import { HomeSharedService } from './home-shared.service';
import { ApiEndPoints } from '../m-constants/api-endpoints';
import { HomeConstants } from '../../../shared/constants/home-constants';
import { LookerApiService } from '../../../shared/services/looker-api.service';
import { FilterFields } from '../m-constants/filters-binding';
import { InterTabsNavigationParams } from '../../../shared/models/intertabs-navigation.model';
import { IntertabsNavigationService } from './intertabs-navigation.service';
import {
  getGridDefaultFields,
  getGridDefaultDimensionFields,
  getOverviewSummaryDefaultFields,
  getGridDefaultSort,
} from '../m-helper/h-overview';
import { CDEConstants, GridConstants, OverviewMRRPanelConstants } from '../m-constants/h-overview-constants';
import { map } from 'rxjs/operators';

@Injectable()
export class OverviewSharedService {
  $summaryData: BehaviorSubject<any>;
  $summaryDataLoading: BehaviorSubject<boolean>;
  $mrrPanelDataLoading: BehaviorSubject<boolean>;
  $gridDataLoading: BehaviorSubject<boolean>;
  $searchInputdataLoading: BehaviorSubject<boolean>;
  $gridData: BehaviorSubject<any>;
  $searchBarData: BehaviorSubject<any>;
  $mrrPanelData: BehaviorSubject<any>;
  $cdeData: BehaviorSubject<any>;
  $cdeDataLoading: BehaviorSubject<boolean>;

  chartsOrPursuits: string = HomeConstants.CHARTS_TEXT;
  siteIdAttribute: string = FilterFields.SITE_ID;
  appliedFilters: any = {};
  appliedOptions: any = {};
  gridSpecificFilters: any = {};
  downloadGridData = [];
  gridDimensionFields = getGridDefaultDimensionFields();
  gridFields = this.gridDimensionFields.concat(getGridDefaultFields(this.chartsOrPursuits));
  searchedSites = [];
  tabs = HomeConstants.tabs;
  navigationParams: InterTabsNavigationParams = new InterTabsNavigationParams();
  fields = GridConstants.Fields;
  filterKeyForCDE: any = CDEConstants.FilterKeyForCurrentTargets;

  searchBarFilter = {};

  toggleEndPoints = {
    [HomeConstants.CHARTS_TEXT]: {
      summary: ApiEndPoints.CHARTS_SUMMARY_REPORT_FOR_OVERVIEW,
      site: ApiEndPoints.SITE_CHARTS,
      statuses: ApiEndPoints.CHART_STATUSES_FOR_OVERVIEW,
    },
    [HomeConstants.PURSUITS_TEXT]: {
      summary: ApiEndPoints.PURSUITS_SUMMARY_REPORT_FOR_OVERVIEW,
      site: ApiEndPoints.SITE_PURSUITS,
      statuses: ApiEndPoints.PURSUIT_STATUSES_FOR_OVERVIEW,
    }
  }

  constructor(
    private lookerApiService: LookerApiService,
    private homeSharedService: HomeSharedService,
    private interTabNavigationService: IntertabsNavigationService
  ) {
    this.$summaryData = new BehaviorSubject<any>(null);
    this.$summaryDataLoading = new BehaviorSubject<boolean>(true);
    this.$mrrPanelDataLoading = new BehaviorSubject<boolean>(true);
    this.$gridDataLoading = new BehaviorSubject<boolean>(true);
    this.$searchInputdataLoading = new BehaviorSubject<boolean>(true);
    this.$gridData = new BehaviorSubject(null);
    this.$searchBarData = new BehaviorSubject(null);
    this.$mrrPanelData = new BehaviorSubject<any>(null);
    this.$cdeData = new BehaviorSubject(null);
    this.$cdeDataLoading = new BehaviorSubject<boolean>(true);
    this.navigationParams.fromIdx = this.tabs.OVERVIEW_IDX;
    this.navigationParams.toIdx = this.tabs.EXTRACT_IDX;
    this.searchBarFilter = { [this.fields.SITE_ID]: [] };

  }

  getChartsOrPursuits() {
    return this.homeSharedService.getChartsOrPursuits();
  }

  refreshData(): void {
    this.setGridFields();
    this.fetchOverviewSummaryData();
    this.fetchGridData();
    this.fetchMRRStatusPanelData();
    this.fetchSearchBarData();
  }

  getGridData() {
    return this.$gridData;
  }

  setGridData(value) {
    this.$gridData.next(value);
  }

  getSearchBarData() {
    return this.$searchBarData;
  }

  setSearchBarData(value) {
    this.$searchBarData.next(value);
  }

  getCDEData() {
    return this.$cdeData;
  }

  setCDEData(value) {
    this.$cdeData.next(value);
  }

  getGridDimensionFields() {
    return this.gridDimensionFields;
  }

  setGridDimensionFields(fields) {
    this.gridDimensionFields = Object.assign([], fields);
    this.setGridFields();
  }

  private setGridFields() {
    this.gridFields = Object.assign([], this.gridDimensionFields.concat(getGridDefaultFields(this.chartsOrPursuits)));
  }

  getGridFields() {
    return this.gridFields;
  }

  fetchGridData(gridFields = this.gridFields, defaultSort = getGridDefaultSort(this.chartsOrPursuits)) {
    this.$gridDataLoading.next(true);
    this.lookerApiService.getWithFilter(
      this.toggleEndPoints[this.chartsOrPursuits].site,
      this.appliedFilters,
      gridFields,
      defaultSort
    ).subscribe(data => {
      this.downloadGridData = data.slice(0, 5);
      this.setGridData(data);
      this.$gridDataLoading.next(false);
    });
  }

  getGridDataBySiteIds(filters, gridFields = this.gridFields, defaultSort = getGridDefaultSort(this.chartsOrPursuits)) {
    this.$gridDataLoading.next(true);
    this.lookerApiService.getWithFilter(
      this.toggleEndPoints[this.chartsOrPursuits].site,
      filters,
      gridFields,
      defaultSort
    ).subscribe(data => {
      this.setGridData(data);
      this.$gridDataLoading.next(false);

    });
  }

  fetchSearchBarData() {
    this.$searchInputdataLoading.next(true);
    this.lookerApiService.getWithFilter(ApiEndPoints.SITES, this.appliedFilters, [], [], -1).subscribe(data => {
      this.$searchBarData.next(data);
      this.$searchInputdataLoading.next(false);
    });
  }

  fetchOverviewSummaryData() {
    this.$summaryDataLoading.next(true);
    this.lookerApiService.getWithFilter(
      ApiEndPoints.PROJECTS,
      this.appliedFilters
    ).subscribe(projects => {
      if (projects) {
        const includeSummaryFields = projects.length !== 1;
        this.lookerApiService.getWithFilter(
          this.toggleEndPoints[this.chartsOrPursuits].summary,
          this.appliedFilters,
          includeSummaryFields ? getOverviewSummaryDefaultFields(this.chartsOrPursuits) : undefined
        ).subscribe((data) => {
          this.$summaryDataLoading.next(false);
          this.$summaryData.next(data);
        });
      }
    });
  }

  getOverviewSummaryData() {
    return this.$summaryData;
  }

  getOverviewSummaryLoadingStatus() {
    return this.$summaryDataLoading;
  }

  getMrrPanelLoadingStatus() {
    return this.$mrrPanelDataLoading;
  }

  getCDELoadingStatus() {
    return this.$cdeDataLoading;
  }

  getGridLoadingStatus() {
    return this.$gridDataLoading;
  }

  fetchMRRStatusPanelData(filters = undefined) {
    this.$mrrPanelDataLoading.next(true);
    this.lookerApiService.getWithFilter(
      this.toggleEndPoints[this.chartsOrPursuits].statuses,
      filters && Object.keys(filters).length > 0 ? filters : this.appliedFilters
      , [],
      [`${OverviewMRRPanelConstants.Keys[this.chartsOrPursuits].PURSUIT_GROUPED_STATUS} desc`]
    ).subscribe((mrrData) => {
      this.$mrrPanelData.next(mrrData);
      this.$mrrPanelDataLoading.next(false);
    });
  }

  fetchCDEData() {
    var filtersForCDE = {};
    if (this.appliedFilters[FilterFields.PROJECT_ID]) {
      filtersForCDE[FilterFields.PROJECT_ID] = this.appliedFilters[FilterFields.PROJECT_ID];
    }

    if (this.appliedFilters[FilterFields.SERVICE_ID]) {
      filtersForCDE[FilterFields.SERVICE_ID] = this.appliedFilters[FilterFields.SERVICE_ID];
    }

    var additionalFiltersForCDE = JSON.parse(JSON.stringify(filtersForCDE));
    additionalFiltersForCDE[this.filterKeyForCDE] = null;
    this.$cdeDataLoading.next(true);
    forkJoin([
      this.lookerApiService.getWithFilter(ApiEndPoints.CDE_TOTAL_TARGETS_SENT, filtersForCDE),
      this.lookerApiService.getWithFilter(ApiEndPoints.CDE_CURRENT_TARGETS, additionalFiltersForCDE)
    ]).subscribe((dataList) => {
      this.$cdeData.next(dataList);
      this.$cdeDataLoading.next(false);
    });
  }

  getMRRStatusPanelData() {
    return this.$mrrPanelData;
  }

  fetchMRRStatusPanelDataForSite(appliedFilters) {
    this.$mrrPanelDataLoading.next(true);
    this.lookerApiService.getWithFilter(
      this.toggleEndPoints[this.chartsOrPursuits].statuses,
      appliedFilters,
      [],
      [`${OverviewMRRPanelConstants.Keys[this.chartsOrPursuits].PURSUIT_GROUPED_STATUS} desc`]
    ).subscribe((mrrData) => {
      this.$mrrPanelData.next(mrrData);
      this.$mrrPanelDataLoading.next(false);
    });
  }

  navigateToExtract(data?: any) {
    if (data) {
      this.navigationParams.data = data;
    }
    this.navigationParams.appliedFilters = this.appliedFilters;
    this.navigationParams.appliedOptions = this.appliedOptions;
    this.interTabNavigationService.navigateToTab(this.navigationParams);
  }

  setGridSpecificFilters(gridSpecificFilters) {
    this.gridSpecificFilters = Object.assign({}, gridSpecificFilters);
  }

  getGridSpecificFilters() {
    return this.gridSpecificFilters;
  }

  getSearchedSites() {
    return this.searchedSites;
  }

  setSearchedSites(value) {
    this.searchedSites = Object.assign([], value);
  }
}
