
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IoComponentsModule, IoConfirmDialogComponent } from '@io-angular/core';
import { LocalIoMenuItemComponent } from './navigation/local-io-menu-item/local-io-menu-item.component';
import { LocalIoRoutTabNavBarComponent } from './navigation/local-io-rout-tab-nav-bar/local-io-rout-tab-nav-bar.component';
import { PreInitService, preInitServiceFactory } from './factory/pre-init.service';
import { OktaCustomComponentComponent } from './okta-custom-component/okta-custom-component.component';
import { DashboardMenuItemComponent } from './navigation/dashboard-menu-item/dashboard-menu-item.component';
import { SvgDefinitionsComponent } from './svg/svg-definitions/svg-definitions.component';
import { SvgIconComponent } from './svg/svg-icon/svg-icon.component';
import { LeftNavComponent } from './navigation/left-nav/left-nav.component';
import { ProductMenuItemComponent } from './navigation/product-menu-item/product-menu-item.component';
import { UtilityMenuItemComponent } from './navigation/utility-menu-item/utility-menu-item.component';
import { UserMenuItemComponent } from './navigation/user-menu-item/user-menu-item.component';
import { LogoutComponent } from './shared/logout/logout.component';
import { SearchMenuItemComponent } from './navigation/search-menu-item/search-menu-item.component';
import { HomeComponent } from './shared/home/home.component';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';
import { ThemeService } from 'ng2-charts';
import { TopNavComponent } from './navigation/top-nav/top-nav.component';
import { MyDownloadsCardComponent } from './shared/download/my-downloads-card/my-downloads-card.component';
import { DownloadListItemComponent } from './shared/download/download-list-item/download-list-item.component';
import { DownloadsFilterPipe } from './pipes/downloads-filter.pipe';
import { FileDownloadPopupComponent } from './shared/download/file-download-popup/file-download-popup.component';
import { ExplorerComponent } from './shared/explorer/explorer.component';
import { OverviewSharedService } from './rli/mrr/m-shared/m-services/overview-shared.service';
import { HomeSharedService } from './rli/mrr/m-shared/m-services/home-shared.service';
import { DatePipe } from '@angular/common';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { InvalidurlComponent } from './shared/invalidurl/invalidurl.component';
import { LookerApiService } from './qpi/shared/services/qpi-looker-api.service';
import { OktaService } from './factory/okta.service';
import { OidcModule } from '@io-angular/auth';
import { SessionManagementModule } from '@io-angular/session-management';
import { AuthModule } from '@io-angular/auth';
import { EnvironmentService } from './services/environment.service';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AuthV2Interceptor } from './interceptors/authv2.interceptor';
import {CookieService} from 'ngx-cookie-service';
import { MessageBarModule } from './shared/message-bar/message-bar.module';

@NgModule({
    declarations: [
        AppComponent,
        LocalIoMenuItemComponent,
        LocalIoRoutTabNavBarComponent,
        OktaCustomComponentComponent,
        DashboardMenuItemComponent,
        SvgDefinitionsComponent,
        SvgIconComponent,
        LeftNavComponent,
        TopNavComponent,
        ProductMenuItemComponent,
        UtilityMenuItemComponent,
        UserMenuItemComponent,
        LogoutComponent,
        SearchMenuItemComponent,
        HomeComponent,
        UnauthorizedComponent,
        MyDownloadsCardComponent,
        DownloadListItemComponent,
        DownloadsFilterPipe,
        FileDownloadPopupComponent,
        ExplorerComponent,
        InvalidurlComponent,
        
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        LayoutModule,
        IoComponentsModule.forRoot(environment),
        ToastrModule.forRoot(),
        SessionManagementModule.forRoot(EnvironmentService),
        AuthModule.forRoot(EnvironmentService),
        OidcModule.forRoot(),
        MatIconModule,
        MatToolbarModule,
        MatTabsModule,
        MatMenuModule,
        MatAutocompleteModule,
        MessageBarModule
    ],
    exports: [],
    providers: [
        PreInitService,
        {
            provide: APP_INITIALIZER,
            useFactory: preInitServiceFactory,
            deps: [PreInitService, Injector],
            multi: true
        },
        OverviewSharedService,
        HomeSharedService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthV2Interceptor, multi: true },
        Title,
        ThemeService,
        DatePipe,
        //provide OktaService as a singleton to be injected in other projects/library
        {
            provide: 'OktaService',
            useExisting: OktaService
        },
        {
            provide: 'LookerApiService',
            useExisting: LookerApiService
        },
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
