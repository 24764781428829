<div>
    <div class="header" fxLayout="row" fxLayoutAlign="space-around center">
        <div class="filter-item" fxLayout="column" fxLayoutAlign="space-between center" fxFlex="25"
            (click)="changeFilter(downloadItemStatuses.COMPLETED)"
            [ngClass]="{'selected': selectedHeaderFilter == downloadItemStatuses.COMPLETED, 'no-hover': (items | downloadsFilter:downloadItemStatuses.COMPLETED).length == 0}">
            <div class="filter-label">Ready</div>
            <div class="filter-count">{{(items | downloadsFilter:downloadItemStatuses.COMPLETED).length}}</div>
        </div>
        <div class="filter-item" fxLayout="column" fxLayoutAlign="space-between center" fxFlex="25"
            (click)="changeFilter(downloadItemStatuses.INITIALIZED)"
            [ngClass]="{'selected': selectedHeaderFilter == downloadItemStatuses.INITIALIZED, 'no-hover': (items | downloadsFilter:downloadItemStatuses.INITIALIZED).length == 0}">
            <div class="filter-label">Processing</div>
            <div class="filter-count">{{(items | downloadsFilter:downloadItemStatuses.INITIALIZED).length}}</div>
        </div>
        <!-- <div class="filter-item" fxLayout="column" fxLayoutAlign="space-between center" fxFlex="20"
            (click)="changeFilter(downloadItemStatuses.DOWNLOADED)"
            [ngClass]="{'selected': selectedHeaderFilter == downloadItemStatuses.DOWNLOADED, 'no-hover': (items | downloadsFilter:downloadItemStatuses.DOWNLOADED).length == 0}">
            <div class="filter-label">Downloaded</div>
            <div class="filter-count">{{(items | downloadsFilter:downloadItemStatuses.DOWNLOADED).length}}</div>
        </div> -->
        <div class="filter-item" fxLayout="column" fxLayoutAlign="space-between center" fxFlex="25"
            (click)="changeFilter(downloadItemStatuses.FAILED)"
            [ngClass]="{'selected': selectedHeaderFilter == downloadItemStatuses.FAILED, 'no-hover': (items | downloadsFilter:downloadItemStatuses.FAILED).length == 0}">
            <div class="filter-label">Failed</div>
            <div class="filter-count">{{(items | downloadsFilter:downloadItemStatuses.FAILED).length}}</div>
        </div>
        <div class="filter-item" fxLayout="column" fxLayoutAlign="space-between center" fxFlex="25"
            (click)="changeFilter(null)" [ngClass]="{'selected': selectedHeaderFilter == null, 'no-hover': (items | downloadsFilter:null).length == 0}">
            <div class="filter-label">Total</div>
            <div class="filter-count">{{(items | downloadsFilter:null).length}}</div>
        </div>
    </div>
    <div class="body">
        <ng-container *ngIf="isLoading then loading else loaded"></ng-container>

        <ng-template #loading>
            <div fxLayoutAlign="center">Loading...</div>
        </ng-template>
        <ng-template #loaded>
            <ng-container *ngIf="items.length == 0 then empty else list"></ng-container>
            <ng-template #list>
                <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 5px;">
                    <div class="labels" fxFlex="30">File Name</div>
                    <div class="labels" fxFlex="10" fxFlexOffset="5" fxLayout="row" fxLayoutAlign="center center">Status</div>
                    <div class="labels" fxFlex="15" fxFlexOffset="5">Time Remaining</div>
                    <div class="labels" fxFlex="30" fxFlexOffset="5">Comments</div>
                </div>
                <app-download-list-item *ngFor="let item of viewList" [item]="item"></app-download-list-item>
            </ng-template>
            <ng-template #empty>
                <div class="empty-msg">
                    There are no files available for download. Please select the data you would like to receive and
                    initiate the download.
                </div>
            </ng-template>
        </ng-template>

    </div>
</div>