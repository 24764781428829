export class FilterLabels {
    static readonly ALL = "All";
}

export class FilterFields {
    static readonly MEDICAL_GROUP = "medicalGroup";
    static readonly PROVIDER_NAME = "providerName";
    static readonly SERVICE_YEAR = "year";
    static readonly STATE = "state";
    static readonly SERVICE_ID = "serviceId";
    static readonly SERVICE_NAME = "serviceName";
    static readonly PROJECT_ID = "projectId";
    static readonly PROJECT_NAME = "projectName";
    static readonly SITE_NAME = "siteName";
    static readonly PROVIDER_SPECIALITY = "speciality";
    static readonly DATA_IMPORT_DATE = "importDate";
    static readonly MEMBER_NAME = "member";
    static readonly SITE_ID = "siteId";
    static readonly PURSUIT_GROUPED_STATUSII = "pursuitGroupedStatusII";
    static readonly PURSUIT_STATUS_ID = "pursuitStatusId";
    static readonly PURSUIT_STATUS = "pursuitStatus";
    static readonly VENDOR_ID = "vendorId";
    static readonly VENDOR_NAME = "vendorName";
    static readonly ACTIVITY_STATUS = "activityStatus";
}

export class FiltersBinding {
    static readonly bindingInfo = {
        [FilterFields.SERVICE_YEAR]: {
            idField: "yearId",
            label: "Service Year",
        },
        [FilterFields.STATE]: {
            idField: "stateCode",
            label: "State"
        },
        [FilterFields.SERVICE_NAME]: {
            idField: "serviceId",
            label: "Service Name"
        },
        [FilterFields.PROJECT_NAME]: {
            idField: "projectId",
            label: "Project Name"
        },
        [FilterFields.MEDICAL_GROUP]: {
            idField: "medicalGroupId",
            label: "Medical Group(ID)"
        },
        [FilterFields.SITE_NAME]: {
            idField: "siteId",
            label: "Site Name(ID)"
        },
        [FilterFields.PROVIDER_NAME]: {
            idField: "providerId",
            label: "Provider Name(ID)"
        },
        [FilterFields.PROVIDER_SPECIALITY]: {
            idField: "specialityId",
            label: "Provider Specialty"
        },
        [FilterFields.DATA_IMPORT_DATE]: {
            idField: "importDate",
            label: "Data Import Date"
        },
        [FilterFields.MEMBER_NAME]: {
            idField: "memberId",
            label: "Member Name"
        },
        [FilterFields.VENDOR_NAME]: {
            idField: "vendorId",
            label: "Vendor Name"
        },
        [FilterFields.PURSUIT_GROUPED_STATUSII]: {
            idField: "pursuitGroupedStatusIIId",
            label: "Pursuit Grouped Status II"
        },
        [FilterFields.PURSUIT_STATUS]: {
            idField: "pursuitStatusId",
            label: "Pursuit Status"
        },
        [FilterFields.ACTIVITY_STATUS]: {
            idField: "activityStatusId",
            label: "Activity Status"
        }
    }
}