import { HomeConstants } from '../../../shared/constants/home-constants';

export class AgingReportSummaryTilesConstants {
    static readonly Keys = {
        // Charts
        [HomeConstants.CHARTS_TEXT]: {
            TOTAL: "totalCharts",
            IN_PROGRESS: "totalInProgressCharts",
            ISSUED: "totalIssuedCharts",
        },

        // Pursuits
        [HomeConstants.PURSUITS_TEXT]: {
            TOTAL: "total",
            IN_PROGRESS: "totalInProgress",
            ISSUED: "totalIssued",    
        },
    }

    static readonly Labels = {
        // Charts
        [HomeConstants.CHARTS_TEXT]: {
            TOTAL: "Total Charts",
            IN_PROGRESS: "Charts In Progress",
            ISSUED: "Charts Issued",    
        },

        // Pursuits
        [HomeConstants.PURSUITS_TEXT]: {
            TOTAL: "Total Pursuits",
            IN_PROGRESS: "Pursuits In Progress",
            ISSUED: "Pursuits Issued",    
        },
    }
}

export class AgingReportSummaryGridConstants {
    static readonly columnsForPursuitStates = {
        IN_PROGRESS: "pursuitStatusInProgress",
        ISSUED: "pursuitStatusIssued"
    }

    static readonly PURSUIT_STATUS_AGE_IN_WEEKS = "pursuitStatusAgeInWeeks";
}

export class AgingReportGridConstants {
    
    static readonly Fields = {
        VENDOR_ID: "vendorId",
        VENDOR_NAME: "vendorName",
        LESS_THAN_THREE_WEEKS: "lessThanThreeWeeks",
        FOUR_TO_NINE_WEEKS: "fourToNineWeeks",
        TEN_TO_FIFTEEN_WEEKS: "tenToFifteenWeeks",
        ONE_WEEK: "oneWeek",
        TWO_WEEKS: "twoWeeks",
        THREE_WEEKS: "threeWeeks",
        FOUR_TO_SIX_WEEKS: "fourToSixWeeks",
        SEVEN_TO_NINE_WEEKS: "sevenToNineWeeks",
        TEN_TO_TWELVE_WEEKS: "tenToTwelveWeeks",
        THIRTEEN_TO_FIFTEEN_WEEKS: "thirteenToFifteenWeeks",
        SIXTEEN_TO_TWENTY_FIVE_WEEKS: "sixteenToTwentyFiveWeeks",
        GREATER_THAN_TWENTY_FIVE_WEEKS: "greaterThanTwentyFiveWeeks",
        AGING_PROGRESS_NULL: "agingProgressNull",
        AGING_ISSUED_NULL: "agingIssuedNull",
        AGING_PROGRESS_PURSUIT_NULL: "agingProgressPursuitNull",
        AGING_ISSUED_PURSUIT_NULL: "agingIssuedPursuitNull",
        
        TOTAL: "total",
        KEY: "key",
        
        // Charts
        [HomeConstants.CHARTS_TEXT]: {
            GROUPED_STATUS_II: "pursuitGroupedStatusII",
            STATUS: "pursuitStatus",
            TOTAL: "chartsCount"
        },

        // Pursuits
        [HomeConstants.PURSUITS_TEXT]: {
            GROUPED_STATUS_II: "pursuitGroupedStatusII",
            STATUS: "pursuitStatus",
            TOTAL: "pursuitsCount"
        },
    }

    static readonly ColumnValuesForWeeks = {
        "oneWeek": "1 week",
        "twoWeeks": "2 weeks",
        "threeWeeks": "3 weeks",
        "fourToSixWeeks": "4 to weeks",
        "sevenToNineWeeks": "6 to 9  weeks",
        "tenToTwelveWeeks": "10 to 12 weeks",
        "thirteenToFifteenWeeks": "13 to 15 weeks",
        "sixteenToTwentyFiveWeeks": "16 to 20 weeks",
        "greaterThanTwentyFiveWeeks": "greater than 25 weeks"
    }

    static readonly COLUMN_FOR_WEEKS_FILTER = "vendorPursuitAgeInWeeks";
    static readonly COLUMN_VALUE_FOR_GREATER_THAN_25 = "greater than 25 weeks";
    static readonly COLUMN_LABEL_FOR_GREATER_THAN_25 = ">25 weeks";

    static readonly FieldsNamingForExtract = {    
        "vendorName": "vendorName",
        "pursuitGroupedStatusII": "pursuitGroupedStatusII",
        "pursuitStatus": "pursuitStatusName"
    };

    static readonly ObjectColumns = { KEY_COLUMN: "keyColumn", VALUE_COLUMN: "valueColumn" };

    static readonly Headers = {
        VENDOR_NAME: "Vendor Name",
        LESS_THAN_THREE_WEEKS: "Less than 3 weeks",
        FOUR_TO_NINE_WEEKS: "4 to 9 weeks",
        TEN_TO_FIFTEEN_WEEKS: "10 to 15 weeks",
        ONE_WEEK: "1 week",
        TWO_WEEKS: "2 weeks",
        THREE_WEEKS: "3 weeks",
        FOUR_TO_SIX_WEEKS: "4 to 6 weeks",
        SEVEN_TO_NINE_WEEKS: "7 to 9 weeks",
        TEN_TO_TWELVE_WEEKS: "10 to 12 weeks",
        THIRTEEN_TO_FIFTEEN_WEEKS: "13 to 15 Weeks",
        SIXTEEN_TO_TWENTY_FIVE_WEEKS: "16 to 25 weeks",
        GREATER_THAN_TWENTY_FIVE_WEEKS: ">25 weeks",
        TOTAL: "Total",
        
        // Charts
        [HomeConstants.CHARTS_TEXT]: {
            GROUPED_STATUS_II: "Chart Grouped Status II",
            STATUS: "Chart Status",
        },

        // Pursuits
        [HomeConstants.PURSUITS_TEXT]: {
            GROUPED_STATUS_II: "Pursuit Grouped Status II",
            STATUS: "Pursuit Status",
        },
    }
}
