
<!--
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
-->

<div class="svg-defs">
<svg xmlns="http://www.w3.org/2000/svg">
    <symbol id="addresources"
            viewBox="0 0 20 20">
        <path id="Path_780"
              class="st0"
              d="M9.8,14.5l-4,4c-0.3,0.3-0.7,0.5-1.1,0.4c-0.4,0-0.8-0.1-1.1-0.4l-2.2-2.2
c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.4,0.1-0.8,0.4-1.1l6.2-6.2c0.6-0.6,1.6-0.6,2.2,0l2.8,2.8l0.8-0.8l-2.8-2.8c-0.5-0.5-1.2-0.8-1.9-0.8
C8.1,6.3,7.4,6.6,7,7.1l-6.2,6.2c-1,1-1,2.7,0,3.8L3,19.2c1.1,1,2.7,1,3.8,0l4.6-4.6C11.3,14.5,10.5,14.7,9.8,14.5z" />
        <path id="Path_781"
              class="st0"
              d="M10.2,5.6l4-4c0.3-0.3,0.7-0.5,1.1-0.4c0.4,0,0.8,0.1,1.1,0.4l2.2,2.2C18.9,4.2,19,4.6,19,5
c0,0.4-0.2,0.7-0.6,1l-6.2,6.3c-0.6,0.6-1.6,0.6-2.2,0L7.3,9.4l-0.8,0.8l2.8,2.8c0.5,0.5,1.2,0.8,1.9,0.8c0.7,0,1.4-0.3,1.9-0.8
l6.2-6.2C19.7,6.2,20,5.6,20,4.9c0-0.7-0.3-1.4-0.8-1.9L17,0.8c-1.1-1-2.7-1-3.8,0L8.6,5.5C9.1,5.4,9.7,5.4,10.2,5.6z" />
    </symbol>
    <symbol id="calendar"
            viewBox="0 0 24 20">
        <title>Calendar</title>
        <rect class="cls-1"
              x="3.26"
              y="7.11"
              width="3.44"
              height="3.44" />
        <rect class="cls-1"
              x="7.47"
              y="7.11"
              width="3.44"
              height="3.44" />
        <rect class="cls-1"
              x="11.68"
              y="7.11"
              width="3.44"
              height="3.44" />
        <rect class="cls-1"
              x="15.88"
              y="7.11"
              width="3.44"
              height="3.44" />
        <rect class="cls-1"
              x="3.26"
              y="11.32"
              width="3.44"
              height="3.44" />
        <rect class="cls-1"
              x="7.47"
              y="11.32"
              width="3.44"
              height="3.44" />
        <rect class="cls-1"
              x="11.68"
              y="11.32"
              width="3.44"
              height="3.44" />
        <path class="cls-2"
              d="M11.68,14.76h3.44V11.32H11.68Zm1-2.44h1.44v1.44H12.68ZM3.26,10.55H6.71V7.11H3.26Zm1-2.44H5.71V9.55H4.26Zm-1,6.65H6.71V11.32H3.26Zm1-2.44H5.71v1.44H4.26ZM19.33,7.11H15.88v3.44h3.44Zm-1,2.44H16.88V8.11h1.44Zm-6.65,1h3.44V7.11H11.68Zm1-2.44h1.44V9.55H12.68ZM7.47,10.55h3.44V7.11H7.47Zm1-2.44H9.91V9.55H8.47Zm-1,6.65h3.44V11.32H7.47Zm1-2.44H9.91v1.44H8.47ZM22.2,13V1.93H18.11V.42h-1V1.93H5.49V.42h-1V1.93H.42V18H17.23a3.63,3.63,0,1,0,5-5Zm-5.41,4H1.42V2.93H4.49V4.24h1V2.93H17.11V4.24h1V2.93H21.2v9.66a3.59,3.59,0,0,0-.92-.13,3.64,3.64,0,0,0-3.63,3.63A3.59,3.59,0,0,0,16.79,17Zm3.49,1.68a2.63,2.63,0,1,1,2.63-2.63A2.64,2.64,0,0,1,20.28,18.73Zm.6-4.16h-1V15.7H18.76v1h1.12v1.12h1V16.7H22v-1H20.88Z" />
    </symbol>
    <symbol id="caremanagement"
            viewBox="0 0 20 20">
        <title>Artboard 1CareManagement</title>
        <path class="cls-1-caremgmt"
              d="M2.92,4.78l-.57.57.59.59.57-.57Zm1.81-.62-.59-.59-.57.57.59.59ZM5.94,2.94l-.59-.59-.57.57.59.59ZM4,5.85l-.57.57L4,7l.57-.57Zm.65-.65.59.59.57-.57L5.2,4.64Zm1.81-.62L7,4l-.59-.59L5.85,4ZM5.65,7.51l-.59-.59-.57.57.59.59Zm.06-1.24.59.59.57-.57-.59-.59Zm1.81-.62.57-.57-.59-.59-.57.57Zm5,8.7-.57.57.59.59.57-.57Zm1.81-.62-.59-.59-.57.57.59.59Zm1.21-1.22-.59-.59-.57.57.59.59Zm-1.95,2.91L13,16l.59.59.57-.57Zm.65-.65.59.59.57-.57-.59-.59ZM16,14.15l.57-.57L16,13l-.57.57Zm-1.95,2.91.59.59.57-.57-.59-.59Zm1.21-1.21.59.59.57-.57-.59-.59Zm1.22-1.22.59.59.57-.57-.59-.59ZM15.87,10,19.4,6.47a1.85,1.85,0,0,0,0-2.61L16.14.6a1.85,1.85,0,0,0-2.61,0L10,4.13,6.47.6A1.85,1.85,0,0,0,3.86.6L.6,3.86a1.85,1.85,0,0,0,0,2.61L4.13,10,.6,13.53a1.85,1.85,0,0,0,0,2.61L3.86,19.4a1.85,1.85,0,0,0,2.61,0L10,15.87l3.53,3.53a1.85,1.85,0,0,0,2.61,0l3.26-3.26a1.85,1.85,0,0,0,0-2.61ZM1.19,5.88a1,1,0,0,1,0-1.43L4.45,1.19a1,1,0,0,1,1.43,0L9.41,4.72,4.72,9.41ZM5.88,18.81a1,1,0,0,1-1.43,0L1.19,15.55a1,1,0,0,1,0-1.43L14.12,1.19a1,1,0,0,1,1.43,0l3.26,3.26a1,1,0,0,1,0,1.43Zm12.93-3.26-3.26,3.26a1,1,0,0,1-1.43,0l-3.53-3.53,4.69-4.69,3.53,3.53A1,1,0,0,1,18.81,15.55ZM15.22,2.92l-.57-.57-.59.59.57.57Zm1.22,1.21-.57-.57-.59.59.57.57Zm.06,1.24.57.57.59-.59-.57-.57ZM14.15,4l-.57-.57L13,4l.57.57Zm.06,1.24.57.57.59-.59-.57-.57Zm1.22,1.22L16,7l.59-.59L16,5.85Zm-3.5-1.36.57.57.59-.59-.57-.57ZM13.13,6.3l.57.57.59-.59-.57-.57Zm1.22,1.22.57.57.59-.59-.57-.57Zm-8.7,5-.57-.57-.59.59.57.57ZM6.87,13.7l-.57-.57-.59.59.57.57Zm.06,1.24.57.57.59-.59-.57-.57ZM4.58,13.56,4,13l-.59.59.57.57Zm.06,1.24.57.57.59-.59-.57-.57ZM5.85,16l.57.57L7,16l-.57-.57Zm-3.5-1.36.57.57.59-.59-.57-.57Zm1.21,1.21.57.57.59-.59-.57-.57Zm1.22,1.22.57.57.59-.59-.57-.57Z" />
    </symbol>
    <symbol id="close-gaps"
            viewBox="0 0 20 13.6">
        <path class="st0"
              d="M19,0H1C0.5,0,0,0.5,0,1v11.6c0,0.6,0.5,1,1,1h18c0.6,0,1-0.4,1-1V1C20,0.5,19.6,0,19,0z M19,12.6H1V1l18,0
V12.6z M6.7,7.9l2.8-2.8v4.7c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V5.1l2.8,2.8c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1
c0.2-0.2,0.2-0.5,0-0.7l-4-4l-4,4C5.8,7.4,5.8,7.7,6,7.9C6.2,8.1,6.5,8.1,6.7,7.9z" />
    </symbol>
    <symbol id="efficiency"
            viewBox="0 0 21 15">
        <path class="st0"
              d="M20.5,14h-1.3l0-4.4c0-0.6-0.5-1-1.1-1.1l-2.5,0c-0.6,0-1,0.5-1.1,1.1V14h-1.4V2.6c0-0.8-0.6-1.4-1.4-1.4H9.9
c-0.8,0-1.4,0.6-1.4,1.4V14H7.1V6.4C7.1,6,7,5.7,6.7,5.5C6.5,5.3,6.2,5.2,5.9,5.2H3.7c-0.4,0-0.8,0.2-1,0.5C2.5,5.9,2.5,6.2,2.5,6.5
V14H1V0.5C1,0.2,0.8,0,0.5,0S0,0.2,0,0.5V15h20.5c0.3,0,0.5-0.2,0.5-0.5S20.8,14,20.5,14z M3.5,14V6.4l0-0.1c0,0,0.1-0.1,0.2-0.1
h2.2c0.1,0,0.2,0.1,0.2,0.2V14H3.5z M9.5,14V2.6c0-0.2,0.2-0.4,0.4-0.4h1.8c0.2,0,0.4,0.2,0.4,0.4V14H9.5z M15.5,14l0-4.4
c0-0.1,0.1-0.1,0.1-0.1l2.5,0c0.1,0,0.1,0.1,0.1,0.1V14H15.5z" />
    </symbol>
    <symbol id="close_x"
            viewBox="0 0 12 12">
        <path class="st0"
              d="M6.7,6l5.2-5.2c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0L6,5.3L0.8,0.1C0.6,0,0.3,0,0.1,0.1
C0,0.3,0,0.6,0.1,0.8L5.3,6l-5.2,5.2c-0.2,0.2-0.2,0.5,0,0.7C0.2,12,0.3,12,0.5,12c0.1,0,0.2,0,0.3-0.1L6,6.7l5.2,5.2
c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.2-0.5,0-0.7L6.7,6z" />
    </symbol>
    <symbol id="collapsearrow"
            viewBox="0 0 7.7 14">
        <g>
            <path class="st0"
                  d="M7.2,14c-0.1,0-0.2,0-0.3-0.1L0,7l6.8-6.8C7,0,7.3,0,7.5,0.1s0.2,0.5,0,0.7L1.3,7l6.2,6.2
c0.2,0.2,0.2,0.5,0,0.7C7.5,14,7.3,14,7.2,14z" />
        </g>
    </symbol>
    <symbol id="datadiagnostics"
            viewBox="0 0 21 15">
        <path class="st0"
              d="M20.5,14h-1.3l0-4.4c0-0.6-0.5-1-1.1-1.1l-2.5,0c-0.6,0-1,0.5-1.1,1.1V14h-1.4V2.6c0-0.8-0.6-1.4-1.4-1.4H9.9
c-0.8,0-1.4,0.6-1.4,1.4V14H7.1V6.4C7.1,6,7,5.7,6.7,5.5C6.5,5.3,6.2,5.2,5.9,5.2H3.7c-0.4,0-0.8,0.2-1,0.5C2.5,5.9,2.5,6.2,2.5,6.5
V14H1V0.5C1,0.2,0.8,0,0.5,0S0,0.2,0,0.5V15h20.5c0.3,0,0.5-0.2,0.5-0.5S20.8,14,20.5,14z M3.5,14V6.4l0-0.1c0,0,0.1-0.1,0.2-0.1
h2.2c0.1,0,0.2,0.1,0.2,0.2V14H3.5z M9.5,14V2.6c0-0.2,0.2-0.4,0.4-0.4h1.8c0.2,0,0.4,0.2,0.4,0.4V14H9.5z M15.5,14l0-4.4
c0-0.1,0.1-0.1,0.1-0.1l2.5,0c0.1,0,0.1,0.1,0.1,0.1V14H15.5z" />
    </symbol>
    <symbol id="datepicker"
            viewBox="2527.556 2151.491 23.5 19.314">
        <g transform="translate(1429 1880)">
            <rect class="a"
                  width="20.781"
                  height="15.113"
                  transform="translate(1099.056 273.507)" />
            <g class="b"
               transform="translate(1114.789 283.538)">
                <ellipse class="c"
                         cx="3.633"
                         cy="3.633"
                         rx="3.633"
                         ry="3.633" />
                <ellipse class="d"
                         cx="3.633"
                         cy="3.633"
                         rx="3.133"
                         ry="3.133" />
            </g>
            <g transform="translate(1101.403 278.184)">
                <g class="a"
                   transform="translate(0 0)">
                    <rect class="c"
                          width="3.442"
                          height="3.442" />
                    <rect class="d"
                          x="0.5"
                          y="0.5"
                          width="2.442"
                          height="2.442" />
                </g>
                <g class="a"
                   transform="translate(4.207 0)">
                    <rect class="c"
                          width="3.442"
                          height="3.442" />
                    <rect class="d"
                          x="0.5"
                          y="0.5"
                          width="2.442"
                          height="2.442" />
                </g>
                <g class="a"
                   transform="translate(8.414 0)">
                    <rect class="c"
                          width="3.442"
                          height="3.442" />
                    <rect class="d"
                          x="0.5"
                          y="0.5"
                          width="2.442"
                          height="2.442" />
                </g>
                <g class="a"
                   transform="translate(12.621 0)">
                    <rect class="c"
                          width="3.442"
                          height="3.442" />
                    <rect class="d"
                          x="0.5"
                          y="0.5"
                          width="2.442"
                          height="2.442" />
                </g>
                <g class="a"
                   transform="translate(0 4.207)">
                    <rect class="c"
                          width="3.442"
                          height="3.442" />
                    <rect class="d"
                          x="0.5"
                          y="0.5"
                          width="2.442"
                          height="2.442" />
                </g>
                <g class="a"
                   transform="translate(4.207 4.207)">
                    <rect class="c"
                          width="3.442"
                          height="3.442" />
                    <rect class="d"
                          x="0.5"
                          y="0.5"
                          width="2.442"
                          height="2.442" />
                </g>
                <g class="a"
                   transform="translate(8.414 4.207)">
                    <rect class="c"
                          width="3.442"
                          height="3.442" />
                    <rect class="d"
                          x="0.5"
                          y="0.5"
                          width="2.442"
                          height="2.442" />
                </g>
            </g>
            <line class="a"
                  y2="3.825"
                  transform="translate(1103.124 271.491)" />
            <line class="a"
                  y2="3.825"
                  transform="translate(1115.745 271.491)" />
            <line class="a"
                  y2="3.246"
                  transform="translate(1118.521 285.647)" />
            <line class="a"
                  y2="3.246"
                  transform="translate(1120.144 287.27) rotate(90)" />
        </g>
    </symbol>
    <symbol id="dropdown_arrow"
            viewBox="0 0 12 6.9">
        <g>
            <path class="st0"
                  d="M6,6.9L0.2,1.1c-0.2-0.2-0.2-0.7,0-0.9s0.7-0.2,0.9,0L6,5.1l4.9-4.9c0.2-0.2,0.7-0.2,0.9,0
c0.2,0.2,0.2,0.7,0,0.9L6,6.9z" />
        </g>
    </symbol>
    <symbol id="expandarrow"
            viewBox="0 0 7.7 14">
        <g>
            <path class="st0"
                  d="M0.2,13.9c-0.2-0.2-0.2-0.5,0-0.7L6.4,7L0.2,0.8C0,0.6,0,0.2,0.2,0.1S0.7,0,0.9,0.2L7.7,7l-6.9,6.9
C0.7,14,0.6,14,0.5,14C0.4,14,0.2,14,0.2,13.9z" />
        </g>
    </symbol>
    <symbol id="expander_close"
            viewBox="0 0 12 1.1">
        <g id="Line_192">
            <path class="st0"
                  d="M11.4,1.1L11.4,1.1l-10.9,0C0.3,1.1,0,0.9,0,0.6S0.3,0,0.6,0l0,0l10.9,0C11.7,0,12,0.3,12,0.6
S11.7,1.1,11.4,1.1z" />
        </g>
    </symbol>
    <symbol id="expander_open"
            viewBox="0 0 12 12">
        <g id="Group_304"
           transform="translate(2528.5 340)">
            <g id="Line_191">
                <path class="st0"
                      d="M-2522.5-328c-0.3,0-0.6-0.3-0.6-0.6l0-10.9c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6l0,10.9
C-2521.9-328.3-2522.2-328-2522.5-328z" />
            </g>
            <g id="Line_192">
                <path class="st0"
                      d="M-2517.1-333.4L-2517.1-333.4l-10.9,0c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6l0,0l10.9,0
c0.3,0,0.6,0.3,0.6,0.6C-2516.5-333.7-2516.8-333.4-2517.1-333.4z" />
            </g>
        </g>
    </symbol>
    <symbol id="favorites"
            viewBox="0 0 20 18.4">
        <g id="My_Fav_icon">
            <path class="st0"
                  d="M10.2,18.4L10,18.3l0,0c-1.4-0.6-8.7-7.5-9.6-10.2C-0.8,5,0.9,1.5,4.1,0.4c2-0.7,4.2-0.3,5.8,1
c1.1-1,2.5-1.5,4-1.5c0,0,0,0,0,0c1.6,0,3.1,0.6,4.3,1.8c1.6,1.6,2.2,4,1.5,6.2c-0.9,2.9-8,9.3-9.2,10.2l-0.1,0.1L10.2,18.4z
M6.1,1.1C5.5,1.1,5,1.2,4.4,1.3C1.7,2.3,0.3,5.2,1.2,7.9l0,0c0.8,2.4,7.4,8.4,8.8,9.4c1.3-1,7.9-7.1,8.8-9.6
c0.6-1.9,0.1-3.9-1.3-5.2c-1-1-2.2-1.5-3.6-1.5c0,0,0,0,0,0c-1.4,0-2.7,0.5-3.6,1.5L9.9,2.8L9.6,2.5C8.6,1.6,7.4,1.1,6.1,1.1z" />
        </g>
    </symbol>
    <symbol id="indices"
            viewBox="16.375 915.375 17.45 17.75">
        <g id="Group_218"
           data-name="Group 218"
           transform="translate(1383 858)">
            <path id="Path_728"
                  data-name="Path 728"
                  class="cls-1"
                  d="M15.6,4.5H1.8A1.155,1.155,0,0,1,.6,3.3V1.8A1.216,1.216,0,0,1,1.8.6H15.6a1.216,1.216,0,0,1,1.2,1.2V3.4A1.278,1.278,0,0,1,15.6,4.5Z"
                  transform="translate(-1366.6 57.4)" />
            <path id="Path_729"
                  data-name="Path 729"
                  class="cls-1"
                  d="M13.1,10.8H1.7A1.11,1.11,0,0,1,.6,9.7V8A1.11,1.11,0,0,1,1.7,6.9H13.1A1.11,1.11,0,0,1,14.2,8V9.7A1.11,1.11,0,0,1,13.1,10.8Z"
                  transform="translate(-1366.6 57.4)" />
            <path id="Path_730"
                  data-name="Path 730"
                  class="cls-1"
                  d="M13.1,17.1H1.7A1.11,1.11,0,0,1,.6,16V14.3a1.11,1.11,0,0,1,1.1-1.1H13.1a1.11,1.11,0,0,1,1.1,1.1V16A1.11,1.11,0,0,1,13.1,17.1Z"
                  transform="translate(-1366.6 57.4)" />
        </g>
    </symbol>
    <symbol id="inovalon_icon"
            viewBox="-206 1598 20 16.357">
        <path id="Path_1986"
              data-name="Path 1986"
              class="cls-1"
              d="M11.643,21.471a6.536,6.536,0,0,1-1.143.071l.286-3a5.514,5.514,0,0,0,1.071-.143Z"
              transform="translate(-209 1592.743)" />
        <path id="Path_1987"
              data-name="Path 1987"
              class="cls-1"
              d="M14.357,20.929c-.357.071-.714.214-1.071.286L13,18.286A3.536,3.536,0,0,0,13.929,18Z"
              transform="translate(-209.714 1592.857)" />
        <path id="Path_1988"
              data-name="Path 1988"
              class="cls-1"
              d="M17.129,19.886c-.357.143-.643.286-1,.429L15.2,17.529c.286-.143.643-.286.929-.429Z"
              transform="translate(-210.343 1593.114)" />
        <path id="Path_1989"
              data-name="Path 1989"
              class="cls-1"
              d="M19.771,18.429a6.521,6.521,0,0,1-.929.571L17.2,16.571A5.958,5.958,0,0,0,18.057,16Z"
              transform="translate(-210.914 1593.429)" />
        <path id="Path_1990"
              data-name="Path 1990"
              class="cls-1"
              d="M22.1,16.629c-.286.214-.5.5-.786.714l-2.214-2c.286-.214.5-.429.786-.643Z"
              transform="translate(-211.457 1593.8)" />
        <path id="Path_1991"
              data-name="Path 1991"
              class="cls-1"
              d="M23.957,14.529c-.214.286-.429.5-.643.786l-2.714-1.5c.214-.214.429-.5.643-.714Z"
              transform="translate(-211.886 1594.257)" />
        <path id="Path_1992"
              data-name="Path 1992"
              class="cls-1"
              d="M25.429,12.386a7.791,7.791,0,0,1-.429.786l-3-.857a3.783,3.783,0,0,0,.429-.714Z"
              transform="translate(-212.286 1594.686)" />
        <path id="Path_1993"
              data-name="Path 1993"
              class="cls-1"
              d="M26.257,10.229c-.071.214-.143.5-.214.714L22.9,10.514a7.411,7.411,0,0,0,.286-.714Z"
              transform="translate(-212.543 1595.2)" />
        <path id="Path_1994"
              data-name="Path 1994"
              class="cls-1"
              d="M26.614,8.557v.357L23.4,8.986c0-.071.071-.214.071-.286a.644.644,0,0,1,.071-.357L26.686,8.2a.644.644,0,0,0-.071.357"
              transform="translate(-212.686 1595.657)" />
        <path id="Path_1995"
              data-name="Path 1995"
              class="cls-1"
              d="M26.471,6.2a1.392,1.392,0,0,1,.071.571l-3.071.5A1.573,1.573,0,0,0,23.4,6.7Z"
              transform="translate(-212.686 1596.229)" />
        <path id="Path_1996"
              data-name="Path 1996"
              class="cls-1"
              d="M25.886,4.4a3.482,3.482,0,0,1,.214.5l-2.857.857a1.324,1.324,0,0,1-.143-.5Z"
              transform="translate(-212.6 1596.743)" />
        <path id="Path_1997"
              data-name="Path 1997"
              class="cls-1"
              d="M25,3c.143.143.214.286.357.357L22.786,4.5c-.071-.143-.214-.286-.286-.429Z"
              transform="translate(-212.429 1597.143)" />
        <path id="Path_1998"
              data-name="Path 1998"
              class="cls-1"
              d="M23.714,1.8a.713.713,0,0,1,.357.286L21.857,3.443c-.143-.071-.214-.214-.357-.286Z"
              transform="translate(-212.143 1597.486)" />
        <path id="Path_1999"
              data-name="Path 1999"
              class="cls-1"
              d="M22.229.9l.429.214-1.929,1.5L20.3,2.4Z"
              transform="translate(-211.8 1597.743)" />
        <path id="Path_2000"
              data-name="Path 2000"
              class="cls-1"
              d="M20.571.4c.143.071.286.071.429.143l-1.571,1.5c-.143-.071-.286-.071-.429-.143Z"
              transform="translate(-211.429 1597.886)" />
        <path id="Path_2001"
              data-name="Path 2001"
              class="cls-1"
              d="M18.914.1a.906.906,0,0,1,.429.071L18.129,1.743c-.143,0-.286-.071-.429-.071Z"
              transform="translate(-211.057 1597.971)" />
        <path id="Path_2002"
              data-name="Path 2002"
              class="cls-1"
              d="M17.057,0h.429l-.857,1.5H16.2Z"
              transform="translate(-210.629 1598)" />
        <path id="Path_2003"
              data-name="Path 2003"
              class="cls-1"
              d="M15.3.2h.357l-.5,1.5H14.8Z"
              transform="translate(-210.229 1597.943)" />
        <path id="Path_2004"
              data-name="Path 2004"
              class="cls-1"
              d="M13.414.571A3.094,3.094,0,0,0,13.771.5l-.143,1.429c-.143,0-.286.071-.429.071Z"
              transform="translate(-209.771 1597.857)" />
        <path id="Path_2005"
              data-name="Path 2005"
              class="cls-1"
              d="M11.7,1.143c.071-.071.214-.071.286-.143l.143,1.286c-.143.071-.214.143-.357.143Z"
              transform="translate(-209.343 1597.714)" />
        <path id="Path_2006"
              data-name="Path 2006"
              class="cls-1"
              d="M9.8,1.943c.071-.071.214-.071.286-.143l.429,1.143c-.143.071-.214.071-.357.143Z"
              transform="translate(-208.8 1597.486)" />
        <path id="Path_2007"
              data-name="Path 2007"
              class="cls-1"
              d="M8,2.943A.542.542,0,0,1,8.286,2.8L9,3.8a.371.371,0,0,0-.286.143Z"
              transform="translate(-208.286 1597.2)" />
        <path id="Path_2008"
              data-name="Path 2008"
              class="cls-1"
              d="M6.3,4.014A.986.986,0,0,0,6.586,3.8l1,.857a.986.986,0,0,0-.286.214Z"
              transform="translate(-207.8 1596.914)" />
        <path id="Path_2009"
              data-name="Path 2009"
              class="cls-1"
              d="M4.8,5.314,5.014,5.1l1.214.643-.214.214Z"
              transform="translate(-207.371 1596.543)" />
        <path id="Path_2010"
              data-name="Path 2010"
              class="cls-1"
              d="M3.4,6.714,3.614,6.5l1.5.429c-.071.071-.214.143-.286.214Z"
              transform="translate(-206.971 1596.143)" />
        <path id="Path_2011"
              data-name="Path 2011"
              class="cls-1"
              d="M2.2,8.286A.986.986,0,0,1,2.414,8l1.714.214c-.143.071-.143.143-.214.214Z"
              transform="translate(-206.629 1595.714)" />
        <path id="Path_2012"
              data-name="Path 2012"
              class="cls-1"
              d="M1.2,9.886c.071-.071.071-.143.143-.214L3.271,9.6c-.071.071-.071.143-.143.214Z"
              transform="translate(-206.343 1595.257)" />
        <path id="Path_2013"
              data-name="Path 2013"
              class="cls-1"
              d="M.5,11.471c0-.071.071-.143.071-.214L2.643,10.9c0,.071-.071.143-.071.286Z"
              transform="translate(-206.143 1594.886)" />
        <path id="Path_2014"
              data-name="Path 2014"
              class="cls-1"
              d="M.1,13.1a.54.54,0,0,1,.071-.286L2.386,12.1v.286Z"
              transform="translate(-206.029 1594.543)" />
        <path id="Path_2015"
              data-name="Path 2015"
              class="cls-1"
              d="M0,14.686v-.214L2.357,13.4v.214Z"
              transform="translate(-206 1594.171)" />
        <path id="Path_2016"
              data-name="Path 2016"
              class="cls-1"
              d="M.271,16.343c0-.071-.071-.143-.071-.214L2.557,14.7c0,.071.071.143.071.214Z"
              transform="translate(-206.057 1593.8)" />
        <path id="Path_2017"
              data-name="Path 2017"
              class="cls-1"
              d="M.971,17.829c0-.071-.071-.071-.071-.143L3.186,15.9c0,.071.071.071.071.143Z"
              transform="translate(-206.257 1593.457)" />
        <path id="Path_2018"
              data-name="Path 2018"
              class="cls-1"
              d="M2.143,19.214c-.071,0-.071-.071-.143-.071L4.143,17c.071.071.071.071.143.071Z"
              transform="translate(-206.571 1593.143)" />
        <path id="Path_2019"
              data-name="Path 2019"
              class="cls-1"
              d="M3.771,20.371,3.7,20.3l1.857-2.5.071.071Z"
              transform="translate(-207.057 1592.914)" />
        <path id="Path_2020"
              data-name="Path 2020"
              class="cls-1"
              d="M5.771,21.157,5.7,21.086,7.129,18.3l.071.071Z"
              transform="translate(-207.629 1592.771)" />
        <path id="Path_2021"
              data-name="Path 2021"
              class="cls-1"
              d="M7.929,21.6c-.143,0-.286-.071-.429-.071l1-2.929a.906.906,0,0,1,.429.071h.714l-1,3a2.2,2.2,0,0,0-.714-.071"
              transform="translate(-208.143 1592.686)" />
        <path id="Path_2022"
              data-name="Path 2022"
              class="cls-1"
              d="M6.4,17.086a2.139,2.139,0,0,1,.571.286l1.143-1.857c-.214-.071-.357-.143-.571-.214Z"
              transform="translate(-207.829 1593.629)" />
        <path id="Path_2023"
              data-name="Path 2023"
              class="cls-1"
              d="M5.2,16.371c.143.071.286.214.429.286l1.429-1.643L6.629,14.8Z"
              transform="translate(-207.486 1593.771)" />
        <path id="Path_2024"
              data-name="Path 2024"
              class="cls-1"
              d="M4.2,15.586c.071.143.214.214.286.357l1.643-1.357c-.143-.071-.214-.214-.286-.286Z"
              transform="translate(-207.2 1593.914)" />
        <path id="Path_2025"
              data-name="Path 2025"
              class="cls-1"
              d="M3.6,14.543l.214.429,1.643-1.143a1.015,1.015,0,0,1-.143-.429Z"
              transform="translate(-207.029 1594.171)" />
        <path id="Path_2026"
              data-name="Path 2026"
              class="cls-1"
              d="M3.2,13.357c0,.143.071.286.071.429l1.786-.857a.906.906,0,0,1-.071-.429Z"
              transform="translate(-206.914 1594.429)" />
        <path id="Path_2027"
              data-name="Path 2027"
              class="cls-1"
              d="M3.2,12.143v.429l1.714-.643V11.5Z"
              transform="translate(-206.914 1594.714)" />
        <path id="Path_2028"
              data-name="Path 2028"
              class="cls-1"
              d="M3.443,10.957c-.071.143-.071.286-.143.429l1.643-.357c0-.143.071-.286.071-.429Z"
              transform="translate(-206.943 1594.971)" />
        <path id="Path_2029"
              data-name="Path 2029"
              class="cls-1"
              d="M3.843,9.743a1.015,1.015,0,0,0-.143.429l1.571-.143A1.015,1.015,0,0,1,5.414,9.6Z"
              transform="translate(-207.057 1595.257)" />
        <path id="Path_2030"
              data-name="Path 2030"
              class="cls-1"
              d="M4.371,8.714c0,.071-.071.143-.071.214L5.729,9c0-.071.071-.143.071-.214.071-.071.071-.143.143-.214L4.514,8.5c-.071.071-.071.143-.143.214"
              transform="translate(-207.229 1595.572)" />
        <path id="Path_2031"
              data-name="Path 2031"
              class="cls-1"
              d="M5.386,7.3c-.071.071-.143.214-.286.357l1.286.286a.713.713,0,0,1,.286-.357Z"
              transform="translate(-207.457 1595.914)" />
        <path id="Path_2032"
              data-name="Path 2032"
              class="cls-1"
              d="M6.386,6.2c-.071.071-.143.214-.286.286l1.071.5c.071-.143.214-.214.286-.357Z"
              transform="translate(-207.743 1596.229)" />
        <path id="Path_2033"
              data-name="Path 2033"
              class="cls-1"
              d="M7.557,5.2c-.071.071-.214.143-.357.286l.857.643a1.494,1.494,0,0,1,.429-.286Z"
              transform="translate(-208.057 1596.514)" />
        <path id="Path_2034"
              data-name="Path 2034"
              class="cls-1"
              d="M8.857,4.3c-.143.071-.214.143-.357.214l.643.786c.143-.071.214-.143.357-.214Z"
              transform="translate(-208.429 1596.771)" />
        <path id="Path_2035"
              data-name="Path 2035"
              class="cls-1"
              d="M10.257,3.6a.546.546,0,0,0-.357.143l.429.929a1.561,1.561,0,0,1,.357-.214Z"
              transform="translate(-208.829 1596.971)" />
        <path id="Path_2036"
              data-name="Path 2036"
              class="cls-1"
              d="M11.657,2.9c-.143.071-.214.071-.357.143l.214,1c.143-.071.286-.071.357-.143Z"
              transform="translate(-209.229 1597.172)" />
        <path id="Path_2037"
              data-name="Path 2037"
              class="cls-1"
              d="M13.129,2.5c-.143,0-.214.071-.357.071L12.7,3.643c.143,0,.214-.071.357-.071Z"
              transform="translate(-209.629 1597.286)" />
        <path id="Path_2038"
              data-name="Path 2038"
              class="cls-1"
              d="M14.543,2.2a.644.644,0,0,0-.357.071L13.9,3.414a.644.644,0,0,0,.357-.071Z"
              transform="translate(-209.971 1597.371)" />
        <path id="Path_2039"
              data-name="Path 2039"
              class="cls-1"
              d="M15.929,2.1h-.357L15,3.314h.357Z"
              transform="translate(-210.286 1597.4)" />
        <path id="Path_2040"
              data-name="Path 2040"
              class="cls-1"
              d="M17.371,2.1h-.286L16.3,3.314a.429.429,0,0,1,.286.071Z"
              transform="translate(-210.657 1597.4)" />
        <path id="Path_2041"
              data-name="Path 2041"
              class="cls-1"
              d="M18.586,2.371c-.071,0-.143-.071-.214-.071L17.3,3.514c.071,0,.143.071.214.071Z"
              transform="translate(-210.943 1597.343)" />
        <path id="Path_2042"
              data-name="Path 2042"
              class="cls-1"
              d="M19.771,2.871c-.071,0-.143-.071-.214-.071L18.2,3.943c.071,0,.143.071.214.071Z"
              transform="translate(-211.2 1597.2)" />
        <path id="Path_2043"
              data-name="Path 2043"
              class="cls-1"
              d="M20.914,3.543c-.071-.071-.143-.071-.143-.143L19.2,4.471c0,.071.071.071.143.143Z"
              transform="translate(-211.486 1597.029)" />
        <path id="Path_2044"
              data-name="Path 2044"
              class="cls-1"
              d="M21.729,4.443c-.071-.071-.071-.143-.143-.143L19.8,5.229c.071.071.071.143.143.143Z"
              transform="translate(-211.657 1596.771)" />
        <path id="Path_2045"
              data-name="Path 2045"
              class="cls-1"
              d="M22.371,5.514c0-.071-.071-.143-.071-.214l-2,.714c0,.071.071.143.071.214Z"
              transform="translate(-211.8 1596.486)" />
        <path id="Path_2046"
              data-name="Path 2046"
              class="cls-1"
              d="M22.743,6.914a.262.262,0,0,0-.071-.214L20.6,7.2v.214Z"
              transform="translate(-211.886 1596.086)" />
        <path id="Path_2047"
              data-name="Path 2047"
              class="cls-1"
              d="M22.743,8.286V8L20.6,8.214V8.5Z"
              transform="translate(-211.886 1595.714)" />
        <path id="Path_2048"
              data-name="Path 2048"
              class="cls-1"
              d="M22.443,9.857c0-.071.071-.143.071-.214L20.371,9.5c0,.071-.071.143-.071.214Z"
              transform="translate(-211.8 1595.286)" />
        <path id="Path_2049"
              data-name="Path 2049"
              class="cls-1"
              d="M21.771,11.343c.071-.071.071-.143.143-.214L19.843,10.7c-.071.071-.071.143-.143.214Z"
              transform="translate(-211.629 1594.943)" />
        <path id="Path_2050"
              data-name="Path 2050"
              class="cls-1"
              d="M20.757,12.8c.071-.071.143-.143.143-.214L19.043,11.8c-.071.071-.143.143-.143.214Z"
              transform="translate(-211.4 1594.629)" />
        <path id="Path_2051"
              data-name="Path 2051"
              class="cls-1"
              d="M19.471,14.186c.071-.071.143-.143.214-.143L18.114,12.9c-.071.071-.143.071-.214.143Z"
              transform="translate(-211.114 1594.314)" />
        <path id="Path_2052"
              data-name="Path 2052"
              class="cls-1"
              d="M17.914,15.471c.071-.071.143-.071.214-.143L16.914,13.9c-.071.071-.143.071-.214.143Z"
              transform="translate(-210.771 1594.029)" />
        <path id="Path_2053"
              data-name="Path 2053"
              class="cls-1"
              d="M16.186,16.486a.249.249,0,0,0,.143-.071L15.543,14.7a.249.249,0,0,0-.143.071Z"
              transform="translate(-210.4 1593.8)" />
        <path id="Path_2054"
              data-name="Path 2054"
              class="cls-1"
              d="M14.257,17.229a.249.249,0,0,0,.143-.071L14.043,15.3a.249.249,0,0,0-.143.071Z"
              transform="translate(-209.971 1593.629)" />
        <path id="Path_2055"
              data-name="Path 2055"
              class="cls-1"
              d="M12.4,17.7h.143l.143-2h-.143Z"
              transform="translate(-209.543 1593.514)" />
        <path id="Path_2056"
              data-name="Path 2056"
              class="cls-1"
              d="M10.5,17.9h.143l.571-2h-.143Z"
              transform="translate(-209 1593.457)" />
        <path id="Path_2057"
              data-name="Path 2057"
              class="cls-1"
              d="M8.586,17.8l.857-1.929H9.157c-.143,0-.286-.071-.429-.071L7.8,17.729c.143-.071.714.071.786.071"
              transform="translate(-208.229 1593.486)" />
    </symbol>
    <symbol id="medical"
            viewBox="0 0 20 19.5">
        <path class="st0"
              d="M19,19.5H1c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1h18c0.6,0,1,0.4,1,1v17.5C20,19.1,19.6,19.5,19,19.5z M1,1
l0,17.5h18V1L1,1L1,1z" />
        <path class="st0"
              d="M11.2,15.8l-2.9,0c-0.6,0-1-0.4-1-1v-2.6H4.7c-0.6,0-1-0.4-1-1V8.3c0-0.6,0.4-1,1-1h2.6V4.7c0-0.6,0.4-1,1-1
h2.9c0.6,0,1,0.4,1,1v2.6h2.6c0.6,0,1,0.4,1,1v2.9c0,0.6-0.4,1-1,1h-2.6v2.6C12.2,15.4,11.7,15.8,11.2,15.8z M4.7,8.3v2.9h3.6v3.6
l2.9,0v-3.6h3.6V8.3h-3.6V4.7H8.3v3.6H4.7z" />
    </symbol>
    <symbol id="member"
            viewBox="0 0 20 13.6">
        <g>
            <path class="st0"
                  d="M19,13.6H1c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1h18c0.6,0,1,0.4,1,1v11.6C20,13.2,19.6,13.6,19,13.6z M1,1v11.6
h18V1L1,1z M1,1C1,1,1,1,1,1L1,1z" />
        </g>
        <g id="Rectangle_165">
            <rect x="2.7"
                  y="2.7"
                  class="st0"
                  width="5.5"
                  height="8.2" />
        </g>
        <g id="Line_148">
            <rect x="9.5"
                  y="2.7"
                  class="st0"
                  width="7.8"
                  height="1" />
        </g>
        <g id="Line_149">
            <rect x="9.5"
                  y="6.3"
                  class="st0"
                  width="7.8"
                  height="1" />
        </g>
        <g id="Line_150">
            <rect x="9.5"
                  y="10"
                  class="st0"
                  width="5.5"
                  height="1" />
        </g>
    </symbol>
    <symbol id="nextarrow"
            viewBox="0 0 7.7 14">
        <g>
            <path class="st0"
                  d="M0.2,13.9c-0.2-0.2-0.2-0.5,0-0.7L6.4,7L0.2,0.8C0,0.6,0,0.2,0.2,0.1S0.7,0,0.9,0.2L7.7,7l-6.9,6.9
C0.7,14,0.6,14,0.5,14S0.2,14,0.2,13.9z" />
        </g>
    </symbol>
    <symbol id="outreach"
            viewBox="0 0 20 16.5">
        <path class="st0"
              d="M20,6.5l-6.2-6.2l-0.1-0.1l0,0l0,0L10.8,3l0.1,0.1L9.2,3.5l0,0l0,0L9.1,3.4c0,0-0.1-0.1-0.1-0.1L9.2,3L6.3,0.1
L0,6.5l2.9,2.9l0.3-0.3c0,0,0.1,0.1,0.1,0.1l0.5,0.5L3.6,10c-0.3,0.4-0.3,1,0.1,1.3l0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.3
c0,0.2,0.1,0.4,0.3,0.5l0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.3c0,0.2,0.1,0.4,0.3,0.5l0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.3
c0,0.2,0.1,0.4,0.3,0.5l0.4,0.4c0.2,0.2,0.4,0.3,0.7,0.3h0c0,0,0,0,0,0h0c0.2,0,0.4-0.1,0.6-0.2l0.2-0.2l0,0c0,0,0,0,0,0l1.1,1
c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l0.5-0.5c0.1-0.1,0.2-0.3,0.3-0.5c0.2,0,0.4-0.1,0.5-0.3l0.5-0.5
c0.2-0.2,0.3-0.4,0.3-0.6c0.2,0,0.4-0.1,0.6-0.3l0.5-0.5c0.1-0.1,0.2-0.3,0.3-0.5c0.2,0,0.4-0.1,0.5-0.3l0.5-0.5
c0.4-0.4,0.4-1,0-1.4l-0.4-0.4l1-1c0,0,0.1-0.1,0.1-0.1l0.3,0.3l0,0l0,0L20,6.5z M8,3L2.9,8.2L1.2,6.5l5.2-5.2L8,3z M14.2,12.6
l-0.5,0.5c-0.1,0.1-0.2,0.1-0.2,0l-0.6-0.6L12.3,13l0.6,0.6c0.1,0.1,0.1,0.2,0,0.2l-0.5,0.5c-0.1,0.1-0.2,0.1-0.2,0l-0.6-0.6
L11,14.3l0.6,0.6c0.1,0.1,0.1,0.2,0,0.2l-0.5,0.5c0,0-0.1,0-0.1,0h0c0,0-0.1,0-0.1-0.1l-1.1-1l0.1-0.1c0,0,0,0,0.1,0l0.5-0.5
c0.3-0.4,0.3-0.9-0.1-1.3L10,12.3c-0.1-0.1-0.3-0.2-0.5-0.3c0-0.2-0.1-0.4-0.3-0.5l-0.4-0.4c-0.1-0.1-0.2-0.1-0.3-0.2
c-0.1-0.1-0.2-0.1-0.3-0.1c0-0.2-0.1-0.4-0.3-0.5L7.5,9.8C7.4,9.8,7.3,9.7,7.3,9.6C7.2,9.6,7.1,9.6,7,9.5c0-0.2-0.1-0.4-0.3-0.5
L6.3,8.6C6.2,8.5,6.1,8.5,6,8.4C5.7,8.2,5.3,8.3,5,8.5L4.4,9.1l0,0l0,0L3.8,8.6c-0.1-0.1-0.1-0.2,0-0.2l4.5-4.5c0,0,0,0,0.1,0
c0,0,0.1,0,0.1,0.1C8.4,4.1,8.3,4.2,8.2,4.3L7,7.1C6.8,7.7,7,8.5,7.6,8.8l1.3,0.8c0.1,0.1,0.2,0.1,0.4,0.1c0.4,0.1,0.7-0.1,0.9-0.4
l0.3-0.5c0.1-0.2,0.2-0.5,0.1-0.7c-0.1-0.2-0.2-0.4-0.4-0.6L10,7.3l0.4-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.3,0.1,0.4,0.2l4.3,4.3
c0.1,0.1,0.1,0.2,0,0.2l-0.5,0.5c-0.1,0.1-0.2,0.1-0.2,0l-0.6-0.6l-0.6,0.6l0.6,0.6C14.3,12.4,14.3,12.5,14.2,12.6z M11.8,6.2
c-0.3-0.3-0.6-0.4-1-0.4c-0.2,0-0.4,0-0.5,0.1C10.1,6,10,6.1,9.8,6.3L8.7,7.3l1.1,0.8l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1L9.5,8.9
c0,0,0,0-0.1,0.1c0,0-0.1,0-0.1,0L8,8.1C7.8,8,7.7,7.7,7.8,7.4L9,4.7c0.1-0.2,0.2-0.3,0.4-0.3l2.2-0.5l4.5,4.5c0,0,0,0.1,0,0.1v0
c0,0,0,0.1,0,0.1l-1,1L11.8,6.2z M7.2,13.6l-0.4-0.4c-0.1-0.1-0.1-0.1-0.1-0.2L8,11.6c0.1,0,0.1,0,0.2,0.1l0.4,0.4
c0.1,0.1,0.1,0.1,0.1,0.1l-1.4,1.4C7.2,13.6,7.2,13.6,7.2,13.6z M6,12.4c0,0-0.1,0-0.1-0.1l-0.4-0.4c0,0-0.1-0.1-0.1-0.1l1.2-1.2
l0.2-0.2c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1l0.5,0.5c0,0,0.1,0.1,0.1,0.1l-0.1,0.1L6,12.4z M4.7,11.1l-0.4-0.4c-0.1-0.1-0.1-0.1-0.1-0.1
c0,0,0,0,0,0l1.4-1.4c0,0,0.1,0,0.1,0.1l0.4,0.4c0.1,0.1,0.1,0.1,0.1,0.1l-1.4,1.4C4.8,11.2,4.7,11.1,4.7,11.1z M8.4,14.8l-0.4-0.4
c-0.1-0.1-0.1-0.1-0.1-0.1h0L9.1,13l0.2-0.2c0,0,0.1,0,0.1,0.1l0.4,0.4c0.1,0.1,0.1,0.1,0.1,0.1l-1.4,1.4
C8.4,14.8,8.4,14.8,8.4,14.8z M12,3L12,3l1.7-1.7l5.1,5.1l-1.7,1.7L12,3z" />
    </symbol>
    <symbol id="pharmacy"
            viewBox="0 0 12.2 20">
        <path class="st0"
              d="M11.2,0H1C0.4,0,0,0.4,0,1v3.3c0,0.6,0.4,1,1,1h0.2V19c0,0.5,0.4,1,1,1H10c0.6,0,1-0.4,1-1V5.3h0.2
c0.6,0,1-0.4,1-1V1C12.2,0.4,11.8,0,11.2,0z M10,19H2.2l0-2h3.5V7.6H2.2l0-2.3H10V19z M11.2,4.3H1V1h10.2V4.3z" />
    </symbol>
    <symbol id="poweredbyinovalon"
            viewBox="0 0 1003 146">
        <path class="st0"
              d="M369,129c-2.9,0.4-5.8,0.6-8.6,0.7l2.4-22.1c2.7-0.1,5.3-0.4,8-0.9L369,129z" />
        <path class="st0"
              d="M383.7,125.9c-2.6,0.7-5.1,1.4-7.7,1.9l-1.9-21.8c2.4-0.5,4.7-1.2,7.1-1.9L383.7,125.9z" />
        <path class="st0"
              d="M399.7,119.8c-2.5,1.2-5,2.2-7.5,3.2l-7-20.3c2.3-0.9,4.7-1.9,6.9-3L399.7,119.8z" />
        <path class="st0"
              d="M414.7,111.3c-2.2,1.5-4.6,3-7,4.3l-12-17.9c2.2-1.2,4.3-2.6,6.4-4L414.7,111.3z" />
        <path class="st0"
              d="M427.8,100.7c-1.9,1.8-3.9,3.5-6,5.2l-16.3-14.7c2-1.5,3.8-3.1,5.6-4.7L427.8,100.7z" />
        <path class="st0"
              d="M438.5,88.9c-1.5,2-3,3.9-4.7,5.7L414,83.8c1.6-1.7,3.2-3.4,4.6-5.2L438.5,88.9z" />
        <path class="st0"
              d="M446.2,76.4c-1,2-2.1,4-3.3,5.9l-22.2-6.9c1.2-1.8,2.4-3.6,3.4-5.5L446.2,76.4z" />
        <path class="st0"
              d="M450.8,64.3c-0.4,1.8-1,3.6-1.6,5.3l-23.4-2.9c0.7-1.7,1.4-3.3,1.9-5L450.8,64.3z" />
        <path class="st0"
              d="M452.2,55.1c0,0.8-0.1,1.6-0.2,2.4l-23.5,0.7c0.2-0.8,0.3-1.5,0.4-2.3c0.1-0.8,0.2-1.7,0.3-2.5l23.1-0.9
C452.3,53.4,452.3,54.2,452.2,55.1" />
        <path class="st0"
              d="M451.2,42c0.3,1.3,0.6,2.7,0.8,4.1L429.3,50c0-1.4-0.2-2.7-0.4-4L451.2,42z" />
        <path class="st0"
              d="M447.5,32.7c0.6,1.1,1.2,2.3,1.7,3.5l-21.1,6.5c-0.4-1.2-0.8-2.4-1.3-3.5L447.5,32.7z" />
        <path class="st0"
              d="M441.9,24.9c0.9,0.9,1.7,1.9,2.4,2.9l-19,8.5c-0.6-1-1.3-2-2.1-3L441.9,24.9z" />
        <path class="st0"
              d="M434.8,18.8c0.9,0.6,1.8,1.3,2.7,2l-16.6,9.9c-0.8-0.8-1.6-1.5-2.5-2.2L434.8,18.8z" />
        <path class="st0"
              d="M426.3,14.3c1.1,0.5,2.1,0.9,3.1,1.4l-14,10.8c-0.9-0.6-1.9-1.1-2.9-1.6L426.3,14.3z" />
        <path class="st0"
              d="M417,11.2c1.2,0.3,2.3,0.6,3.4,0.9L409,23.4c-1.1-0.4-2.2-0.7-3.3-1.1L417,11.2z" />
        <path class="st0"
              d="M407.3,9.6c1.1,0.1,2.1,0.2,3.2,0.4l-8.7,11.4c-1-0.2-2.1-0.4-3.2-0.5L407.3,9.6z" />
        <path class="st0"
              d="M397,9.4c1.1,0,2.1-0.1,3.2-0.1l-6.1,11.3c-1.1,0-2.2-0.1-3.3,0L397,9.4z" />
        <path class="st0"
              d="M386.9,10.4c0.6-0.1,1.2-0.2,1.8-0.3c0.3,0,0.7-0.1,1.1-0.1L386,20.9c-0.4,0-0.7,0.1-1.1,0.1
c-0.6,0.1-1.2,0.2-1.8,0.2L386.9,10.4z" />
        <path class="st0"
              d="M376.5,12.5c0.9-0.2,1.9-0.5,2.8-0.7L378,22.1c-1,0.2-2,0.4-2.9,0.7L376.5,12.5z" />
        <path class="st0"
              d="M366.6,15.6c0.8-0.3,1.6-0.6,2.3-0.8l0.9,9.5c-0.8,0.3-1.7,0.6-2.5,0.9L366.6,15.6z" />
        <path class="st0"
              d="M356.7,19.8c0.8-0.4,1.5-0.7,2.3-1.1l3.1,8.6c-0.8,0.4-1.6,0.7-2.4,1.1L356.7,19.8z" />
        <path class="st0"
              d="M347.3,25c0.7-0.4,1.4-0.9,2.2-1.3l5.2,7.5c-0.8,0.4-1.5,0.9-2.3,1.4L347.3,25z" />
        <path class="st0"
              d="M338.5,30.9c0.7-0.5,1.3-1,2-1.5l7.1,6.2c-0.7,0.5-1.4,1-2.1,1.6L338.5,30.9z" />
        <path class="st0"
              d="M330.4,37.7c0.6-0.6,1.2-1.1,1.8-1.7l9,4.8c-0.6,0.6-1.3,1.2-1.9,1.7L330.4,37.7z" />
        <path class="st0"
              d="M323.1,45.2c0.5-0.6,1.1-1.2,1.6-1.8l10.8,3.2c-0.6,0.6-1.1,1.3-1.6,1.9L323.1,45.2z" />
        <path class="st0"
              d="M316.8,53.3c0.5-0.7,0.9-1.3,1.4-2l12.5,1.5c-0.5,0.7-0.9,1.4-1.3,2L316.8,53.3z" />
        <path class="st0"
              d="M311.7,61.8c0.3-0.6,0.6-1.2,0.9-1.7l14-0.5c-0.3,0.6-0.6,1.2-0.8,1.8L311.7,61.8z" />
        <path class="st0"
              d="M307.9,70.9c0.2-0.6,0.4-1.2,0.6-1.8l15.4-2.7c-0.2,0.6-0.4,1.2-0.5,1.8L307.9,70.9z" />
        <path class="st0"
              d="M305.7,80.3c0.1-0.6,0.2-1.2,0.3-1.9l16.4-5.1c-0.1,0.6-0.1,1.2-0.2,1.8L305.7,80.3z" />
        <path class="st0"
              d="M305.3,89.6c0-0.5,0-1-0.1-1.5l17.1-7.6c0.1,0.5,0.1,1,0.2,1.4L305.3,89.6z" />
        <path class="st0"
              d="M306.9,98.9c-0.1-0.5-0.3-1-0.4-1.4l17.4-10.4c0.2,0.5,0.3,0.9,0.5,1.4L306.9,98.9z" />
        <path class="st0"
              d="M310.6,107.5c-0.2-0.3-0.4-0.7-0.6-1l17-13.2c0.2,0.3,0.4,0.6,0.7,0.9L310.6,107.5z" />
        <path class="st0"
              d="M316.8,115.4c-0.3-0.3-0.6-0.6-0.9-0.9l15.8-15.9c0.3,0.3,0.6,0.5,0.9,0.8L316.8,115.4z" />
        <path class="st0"
              d="M325.2,121.8l-0.8-0.4l13.7-18.5c0.3,0.1,0.5,0.3,0.8,0.4L325.2,121.8z" />
        <polygon class="st0"
                 points="336,126.6 335.2,126.3 345.6,105.8 346.4,106.1 " />
        <path class="st0"
              d="M348,129.2c-1.1-0.1-2.2-0.3-3.3-0.5l7.5-21.5c1,0.1,2.1,0.2,3.2,0.3c1.8,0.1,3.6,0.2,5.4,0.1l-7.2,22
C351.7,129.6,349.9,129.4,348,129.2" />
        <path class="st0"
              d="M338.7,103.2c1.3,0.7,2.8,1.3,4.3,1.9l8.5-13.5c-1.5-0.4-2.8-1-4.1-1.6L338.7,103.2z" />
        <path class="st0"
              d="M332.4,99c0.9,0.8,1.9,1.5,2.9,2.2l10.4-12c-1.1-0.5-1.9-1-2.9-1.8L332.4,99z" />
        <path class="st0"
              d="M327.4,94.1c0.7,0.9,1.4,1.8,2.1,2.6l11.9-10.2c-0.8-0.8-1.6-1.5-2.3-2.3L327.4,94.1z" />
        <path class="st0"
              d="M324,88.3c0.4,1,0.8,2,1.3,3l12.8-8.5c-0.6-0.9-1.1-1.9-1.5-2.8L324,88.3z" />
        <path class="st0"
              d="M322.2,81.5c0.1,1.1,0.3,2.1,0.6,3.2l13-6.5c-0.3-1-0.6-2-0.8-3.1L322.2,81.5z" />
        <path class="st0"
              d="M322.2,74.7c-0.1,1.1-0.1,2.2-0.1,3.3l12.8-4.6c-0.1-1.1-0.1-2.1,0-3.2L322.2,74.7z" />
        <path class="st0"
              d="M323.4,67.6c-0.3,1.1-0.6,2.2-0.8,3.3l12.3-2.8c0.1-1.1,0.4-2.2,0.6-3.3L323.4,67.6z" />
        <path class="st0"
              d="M325.8,60.9c-0.4,1-0.9,2-1.2,3l11.5-1c0.3-1,0.7-2,1.2-3L325.8,60.9z" />
        <path class="st0"
              d="M328.5,55.8c-0.3,0.5-0.5,0.9-0.8,1.4l10.5,0.6c0.2-0.5,0.5-0.9,0.8-1.4c0.3-0.5,0.6-1,0.9-1.5l-10.4-0.5
C329.1,54.8,328.8,55.3,328.5,55.8" />
        <path class="st0"
              d="M334.2,47.7c-0.7,0.8-1.3,1.6-1.9,2.4l9.2,2.1c0.6-0.9,1.3-1.7,1.9-2.5L334.2,47.7z" />
        <path class="st0"
              d="M339.7,41.9c-0.8,0.7-1.5,1.5-2.3,2.2l7.9,3.5c0.7-0.8,1.5-1.6,2.3-2.3L339.7,41.9z" />
        <path class="st0"
              d="M345.9,36.6c-0.9,0.6-1.7,1.3-2.5,2l6.4,4.7c0.9-0.7,1.7-1.4,2.6-2.1L345.9,36.6z" />
        <path class="st0"
              d="M352.5,32c-0.8,0.5-1.7,1-2.5,1.6l4.8,5.7c0.8-0.6,1.7-1.1,2.6-1.6L352.5,32z" />
        <path class="st0"
              d="M359.8,28c-0.9,0.4-1.7,0.9-2.6,1.3l3.1,6.7c0.9-0.5,1.8-0.9,2.7-1.4L359.8,28z" />
        <path class="st0"
              d="M367.5,24.8c-0.9,0.3-1.8,0.7-2.7,1.1l1.4,7.5c0.9-0.4,1.9-0.7,2.8-1.1L367.5,24.8z" />
        <path class="st0"
              d="M375.2,22.4c-0.8,0.2-1.7,0.4-2.5,0.7l-0.4,8.1c0.9-0.2,1.7-0.5,2.6-0.7L375.2,22.4z" />
        <path class="st0"
              d="M383.2,20.8c-0.8,0.1-1.7,0.2-2.5,0.4l-2.2,8.6c0.9-0.1,1.7-0.3,2.6-0.4L383.2,20.8z" />
        <path class="st0"
              d="M391,20.2c-0.4,0-0.9,0-1.3,0c-0.3,0-0.5,0-0.8,0l-4.1,8.8c0.3,0,0.6,0,0.8,0c0.5,0,0.9,0,1.4,0L391,20.2z" />
        <path class="st0"
              d="M398.8,20.6c-0.7-0.1-1.4-0.2-2.1-0.2l-6,8.9c0.7,0.1,1.4,0.2,2.1,0.3L398.8,20.6z" />
        <path class="st0"
              d="M406,22c-0.5-0.2-1.1-0.3-1.7-0.4l-8,8.8c0.6,0.1,1.1,0.3,1.6,0.5L406,22z" />
        <path class="st0"
              d="M412.8,24.6c-0.5-0.2-1-0.5-1.5-0.7l-9.8,8.4c0.5,0.2,1,0.5,1.5,0.8L412.8,24.6z" />
        <path class="st0"
              d="M418.9,28.4c-0.4-0.3-0.8-0.7-1.3-1l-11.6,7.7c0.4,0.3,0.8,0.7,1.2,1L418.9,28.4z" />
        <path class="st0"
              d="M423.8,33c-0.3-0.4-0.7-0.8-1-1.2l-13.2,6.7c0.3,0.4,0.6,0.9,0.9,1.3L423.8,33z" />
        <path class="st0"
              d="M427.4,38.9c-0.2-0.5-0.5-1-0.7-1.5l-14.5,5.3c0.2,0.5,0.4,1,0.6,1.5L427.4,38.9z" />
        <path class="st0"
              d="M429.3,45.9c-0.1-0.6-0.2-1.2-0.3-1.7l-15.5,3.5c0.1,0.6,0.1,1.1,0.2,1.7L429.3,45.9z" />
        <path class="st0"
              d="M429.6,53.5c0.1-0.6,0.1-1.3,0.1-1.9l-16,1.5c-0.1,0.6-0.1,1.2-0.3,1.9L429.6,53.5z" />
        <path class="st0"
              d="M428,61.9c0.2-0.6,0.4-1.1,0.5-1.7l-15.9-0.8c-0.2,0.5-0.4,1.1-0.6,1.6L428,61.9z" />
        <path class="st0"
              d="M424.4,70.2c0.3-0.6,0.6-1.1,0.9-1.7l-15.2-3.3c-0.3,0.5-0.6,1.1-1,1.6L424.4,70.2z" />
        <path class="st0"
              d="M418.5,78.8c0.4-0.5,0.9-1.1,1.3-1.6L406,71.2c-0.4,0.5-0.9,1-1.3,1.5L418.5,78.8z" />
        <path class="st0"
              d="M411.2,86.8c0.4-0.4,0.9-0.8,1.3-1.2l-11.8-8.4c-0.4,0.4-0.8,0.8-1.3,1.1L411.2,86.8z" />
        <path class="st0"
              d="M402.1,93.9c0.5-0.3,1-0.7,1.5-1l-9.1-10.7c-0.5,0.3-1,0.6-1.5,1L402.1,93.9z" />
        <path class="st0"
              d="M392.1,100c0.4-0.2,0.8-0.4,1.3-0.6l-6-12.6c-0.4,0.2-0.8,0.4-1.2,0.6L392.1,100z" />
        <path class="st0"
              d="M380.8,104.2c0.4-0.1,0.9-0.3,1.3-0.4l-2.6-13.9c-0.4,0.1-0.8,0.3-1.2,0.4L380.8,104.2z" />
        <path class="st0"
              d="M370.4,106.8l0.9-0.1l0.9-14.6c-0.3,0-0.6,0.1-0.8,0.1L370.4,106.8z" />
        <polygon class="st0"
                 points="360.6,107.7 361.5,107.6 365.8,92.9 364.9,93 " />
        <path class="st0"
              d="M352.2,107.2l6.5-14.4c-0.8-0.1-1.5-0.2-2.2-0.3c-1.2-0.2-2-0.3-3.1-0.6l-7.1,14
C347.5,106.3,351.5,107.1,352.2,107.2" />
        <g>
            <rect x="481"
                  y="57.9"
                  class="st0"
                  width="25.6"
                  height="71.9" />
            <path class="st0"
                  d="M515,64.1c12.5-4.8,24.6-7.6,38.1-7.6c28.6,0,39.2,13.1,39.2,35.9v37.4h-25.9V92.4c0-16.9-3.7-21.9-13.3-21.9
c-4.7,0-9.1,0.9-12.6,1.7v57.6H515L515,64.1z" />
        </g>
        <g>
            <path class="st0"
                  d="M636.7,56.5c23.9,0,38.1,11.1,38.1,36.1c0,24.9-7.8,38.4-38.1,38.4c-29.9,0-38.7-11.8-38.7-38.6
C598,67,610.6,56.5,636.7,56.5L636.7,56.5z M653.2,92.7c0-18.4-6.4-24-16.5-24c-12.7,0-17.1,4.1-17.1,23.7
c0,20.9,2.9,26.5,17.1,26.5C650.7,118.9,653.2,112.3,653.2,92.7z" />
        </g>
        <g>
            <path class="st0"
                  d="M689.8,57.9l18.7,54.1h1.5l18.9-54.1h18.1l-27.4,71.9h-20.7l-27-71.9H689.8z" />
        </g>
        <path class="st0"
              d="M813,58.2h-11.4l-1.6,3.9c-5.5-3.5-12.5-5.1-21-5.1v0c-23.7,0-34.7,9.8-34.7,35.8c0,27.9,7.7,38.7,34.7,38.7
c9,0,15.7-1.4,20.8-4.2v2.5H813V58.2z M779,123c-17.6,0-21.1-7.6-21.1-30.4c0-21.9,5.7-27.4,21.1-27.4c12.7,0,20.5,7,20.5,27.7
C799.4,114.2,796.5,123,779,123L779,123z" />
        <path class="st0"
              d="M833.1,30.4c-3.6,2.6-7.3,4.9-11,6.8l-0.6,0.3v92.2h13.2V29.2L833.1,30.4z" />
        <g>
            <path class="st0"
                  d="M873.8,56.8c20.9,0,32.6,10.3,32.6,36c0,26.1-6.3,38.4-32.6,38.4c-26.2,0-33.2-10.6-33.2-38.7
C840.6,66.4,850.8,56.8,873.8,56.8L873.8,56.8z M895.2,92.9c0-20.2-8.2-27.6-21.5-27.6c-15.8,0-22,6.1-22,27.4
c0,22,3.7,30.1,22,30.1C892.1,122.8,895.2,113.3,895.2,92.9z" />
        </g>
        <path class="st0"
              d="M973.4,92.2c0-24.3-8.3-35.7-31.2-35.7c-10.4,0-19,2-30.1,6.2l0,67l9.3,0V66.8c7.3-2.3,14.5-3.5,20.8-3.5
c15.5,0,21.6,8.3,21.6,29.7v36.8l9.6,0V92.2z" />
        <g>
            <path class="st0"
                  d="M990.5,62.8c-0.4,1-1,1.9-1.8,2.6c-0.7,0.7-1.6,1.3-2.6,1.7c-1,0.4-2.1,0.6-3.3,0.6c-1.2,0-2.3-0.2-3.3-0.6
c-1-0.4-1.9-1-2.6-1.7c-0.7-0.7-1.3-1.6-1.8-2.6c-0.4-1-0.6-2.2-0.6-3.4c0-1.2,0.2-2.4,0.6-3.4c0.4-1,1-1.9,1.8-2.6
c0.7-0.7,1.6-1.3,2.6-1.7c1-0.4,2.1-0.6,3.3-0.6c1.2,0,2.3,0.2,3.3,0.6c1,0.4,1.9,1,2.6,1.7c0.7,0.7,1.3,1.6,1.8,2.6
c0.4,1,0.6,2.2,0.6,3.4C991.1,60.7,990.9,61.8,990.5,62.8z M989.4,56.5c-0.4-0.9-0.9-1.7-1.5-2.3c-0.6-0.6-1.4-1.1-2.3-1.5
c-0.9-0.4-1.8-0.5-2.8-0.5s-2,0.2-2.8,0.5c-0.9,0.4-1.6,0.9-2.3,1.5c-0.6,0.6-1.2,1.4-1.5,2.3c-0.4,0.9-0.6,1.9-0.6,3
s0.2,2.1,0.6,3c0.4,0.9,0.9,1.7,1.5,2.3c0.6,0.6,1.4,1.1,2.3,1.5c0.9,0.4,1.8,0.5,2.8,0.5s2-0.2,2.8-0.5c0.9-0.4,1.6-0.9,2.3-1.5
c0.6-0.6,1.2-1.4,1.5-2.3c0.4-0.9,0.6-1.9,0.6-3S989.8,57.4,989.4,56.5z M985.9,59c-0.4,0.4-0.9,0.7-1.5,0.9l2.1,4h-1.4l-2-3.9h-2
v3.9h-1.2v-9.2h3.7c0.8,0,1.5,0.2,2.1,0.6c0.6,0.4,0.9,1.1,0.9,2C986.5,58,986.3,58.6,985.9,59z M984.8,56.1
c-0.3-0.3-0.8-0.4-1.3-0.4H981v3.1h2.3c1.3,0,1.9-0.5,1.9-1.6C985.2,56.8,985.1,56.4,984.8,56.1z" />
        </g>
        <g>
            <path class="st0"
                  d="M6.3,129.7V94.7h11.4c4.3,0,7.1,0.2,8.4,0.5c2,0.5,3.7,1.7,5,3.4c1.4,1.8,2,4,2,6.8c0,2.2-0.4,4-1.2,5.4
s-1.8,2.6-3,3.5c-1.2,0.8-2.4,1.4-3.7,1.7c-1.7,0.3-4.1,0.5-7.3,0.5h-4.6v13.2H6.3z M13.4,100.6v10h3.9c2.8,0,4.7-0.2,5.6-0.6
c0.9-0.4,1.7-0.9,2.2-1.7c0.5-0.8,0.8-1.7,0.8-2.7c0-1.3-0.4-2.3-1.1-3.2c-0.8-0.8-1.7-1.3-2.8-1.6c-0.8-0.2-2.5-0.2-5.1-0.2H13.4z
" />
            <path class="st0"
                  d="M37.4,116.7c0-2.2,0.5-4.4,1.7-6.5c1.1-2.1,2.7-3.7,4.7-4.8s4.3-1.7,6.8-1.7c3.8,0,7,1.2,9.5,3.7
c2.5,2.5,3.7,5.7,3.7,9.5c0,3.8-1.2,7-3.7,9.6c-2.5,2.5-5.6,3.8-9.4,3.8c-2.3,0-4.5-0.5-6.7-1.6s-3.7-2.6-4.8-4.6
C38,122.1,37.4,119.6,37.4,116.7z M44.3,117c0,2.5,0.6,4.5,1.8,5.8s2.7,2,4.4,2c1.8,0,3.2-0.7,4.4-2s1.8-3.3,1.8-5.8
c0-2.5-0.6-4.4-1.8-5.7s-2.7-2-4.4-2c-1.8,0-3.2,0.7-4.4,2S44.3,114.5,44.3,117z" />
            <path class="st0"
                  d="M73.6,129.7l-8-25.4h6.5l4.8,16.7l4.4-16.7h6.5L92,121l4.9-16.7h6.6l-8.2,25.4h-6.5l-4.4-16.3l-4.3,16.3H73.6z
" />
            <path class="st0"
                  d="M121.7,121.7l6.7,1.1c-0.9,2.5-2.2,4.3-4.1,5.6c-1.9,1.3-4.2,1.9-7,1.9c-4.4,0-7.7-1.4-9.8-4.3
c-1.7-2.3-2.5-5.2-2.5-8.8c0-4.2,1.1-7.5,3.3-9.9c2.2-2.4,5-3.6,8.4-3.6c3.8,0,6.8,1.2,8.9,3.7c2.2,2.5,3.2,6.3,3.1,11.5H112
c0,2,0.6,3.5,1.6,4.7s2.3,1.7,3.9,1.7c1.1,0,1.9-0.3,2.7-0.9S121.4,122.9,121.7,121.7z M122.1,114.9c0-1.9-0.6-3.4-1.5-4.4
s-2.1-1.5-3.5-1.5c-1.5,0-2.7,0.5-3.6,1.6s-1.4,2.5-1.4,4.4H122.1z" />
            <path class="st0"
                  d="M140.7,129.7H134v-25.4h6.2v3.6c1.1-1.7,2-2.8,2.9-3.4s1.8-0.8,2.9-0.8c1.5,0,3,0.4,4.4,1.3l-2.1,5.9
c-1.1-0.7-2.2-1.1-3.2-1.1c-0.9,0-1.7,0.3-2.4,0.8c-0.7,0.5-1.2,1.5-1.5,2.8c-0.4,1.4-0.6,4.2-0.6,8.5V129.7z" />
            <path class="st0"
                  d="M168.1,121.7l6.7,1.1c-0.9,2.5-2.2,4.3-4.1,5.6c-1.9,1.3-4.2,1.9-7,1.9c-4.4,0-7.7-1.4-9.8-4.3
c-1.7-2.3-2.5-5.2-2.5-8.8c0-4.2,1.1-7.5,3.3-9.9c2.2-2.4,5-3.6,8.4-3.6c3.8,0,6.8,1.2,8.9,3.7c2.2,2.5,3.2,6.3,3.1,11.5h-16.8
c0,2,0.6,3.5,1.6,4.7s2.3,1.7,3.9,1.7c1.1,0,1.9-0.3,2.7-0.9S167.7,122.9,168.1,121.7z M168.4,114.9c0-1.9-0.6-3.4-1.5-4.4
s-2.1-1.5-3.5-1.5c-1.5,0-2.7,0.5-3.6,1.6s-1.4,2.5-1.4,4.4H168.4z" />
            <path class="st0"
                  d="M203.9,129.7h-6.2V126c-1,1.5-2.3,2.5-3.7,3.2c-1.4,0.7-2.8,1.1-4.3,1.1c-2.9,0-5.4-1.2-7.5-3.5
c-2.1-2.4-3.1-5.6-3.1-9.8c0-4.3,1-7.6,3-9.8c2-2.2,4.6-3.4,7.7-3.4c2.8,0,5.3,1.2,7.4,3.5V94.7h6.7V129.7z M186,116.5
c0,2.7,0.4,4.7,1.1,5.9c1.1,1.8,2.6,2.6,4.5,2.6c1.5,0,2.9-0.7,3.9-2c1.1-1.3,1.6-3.3,1.6-5.9c0-2.9-0.5-5-1.6-6.3
c-1.1-1.3-2.4-1.9-4-1.9c-1.6,0-2.9,0.6-4,1.9C186.5,112.1,186,114,186,116.5z" />
            <path class="st0"
                  d="M223.8,129.7V94.7h6.7v12.6c2.1-2.4,4.5-3.5,7.4-3.5c3.1,0,5.7,1.1,7.7,3.4s3,5.5,3,9.7c0,4.3-1,7.7-3.1,10
s-4.6,3.5-7.5,3.5c-1.5,0-2.9-0.4-4.3-1.1c-1.4-0.7-2.6-1.8-3.6-3.2v3.7H223.8z M230.5,116.5c0,2.6,0.4,4.6,1.2,5.8
c1.2,1.8,2.7,2.7,4.6,2.7c1.5,0,2.7-0.6,3.8-1.9c1-1.3,1.6-3.3,1.6-6c0-2.9-0.5-5-1.6-6.3c-1.1-1.3-2.4-1.9-4-1.9
c-1.6,0-3,0.6-4,1.9C231.1,112,230.5,113.9,230.5,116.5z" />
            <path class="st0"
                  d="M250.9,104.3h7.2l6.1,18l5.9-18h7l-9,24.5l-1.6,4.4c-0.6,1.5-1.2,2.6-1.7,3.4c-0.5,0.8-1.1,1.4-1.8,1.9
c-0.7,0.5-1.5,0.9-2.6,1.1c-1,0.3-2.2,0.4-3.4,0.4c-1.3,0-2.6-0.1-3.8-0.4l-0.6-5.3c1.1,0.2,2,0.3,2.8,0.3c1.6,0,2.7-0.5,3.5-1.4
c0.7-0.9,1.3-2.1,1.7-3.5L250.9,104.3z" />
        </g>
    </symbol>
    <symbol id="previousarrow"
            viewBox="0 0 7.7 14">
        <g>
            <path class="st0"
                  d="M7.3,14C7.1,14,7,14,7,13.9L0.1,7l6.8-6.8C7,0,7.3,0,7.5,0.1c0.2,0.1,0.2,0.5,0,0.7L1.3,7l6.2,6.2
c0.2,0.2,0.2,0.5,0,0.7C7.5,14,7.3,14,7.3,14z" />
        </g>
    </symbol>
    <symbol id="profile"
            viewBox="0 0 20 20">
        <path class="st0"
              d="M10,0C4.5,0,0,4.5,0,10c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C20,4.5,15.5,0,10,0z M10,1c5,0,9,4,9,9
c0,1.9-0.6,3.6-1.6,5c0,0,0,0,0,0c-0.1-0.3-0.4-0.6-0.6-0.8c-0.6-0.5-1.3-0.9-2-1.2c-0.8-0.3-1.5-0.7-2.1-1.2
c-0.2-0.2-0.2-0.3-0.1-0.9c0-0.2,0-0.4,0.1-0.7c0.2,0,0.4-0.2,0.6-0.5c0.2-0.4,0.4-0.9,0.5-1.4c0-0.3,0.2-0.8-0.2-1.2
c0.2-1.1-0.1-2.3-0.8-3.2c-0.5-0.5-1.1-0.9-1.8-1.1l0.5-0.4L9.9,2.4c-0.4,0-0.8,0.1-1.2,0.1L8.3,2.7C7.6,2.9,7.1,3.5,6.7,4.1
c-0.5,1-0.5,2.1-0.1,3.2C6.4,7.5,6.2,7.9,6.4,8.4c0.1,0.5,0.3,0.9,0.4,1.4l0.1,0.1c0.1,0.1,0.3,0.5,0.6,0.5c0.1,0.3,0.1,0.5,0.1,0.9
l0,0.1c0,0.1,0,0.3,0,0.3c-0.3,0.3-0.6,0.5-1,0.7c-0.3,0.2-0.7,0.3-1,0.5c-0.5,0.2-1,0.4-1.4,0.7c-0.7,0.3-1.3,0.8-1.6,1.4
C1.6,13.6,1,11.9,1,10C1,5,5,1,10,1z M10,19c-2.6,0-5-1.2-6.7-3c0.1-0.6,0.6-1.2,1.3-1.5c0.4-0.2,0.9-0.4,1.3-0.7
c0.4-0.2,0.7-0.3,1.1-0.5c0.5-0.2,0.9-0.6,1.3-1c0.3-0.3,0.2-0.7,0.2-1.1l0,0c0.1-0.6,0-1.1-0.3-1.7L7.8,8.7L7.6,9.1
c-0.1-0.3-0.2-0.7-0.3-1c0-0.2,0-0.2,0-0.2l0.5-0.2L7.6,7.3c-0.4-0.9-0.4-1.9,0-2.7c0.2-0.4,0.5-0.7,0.9-0.9l0.9-0.1
c1-0.1,2,0.2,2.6,0.9c0.6,0.7,0.8,1.8,0.5,2.7l-0.2,0.5l0.5,0.1c0,0.1,0,0.2,0,0.2c-0.1,0.4-0.2,0.7-0.4,1.1l0,0l-0.2,0.1
c-0.2,0.1-0.3,0.2-0.3,0.3c-0.2,0.4-0.3,0.9-0.3,1.3c0,0.6-0.1,1.1,0.4,1.7c0.7,0.6,1.5,1.1,2.4,1.4c0.6,0.3,1.2,0.7,1.7,1.1
c0.3,0.2,0.4,0.5,0.5,0.9c0,0.1,0,0.1,0,0.2C15,17.8,12.7,19,10,19z" />
    </symbol>
    <symbol id="profileicon"
            viewBox="101.5 691.837 20 20">
        <g id="Group_289"
           data-name="Group 289"
           transform="translate(101 691.337)">
            <path id="Path_1852"
                  data-name="Path 1852"
                  class="cls-1"
                  d="M17.7,16.684a3.647,3.647,0,0,0-.035-.449,2.013,2.013,0,0,0-.656-1.175,10.63,10.63,0,0,0-1.848-1.14,6.964,6.964,0,0,1-2.28-1.3c-.311-.345-.294-.691-.259-1.244a2.319,2.319,0,0,1,.259-1.123c.1-.276.19.225.484-.328a4.762,4.762,0,0,0,.484-1.278c.069-.38.138-.743-.294-.829a3.263,3.263,0,0,0-.622-3.144A3.6,3.6,0,0,0,9.878,3.574c.086-.069.449-.121.518-.173a7.491,7.491,0,0,0-1.088.121c-.1.035-.19.069-.276.1A2.733,2.733,0,0,0,7.65,4.852,3.77,3.77,0,0,0,7.667,8c-.4.138-.4.449-.328.777a12.058,12.058,0,0,0,.4,1.347c.4.587.553.259.553.259a2.619,2.619,0,0,1,.242,1.4c.017.259.069.639-.069.795a4.888,4.888,0,0,1-1.192.864c-.795.415-1.641.725-2.418,1.175a2.429,2.429,0,0,0-1.52,1.814,1.4,1.4,0,0,0-.017.276A9.42,9.42,0,0,1,1,10.5a9.5,9.5,0,1,1,16.7,6.184Z" />
            <circle id="Ellipse_157"
                    data-name="Ellipse 157"
                    class="cls-1"
                    cx="9.5"
                    cy="9.5"
                    r="9.5"
                    transform="translate(1 1)" />
        </g>
    </symbol>
    <symbol id="profil_portal_menu"
            viewBox="0 0 4 24">
        <g id="Repeat_Grid_14"
           transform="translate(197 1549)">
            <g id="Group_236"
               transform="translate(-202 -204)">
                <circle id="Ellipse_129"
                        class="st0"
                        cx="7"
                        cy="-1343"
                        r="2" />
            </g>
            <g id="Group_237"
               transform="translate(-202 -194)">
                <circle id="Ellipse_130"
                        class="st0"
                        cx="7"
                        cy="-1343"
                        r="2" />
            </g>
            <g id="Group_238"
               transform="translate(-202 -184)">
                <circle id="Ellipse_131"
                        class="st0"
                        cx="7"
                        cy="-1343"
                        r="2" />
            </g>
        </g>
    </symbol>
    <symbol id="quality"
            viewBox="0 0 20 20">
        <g>
            <g id="Group_300"
               transform="translate(-2.995 -3.036)">
                <g id="Path_1985">
                    <path class="st0"
                          d="M16.4,23c-0.7,0-1.4-0.4-2.1-0.8c-0.5-0.3-1-0.6-1.4-0.6c0,0,0,0,0,0c-0.4,0-1,0.3-1.5,0.6
c-0.9,0.5-1.8,1-2.6,0.6c-0.8-0.4-1.1-1.3-1.3-2.3c-0.2-0.6-0.3-1.2-0.6-1.5c-0.2-0.2-0.8-0.4-1.4-0.5c-1-0.3-2-0.6-2.4-1.5
c-0.4-0.9,0.2-1.8,0.7-2.6c0.3-0.5,0.6-1,0.6-1.4c0-0.4-0.3-1-0.6-1.5c-0.5-0.9-1-1.8-0.6-2.6c0.4-0.8,1.3-1.1,2.3-1.4
C6.1,7.4,6.7,7.2,7,6.9c0.3-0.3,0.4-0.9,0.6-1.4c0.3-0.9,0.5-2,1.4-2.3c0.9-0.4,1.8,0.2,2.6,0.7c0.5,0.3,1,0.6,1.4,0.6
c0,0,0,0,0,0c0.4,0,1-0.3,1.5-0.6c0.9-0.5,1.8-1,2.6-0.6c0.8,0.4,1.1,1.3,1.4,2.3c0.2,0.6,0.3,1.2,0.6,1.5
c0.3,0.2,0.9,0.4,1.5,0.6c0.9,0.3,2,0.5,2.3,1.4l0,0c0.4,0.9-0.2,1.8-0.7,2.7c-0.3,0.5-0.6,1-0.6,1.4c0,0.4,0.3,1,0.6,1.5
c0.5,0.9,1,1.8,0.6,2.6c-0.4,0.8-1.3,1.1-2.3,1.3c-0.6,0.2-1.2,0.3-1.5,0.6c-0.3,0.3-0.4,0.9-0.6,1.4c-0.3,0.9-0.5,2-1.4,2.3
C16.8,23,16.6,23,16.4,23z M12.9,20.6C12.9,20.6,12.9,20.6,12.9,20.6c0.6,0,1.3,0.4,1.9,0.8c0.7,0.4,1.3,0.8,1.7,0.6
c0.4-0.2,0.6-1,0.8-1.6c0.2-0.7,0.4-1.4,0.9-1.9c0.5-0.5,1.2-0.7,2-0.9c0.7-0.2,1.5-0.4,1.6-0.8s-0.2-1.1-0.6-1.7
c-0.4-0.7-0.7-1.3-0.7-2c0-0.6,0.4-1.3,0.8-1.9c0.4-0.7,0.8-1.3,0.6-1.7c-0.2-0.4-1-0.6-1.7-0.8c-0.7-0.2-1.4-0.4-1.9-0.8
c-0.5-0.5-0.7-1.2-0.9-2c-0.2-0.7-0.4-1.5-0.8-1.6C16.4,4,15.7,4.4,15,4.8c-0.7,0.4-1.3,0.8-2,0.7c-0.6,0-1.3-0.4-1.9-0.8
c-0.7-0.4-1.3-0.8-1.7-0.6C9,4.3,8.8,5.1,8.6,5.8C8.4,6.5,8.2,7.2,7.7,7.7c-0.5,0.5-1.2,0.7-2,0.9C5.1,8.7,4.3,8.9,4.1,9.3
C4,9.7,4.4,10.4,4.7,11c0.4,0.7,0.7,1.3,0.7,2c0,0.6-0.4,1.3-0.8,1.9c-0.4,0.7-0.8,1.3-0.6,1.7c0.2,0.4,1,0.7,1.7,0.9
c0.7,0.2,1.4,0.4,1.8,0.8c0.5,0.5,0.7,1.2,0.9,2c0.2,0.7,0.4,1.5,0.8,1.6c0.4,0.2,1.1-0.2,1.7-0.6C11.6,20.9,12.3,20.6,12.9,20.6
z" />
                </g>
            </g>
            <path class="st0"
                  d="M10.2,16.3c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2C16.4,13.5,13.6,16.3,10.2,16.3z
M10.2,4.9C7.3,4.9,5,7.2,5,10.1s2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2S13.1,4.9,10.2,4.9z" />
            <g id="Ellipse_164">
                <ellipse transform="matrix(1.962218e-03 -1 1 1.962218e-03 0.1019 20.2207)"
                         class="st0"
                         cx="10.2"
                         cy="10.1"
                         rx="4"
                         ry="4" />
            </g>
        </g>
    </symbol>
    <symbol id="riskadj"
            viewBox="0 0 20 13.6">
        <g>
            <path class="st0"
                  d="M19,13.6H1c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1h18c0.6,0,1,0.4,1,1v11.6C20,13.2,19.6,13.6,19,13.6z M1,1v11.6
h18V1L1,1z" />
            <polygon class="st0"
                     points="10.8,8.5 4.2,7.9 4.3,6.9 10.6,7.5 15.6,3.8 16.1,4.6 	" />
            <circle class="st0"
                    cx="10.8"
                    cy="7.9"
                    r="1.3" />
            <circle class="st0"
                    cx="15.8"
                    cy="4.2"
                    r="1.3" />
            <circle class="st0"
                    cx="4.3"
                    cy="7.4"
                    r="1.3" />
        </g>
    </symbol>
    <symbol id="search"
            viewBox="0 0 20 20">
        <g id="Serach_portal_Icon"
           transform="translate(14.975 1373)">
            <path id="Path_775"
                  class="st0"
                  d="M-2.8-1373c-4.2,0-7.7,3.4-7.7,7.6c0,0,0,0,0,0.1c0,1.9,0.7,3.7,1.9,5l-6.3,6.3
c-0.1,0.2-0.1,0.5,0,0.8c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l5-5l1.3-1.3c3.2,2.8,8,2.5,10.8-0.7c1.2-1.4,1.9-3.2,1.9-5.1
C5-1369.5,1.5-1373-2.8-1373z M-2.8-1358.6c-3.7,0-6.6-3-6.6-6.6s3-6.6,6.6-6.6s6.6,3,6.6,6.6C3.8-1361.6,0.9-1358.6-2.8-1358.6z" />
        </g>
    </symbol>
    <symbol id="settings"
            viewBox="0 0 20 20">
        <path class="st0"
              d="M3.4,11.9L3.4,11.9c-0.2-0.7-0.3-1.3-0.3-1.9c0-0.6,0.1-1.3,0.3-1.9l0-0.1L1.8,6.5l1-1.8L5,5.2l0,0
c0.9-0.9,2-1.6,3.3-1.9l0.1,0L9,1.2H11l0.6,2.1l0.1,0c1.2,0.3,2.4,1,3.3,1.9l0,0l2.1-0.5l1,1.8l-1.5,1.6l0,0.1
c0.2,0.6,0.3,1.2,0.3,1.9c0,0.6-0.1,1.3-0.3,1.9l0,0.1l1.5,1.6l-1,1.8L15,14.8l0,0c-0.9,0.9-2,1.6-3.3,1.9l-0.1,0L11,18.8H8.9
l-0.6-2.1l-0.1,0c-1.2-0.3-2.4-1-3.3-1.9l0,0l-2.1,0.5l-1-1.8L3.4,11.9z M17.5,11.7c0.1-0.6,0.2-1.1,0.2-1.7s-0.1-1.1-0.2-1.7
L18.8,7c0.2-0.2,0.3-0.6,0.1-0.9l-1.1-1.9c-0.1-0.2-0.4-0.4-0.6-0.4c-0.1,0-0.1,0-0.2,0l-1.7,0.4c-0.8-0.8-1.9-1.4-2.9-1.7l-0.5-1.7
c-0.1-0.3-0.4-0.5-0.7-0.5H8.9c-0.3,0-0.6,0.2-0.7,0.5L7.7,2.6C6.6,3,5.6,3.5,4.8,4.3L3,3.9c-0.1,0-0.1,0-0.2,0
C2.6,3.9,2.3,4,2.2,4.2L1.1,6.2C0.9,6.4,1,6.8,1.2,7l1.3,1.3C2.3,8.9,2.3,9.4,2.3,10s0.1,1.1,0.2,1.7L1.2,13
c-0.2,0.2-0.3,0.6-0.1,0.9l1.1,1.9c0.1,0.2,0.4,0.4,0.6,0.4c0.1,0,0.1,0,0.2,0l1.7-0.4c0.8,0.8,1.9,1.4,2.9,1.7l0.5,1.7
c0.1,0.3,0.4,0.5,0.7,0.5h2.2c0.3,0,0.6-0.2,0.7-0.5l0.5-1.7c1.1-0.3,2.1-0.9,2.9-1.7l1.7,0.4c0.1,0,0.1,0,0.2,0
c0.3,0,0.5-0.1,0.6-0.4l1.1-1.9c0.2-0.3,0.1-0.6-0.1-0.9L17.5,11.7z" />
        <path class="st0"
              d="M5.8,10c0-2.3,1.9-4.2,4.2-4.2c2.3,0,4.2,1.9,4.2,4.2s-1.9,4.2-4.2,4.2C7.7,14.2,5.8,12.3,5.8,10z M10,15
c2.8,0,5-2.3,5-5s-2.3-5-5-5s-5,2.3-5,5C5,12.8,7.2,15,10,15z" />
    </symbol>
    <symbol id="upload">
        <rect x="0.63"
              y="0.63"
              width="18.65"
              height="18.65"
              rx="2"
              ry="2"
              fill="none"
              stroke="#0881c5"
              stroke-miterlimit="10"
              stroke-width="1.25" />
        <polyline points="4.34 10.49 10.04 4.79 15.74 10.49"
                  fill="none"
                  stroke="#0881c5"
                  stroke-miterlimit="10"
                  stroke-width="1.25" />
        <line x1="10.04"
              y1="15.99"
              x2="10.04"
              y2="4.99"
              fill="none"
              stroke="#0881c5"
              stroke-miterlimit="10"
              stroke-width="1.25" />
    </symbol>
    <symbol id="clinical"
            viewBox="0 0 16.8 20">
        <path class="st0"
              d="M16.7,9c-0.3-1-1.2-1.7-2.2-1.7v0c-1.2,0-2.3,1-2.3,2.2c0,1,0.7,2,1.7,2.2v2.8c0,2.4-2,4.4-4.4,4.4
s-4.4-2-4.4-4.4v-2.7c0.1,0,0.3-0.1,0.4-0.3C7.8,11.1,9.3,9.2,9.3,7V2.3c0-0.8-0.4-1.5-1-1.9C7.4,0,6.4,0,5.6,0.4
c0,0-0.5,0.3-0.4,0.7C5.4,1.6,6,1.4,6,1.4c0.5-0.3,1.2-0.3,1.7-0.1c0.3,0.2,0.5,0.6,0.5,1V7c0,1.7-1.2,3.1-2.8,3.5
c-0.1-0.1-0.2-0.1-0.4-0.1H4.4c-0.1,0-0.3,0-0.4,0.1C2.3,10.2,1.1,8.7,1.1,7V2.2c0-0.4,0.2-0.8,0.5-1C2.2,1,2.8,1.1,3.3,1.3
c0,0,0.6,0.1,0.7-0.3c0.1-0.3,0-0.6-0.3-0.7C2.9-0.1,1.9-0.1,1,0.3C0.4,0.7,0,1.4,0,2.2V7c0,2.2,1.6,4.2,3.8,4.6
c0.1,0.1,0.2,0.2,0.3,0.2v2.7c0,3,2.5,5.5,5.5,5.5c3,0,5.5-2.5,5.5-5.5v-2.8C16.3,11.4,17.1,10.2,16.7,9z M14.6,10.7L14.6,10.7
c-0.6,0-1.2-0.5-1.2-1.2c0-0.6,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2C15.7,10.2,15.2,10.7,14.6,10.7z M14.6,8.9c-0.3,0-0.6,0.3-0.6,0.6
s0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6S14.9,8.9,14.6,8.9z" />
    </symbol>
    <symbol id="contract"
            viewBox="0 0 20 13.6">
        <g>
            <path class="st0"
                  d="M19,13.6H1c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1h18c0.6,0,1,0.4,1,1v11.6C20,13.2,19.6,13.6,19,13.6z M1,1v11.6
h18V1L1,1z M1,1C1,1,1,1,1,1L1,1z" />
        </g>
        <g id="Rectangle_165">
            <rect x="2.7"
                  y="2.7"
                  class="st0"
                  width="5.5"
                  height="8.2" />
        </g>
        <g id="Line_148">
            <rect x="9.5"
                  y="2.7"
                  class="st0"
                  width="7.8"
                  height="1" />
        </g>
        <g id="Line_149">
            <rect x="9.5"
                  y="6.3"
                  class="st0"
                  width="7.8"
                  height="1" />
        </g>
        <g id="Line_150">
            <rect x="9.5"
                  y="10"
                  class="st0"
                  width="5.5"
                  height="1" />
        </g>
    </symbol>
    <symbol id="demographics"
            viewBox="0 0 18.4 18.4">
        <path class="st0"
              d="M9.2,0C6.7,0,4.4,1,2.7,2.7C1,4.4,0,6.7,0,9.1c0,5,4,9.2,9.1,9.2c5,0,9.2-4,9.2-9.1C18.3,4.1,14.2,0,9.2,0z
M17.2,6.7l-5.9,5.9l0,0l0,0l0,0l-0.6-1.3l6-6C16.8,5.8,17,6.2,17.2,6.7z M16.2,4.6l-5.9,5.9l0,0l0,0l0,0L9.7,9.3l5.7-5.7
C15.7,3.9,16,4.2,16.2,4.6z M14.8,3L9,8.8H7.1l6.6-6.6C14.1,2.4,14.5,2.7,14.8,3z M13,1.7l-7,7l-1.9,0l7.6-7.6
C12.1,1.3,12.6,1.5,13,1.7z M9.2,0.8c0.5,0,1.1,0.1,1.6,0.1L2.9,8.8H1l8-8H9.2z M3.2,3.3C4.4,2,6,1.2,7.7,0.9L0.9,7.7
C1.2,6,2,4.5,3.2,3.3z M9.2,17.5c-4.5,0-8.1-3.5-8.4-8h8.1l3.4,7.4C11.4,17.4,10.3,17.6,9.2,17.5z M17.4,10.6
c-0.5,2.5-2.1,4.7-4.4,5.9l-0.5-1.1L17.4,10.6L17.4,10.6z M12.2,14.7L12.2,14.7l-0.6-1.3l5.8-5.8c0.1,0.5,0.1,1,0.1,1.6v0.2
L12.2,14.7z" />
    </symbol>
    <symbol id="disease"
            viewBox="0 0 13.1 20.2">
        <path class="st0"
              d="M2.9,2.7h8.8c0,0,0,0,0,0c-0.1,0.5-0.3,1-0.5,1.5H3.9c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h6.7
c-0.3,0.5-0.7,1-1.1,1.4c0,0,0,0,0,0H6.3C6,6.7,5.7,7,5.7,7.3c0,0.3,0.2,0.5,0.5,0.5h2.2C8.2,8.1,7.9,8.3,7.6,8.5c0,0,0,0-0.1,0
C7.4,8.7,7.4,8.8,7.4,9c0,0.1,0,0.3,0.1,0.4C7.6,9.5,7.8,9.5,8,9.6c0.1,0,0.3-0.1,0.4-0.2C10.6,7.5,13,4.9,13,0.5
C13,0.2,12.8,0,12.4,0h0c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.4,0,0.7,0,1.1c-0.1,0-0.1,0-0.2,0H2.9
c-0.3,0-0.5,0.3-0.5,0.6C2.4,2.4,2.6,2.7,2.9,2.7z M6.9,9.7c-3-2.3-5.8-4.5-5.8-9.2C1.1,0.2,0.8,0,0.5,0h0C0.2,0,0,0.3,0,0.5
c0,5.2,3.2,7.7,6.2,10.1l0,0c2.9,2.3,5.7,4.5,5.7,9h0c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5C13.1,14.5,9.8,12,6.9,9.7z
M10,17.8H1.3c0.1-0.5,0.2-1,0.4-1.5h7.4c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5H2.3c0.3-0.5,0.7-1.1,1.1-1.5c0.1,0,0.1,0,0.2,0
h3.1c0.3,0,0.6-0.2,0.6-0.5v0c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.1-0.4-0.1H4.5c0.3-0.3,0.6-0.5,1-0.9c0.2-0.2,0.3-0.5,0.1-0.8
c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.1c-2.4,2-4.8,4.4-4.8,8.9v0c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.1,0.4,0.1h0
c0.3,0,0.5-0.2,0.6-0.5c0-0.3,0-0.6,0-0.8c0,0,0.1,0,0.1,0h8.8c0.3,0,0.5-0.2,0.5-0.6C10.6,18,10.3,17.8,10,17.8z" />
    </symbol>
    <symbol id="financial"
            viewBox="0 0 17.8 20">
        <path class="st0"
              d="M14.9,20h2.8V6.8h-2.8V20z M15.7,7.6H17v11.6h-1.3V7.6z M10,20h2.8v-9.6H10V20z M12.1,11.2v8h-1.3l0-8H12.1z
M5.2,20h2.8v-7.5H5.2V20z M6,13.3h1.3v5.9H6V13.3z M0.4,20h2.8v-5.4H0.4V20z M1.2,15.3h1.3v3.9H1.2L1.2,15.3z M13.6,0v0.8h2.9
l-6.1,6.1L7.5,4.1L0,11.6l0.6,0.6l6.9-7L10.3,8l6.7-6.7v2.9h0.8V0H13.6z" />
    </symbol>
    <symbol id="operations"
            viewBox="0 0 20 20">
        <path class="st0"
              d="M19.6,14.2l-1.2-0.8c-0.1,0-0.1-0.1-0.1-0.2V13c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0.1-0.2l1.2-0.8
c0.3-0.2,0.5-0.6,0.3-1c0,0,0,0,0,0l-0.5-1.2c0,0,0,0,0,0c-0.2-0.3-0.5-0.5-0.9-0.4l-1.4,0.3c-0.1,0-0.1,0-0.2-0.1l-0.3-0.3
c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2l0.3-1.4c0.1-0.4-0.1-0.7-0.4-0.9c0,0,0,0,0,0l-1.2-0.5c0,0,0,0,0,0c-0.4-0.1-0.7,0-1,0.3
l-0.8,1.2c0,0.1-0.1,0.1-0.2,0.1h-0.5c-0.1,0-0.1,0-0.2-0.1l-0.8-1.3c-0.2-0.3-0.6-0.5-1-0.3c0,0,0,0,0,0L9.6,6.6c0,0,0,0,0,0
C9.3,6.7,9.1,7.1,9.2,7.5l0.3,1.4c0,0.1,0,0.1-0.1,0.2L9.1,9.5C9,9.5,9,9.5,8.9,9.5L7.5,9.2C7.1,9.1,6.7,9.3,6.5,9.6c0,0,0,0,0,0
l-0.5,1.2c0,0,0,0,0,0c-0.1,0.4,0,0.7,0.3,1l1.2,0.8c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3c0,0.2,0,0.2,0,0.3
c0,0.1,0,0.1-0.1,0.2l-1.3,0.8c-0.3,0.2-0.5,0.6-0.3,1c0,0,0,0,0,0l0.5,1.2c0,0,0,0,0,0c0.2,0.3,0.5,0.5,0.9,0.4l1.4-0.3
c0.1,0,0.1,0,0.2,0.1l0,0l0.3,0.3l0,0c0,0.1,0.1,0.1,0.1,0.2l-0.3,1.4c-0.1,0.4,0.1,0.7,0.4,0.9c0,0,0,0,0,0l1.2,0.5c0,0,0,0,0,0
c0.1,0,0.2,0,0.3,0c0.3,0,0.6-0.1,0.7-0.4l0.8-1.2c0-0.1,0.1-0.1,0.2-0.1h0.5c0.1,0,0.1,0,0.2,0.1l0.8,1.3c0.2,0.2,0.4,0.4,0.7,0.4
c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0l1.2-0.5c0,0,0,0,0,0c0.3-0.2,0.5-0.5,0.4-0.9l-0.3-1.4c0-0.1,0-0.2,0.1-0.2c0,0,0,0,0,0l0.3-0.3l0,0
c0.1,0,0.1-0.1,0.2-0.1l1.4,0.3c0.4,0.1,0.7-0.1,0.9-0.4c0,0,0,0,0,0l0.5-1.2c0,0,0,0,0,0C20.1,14.8,19.9,14.4,19.6,14.2z M18.8,16
C18.7,16,18.7,16,18.8,16l-1.5-0.3c-0.3-0.1-0.7,0-0.9,0.3L16,16.3c-0.2,0.2-0.4,0.6-0.3,0.9l0.3,1.4c0,0,0,0.1,0,0.1l-1.1,0.5
c0,0-0.1,0-0.1,0l-0.8-1.2c-0.2-0.3-0.5-0.4-0.8-0.4h-0.5c-0.3,0-0.7,0.2-0.9,0.4l-0.8,1.2c0,0,0,0-0.1,0L10,18.7c0,0,0,0,0-0.1
l0.3-1.4c0.1-0.3,0-0.7-0.3-0.9L9.7,16c-0.2-0.2-0.6-0.4-0.9-0.3L7.3,16c0,0-0.1,0-0.1,0l-0.5-1.1c0,0,0-0.1,0-0.1L8.1,14
c0.3-0.2,0.5-0.5,0.4-0.9v-0.4c0-0.3-0.1-0.7-0.4-0.9l-1.2-0.8c0,0,0,0,0-0.1l0.5-1.1c0,0,0,0,0.1,0l1.4,0.3c0.3,0.1,0.7,0,0.9-0.3
L10,9.6c0.2-0.2,0.4-0.6,0.3-0.9L9.9,7.3c0,0,0-0.1,0-0.1l1.1-0.5c0,0,0,0,0.1,0L11.9,8c0.2,0.3,0.5,0.5,0.9,0.4h0.4
c0.3,0,0.7-0.1,0.9-0.4l0.8-1.2c0,0,0,0,0.1,0L16,7.2c0,0,0,0,0,0.1l-0.3,1.4c-0.1,0.3,0,0.7,0.3,0.9l0.3,0.3
c0.2,0.2,0.6,0.4,0.9,0.3l1.4-0.3c0,0,0.1,0,0.1,0l0.5,1.1c0,0,0,0.1,0,0.1l-1.3,0.8c-0.3,0.2-0.5,0.5-0.4,0.9v0.4
c0,0.3,0.1,0.7,0.4,0.9l1.2,0.8c0,0,0,0,0,0.1L18.8,16z M13,10.2c-1.6,0-2.8,1.3-2.8,2.8h0c0,1.6,1.3,2.8,2.8,2.8
c1.6,0,2.8-1.3,2.8-2.8C15.8,11.4,14.5,10.2,13,10.2z M13,15c-1.1,0-2.1-0.9-2.1-2.1h0c0-1.1,0.9-2.1,2.1-2.1h0
c1.1,0,2.1,0.9,2.1,2.1S14.1,15,13,15z M6.2,8.3c0,0,0,0,0.2-0.1c0.1,0,0.1,0,0.2-0.1h0l0.8,0.6c0.3,0.2,0.6,0.2,0.8,0l0.6-0.6
c0.2-0.2,0.2-0.6,0-0.8L8.2,6.5v0l0.1-0.3v0c0,0,0,0,0,0l1-0.2c0.3-0.1,0.5-0.3,0.6-0.6V4.5c0-0.3-0.3-0.6-0.6-0.6l-1-0.2
c0,0,0,0,0,0s0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2v0l0.6-0.8c0.2-0.3,0.2-0.6,0-0.8l0,0L8.1,1.1c0,0,0,0,0,0c-0.2-0.2-0.6-0.2-0.8,0
L6.5,1.6h0L6.2,1.5h0c0,0,0,0,0,0L6,0.6C5.9,0.3,5.7,0,5.4,0H4.5C4.2,0,3.9,0.3,3.8,0.6L3.7,1.5c0,0,0,0,0,0h0L3.5,1.6
c-0.1,0-0.1,0-0.2,0.1h0L2.6,1.1C2.3,0.9,2,0.9,1.7,1.1L1.1,1.7c0,0,0,0,0,0C0.9,2,0.9,2.3,1.1,2.6l0.6,0.8v0L1.5,3.7v0c0,0,0,0,0,0
L0.6,3.9C0.3,3.9,0,4.2,0,4.5v0.9C0,5.7,0.3,5.9,0.6,6l1,0.2c0,0,0,0,0,0c0,0,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2v0L1.1,7.3
c-0.2,0.3-0.2,0.6,0,0.8l0,0l0.6,0.6c0,0,0,0,0,0c0.2,0.2,0.6,0.2,0.8,0l0.8-0.6h0c0,0,0,0,0,0l0.3,0.1h0c0,0,0,0,0,0l0.2,1
c0.1,0.3,0.3,0.5,0.6,0.6h0.9l0,0c0.3,0,0.6-0.3,0.6-0.6L6.2,8.3C6.1,8.3,6.1,8.3,6.2,8.3z M6,7.6c-0.1,0-0.1,0-0.1,0
C5.6,7.7,5.5,7.9,5.4,8.2L5.3,9.1H4.6L4.5,8.2C4.4,7.9,4.2,7.7,4,7.6L3.7,7.5c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.5,0.1L2.2,8.1
L1.8,7.7l0.5-0.7c0.2-0.2,0.2-0.5,0.1-0.8c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0C2.2,5.7,1.9,5.5,1.6,5.4L0.8,5.3V4.6l0.9-0.2
C1.9,4.4,2.1,4.2,2.2,4l0.1-0.3c0.1-0.3,0.1-0.5-0.1-0.8L1.7,2.2l0.5-0.5l0.7,0.5c0.2,0.2,0.5,0.2,0.8,0.1c0,0,0,0,0.1,0
c0.1,0,0.1,0,0.1-0.1c0.3-0.1,0.4-0.3,0.5-0.6l0.2-0.9h0.6l0.1,0.9c0.1,0.3,0.2,0.5,0.5,0.6l0.3,0.1c0.3,0.1,0.5,0.1,0.8-0.1
l0.7-0.5l0.5,0.5L7.6,2.9C7.4,3.2,7.4,3.5,7.5,3.7c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.1c0.1,0.3,0.3,0.5,0.6,0.5l0.9,0.1v0.6L8.2,5.4
C7.9,5.5,7.7,5.6,7.6,5.9L7.5,6.1C7.4,6.4,7.4,6.7,7.6,6.9l0.5,0.7L7.6,8.1L6.9,7.6C6.7,7.4,6.4,7.4,6.1,7.5C6.1,7.5,6.1,7.5,6,7.6z
M4.9,3.1c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h0c1,0,1.8-0.8,1.8-1.8S5.9,3.1,4.9,3.1z M4.9,6C4.3,6,3.8,5.5,3.8,4.9h0v0
c0-0.6,0.5-1.1,1.1-1.1C5.5,3.8,6,4.3,6,4.9C6,5.5,5.5,6,4.9,6z" />
    </symbol>
    <symbol id="regulatory"
            viewBox="0 0 18 20">
        <path class="st0"
              d="M15.8,5.1V1.2c0-0.7-0.6-1.3-1.3-1.2H1.3C0.9,0,0.6,0.1,0.4,0.4C0.1,0.6,0,1,0,1.3v17.4C0,19.4,0.6,20,1.3,20
h13.3c0.7,0,1.3-0.6,1.3-1.3v-3.4c0,0,0.1-0.1,0.1-0.1c1.3-1.4,2-3.2,2-5.1C17.9,8.2,17.2,6.4,15.8,5.1z M15.1,18.6
C15.1,18.7,15.1,18.7,15.1,18.6c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.1H1.3c0,0,0,0,0,0c0,0,0,0,0,0
c-0.1,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.1-0.4V1.3c0,0,0-0.1,0-0.1c0-0.3,0.3-0.5,0.6-0.4l13.2,0c0.2,0,0.5,0.1,0.5,0.6v3.2
c-1.2-0.9-2.7-1.4-4.3-1.4h0c-0.9,0-1.8,0.2-2.7,0.5h-5c-0.1,0-0.2,0.1-0.2,0.2v0C2.9,4,3,4.1,3.1,4.1h4.1c-0.5,0.3-1,0.6-1.4,1.1
c-0.5,0.5-0.9,1-1.2,1.6H3.1C3,6.8,2.9,6.8,2.8,7C2.8,7,3,7.3,3.1,7.3h1.2C3.9,8.1,3.7,9,3.7,9.9H3.1C3,9.9,2.8,10,2.8,10.1
s0.1,0.2,0.2,0.2h0.6v0.2l0,0c0,0.9,0.2,1.7,0.6,2.5H3.1c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2h1.4c0.6,1.1,1.4,2,2.4,2.7
H3.1c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2l4.7,0c0.9,0.4,1.9,0.7,3,0.7c1.6,0,3.1-0.6,4.3-1.5V18.6z M10.8,16.6
c-3.4,0-6.3-2.7-6.4-6.1l0,0v-0.3c0-1.7,0.7-3.3,1.9-4.6c1.2-1.2,2.8-1.9,4.5-1.9h0c3.5,0,6.4,2.9,6.4,6.4S14.3,16.6,10.8,16.6z
M14.4,7.5c-0.1,0-0.3,0-0.4,0.1l-4.7,4.7L7.1,10c-0.2-0.2-0.5-0.2-0.7,0c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.3,0.1,0.4l3,3l5.4-5.4
c0.2-0.2,0.2-0.5,0-0.7C14.6,7.5,14.5,7.5,14.4,7.5z" />
    </symbol>
    <symbol id="utilization"
            viewBox="0 0 19 20.2">
        <path class="st0"
              d="M8.1,10.5H11c0.2,0,0.3-0.1,0.3-0.3V8.8c0-0.2-0.1-0.3-0.3-0.3H8.1c-0.2,0-0.3,0.1-0.3,0.3v1.4
C7.8,10.3,7.9,10.5,8.1,10.5z M8.4,9.1h2.3v0.8H8.4V9.1z M3.6,10.5h2.9c0.2,0,0.3-0.1,0.3-0.3V8.8c0-0.2-0.1-0.3-0.3-0.3H3.6
c-0.2,0-0.3,0.1-0.3,0.3v1.4C3.3,10.3,3.5,10.5,3.6,10.5z M3.9,9.1h2.3v0.8H3.9V9.1z M12.6,10.5h2.9c0.2,0,0.3-0.1,0.3-0.3V8.8
c0-0.2-0.1-0.3-0.3-0.3h-2.9c-0.2,0-0.3,0.1-0.3,0.3v1.4C12.3,10.3,12.4,10.5,12.6,10.5z M12.8,9.1h2.3v0.8h-2.3V9.1z M8.1,13.6H11
c0.2,0,0.3-0.1,0.3-0.3V12c0-0.2-0.1-0.3-0.3-0.3H8.1c-0.2,0-0.3,0.1-0.3,0.3v1.4C7.8,13.5,7.9,13.6,8.1,13.6z M8.4,12.3h2.3v0.8
H8.4V12.3z M3.6,13.6h2.9c0.2,0,0.3-0.1,0.3-0.3V12c0-0.2-0.1-0.3-0.3-0.3H3.6c-0.2,0-0.3,0.1-0.3,0.3v1.4
C3.3,13.5,3.5,13.6,3.6,13.6z M3.9,12.3h2.3v0.8H3.9V12.3z M12.6,13.6h2.9c0.2,0,0.3-0.1,0.3-0.3V12c0-0.2-0.1-0.3-0.3-0.3h-2.9
c-0.2,0-0.3,0.1-0.3,0.3v1.4C12.3,13.5,12.4,13.6,12.6,13.6z M12.8,12.3h2.3v0.8h-2.3V12.3z M3.6,16.8h2.9c0.2,0,0.3-0.1,0.3-0.3
v-1.4c0-0.2-0.1-0.3-0.3-0.3H3.6c-0.2,0-0.3,0.1-0.3,0.3v1.4C3.3,16.7,3.5,16.8,3.6,16.8z M3.9,15.4h2.3v0.8H3.9V15.4z M12.6,16.8
h2.9c0.2,0,0.3-0.1,0.3-0.3v-1.4c0-0.2-0.1-0.3-0.3-0.3h-2.9c-0.2,0-0.3,0.1-0.3,0.3v1.4C12.3,16.7,12.4,16.8,12.6,16.8z M12.8,15.4
h2.3v0.8h-2.3V15.4z M18.9,6.8l-2.5-6.5c0-0.1-0.1-0.2-0.3-0.2H2.9c-0.1,0-0.2,0.1-0.3,0.2L0.1,6.8c0,0.1,0,0.2,0,0.3
c0.1,0.1,0.1,0.1,0.2,0.1h0.9v12.6c0,0.2,0.1,0.3,0.3,0.3h15.9c0.2,0,0.3-0.1,0.3-0.3V7.2h0.9c0.2,0,0.3-0.1,0.3-0.3
C18.9,6.9,18.9,6.8,18.9,6.8z M7.8,19.5h-6v-1c0,0,0,0,0,0h5.9V19.5z M10.7,19.5H8.4v-4.1h2.3V19.5z M17.2,19.5h-6v-1h6V19.5z
M17.2,18h-6v-2.8c0-0.2-0.1-0.3-0.3-0.3H8.1c-0.2,0-0.3,0.1-0.3,0.3V18H1.9c0,0,0,0,0,0V7.2h15.4V18z M0.8,6.6l2.3-6h12.9l2.3,6
H0.8z M10.3,3.4H9.8V2.8c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3v0.5H8.7c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3h0.5
v0.5c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3V3.9h0.5c0.2,0,0.3-0.1,0.3-0.3C10.6,3.5,10.5,3.4,10.3,3.4z M9.5,1.5
c-1.2,0-2.2,1-2.2,2.2c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2C11.7,2.4,10.7,1.5,9.5,1.5z M9.5,5.3c-0.9,0-1.6-0.7-1.6-1.6
C7.9,2.7,8.6,2,9.5,2c0.9,0,1.6,0.7,1.6,1.6C11.2,4.5,10.4,5.3,9.5,5.3z" />
    </symbol>
    <symbol id="edit"
            viewBox="0 0 22.5 20">
        <path d="M1178.14,261.42l-2.05-2.05a0.39,0.39,0,0,0-.55,0l-1.89,1.89,2.61,2.61,1.89-1.89a0.39,0.39,0,0,0,0-.55h0Zm-2.77,3.33-2.61-2.61-9.12,9.12-0.32,2.93,2.93-.32,9.12-9.12h0Zm3.66-1.89-12.21,12.21-3.9.43a0.82,0.82,0,0,1-.91-0.91l0.43-3.9,12.21-12.21a1.64,1.64,0,0,1,2.32,0l2.05,2.05a1.64,1.64,0,0,1,0,2.32h0Zm-5.7,7.47,0.78-.78a0.23,0.23,0,0,1,.4.16v6.42a1.88,1.88,0,0,1-1.87,1.88h-13.75a1.88,1.88,0,0,1-1.87-1.87V262.38a1.88,1.88,0,0,1,1.87-1.87h11.42a0.23,0.23,0,0,1,.16.4l-0.78.78a0.24,0.24,0,0,1-.16.07h-10.64a0.63,0.63,0,0,0-.62.63v13.75a0.63,0.63,0,0,0,.63.63h13.75a0.63,0.63,0,0,0,.63-0.62v-5.64A0.22,0.22,0,0,1,1173.32,270.32Z"
              transform="translate(-1157 -258)"
              fill="#0081c6" />
    </symbol>
    <symbol id="analytics"
            viewBox="0 0 23.33 17.5">
        <path d="M1218.67,273.85v-9.66a0.27,0.27,0,0,0-.27-0.27h-0.91a0.27,0.27,0,0,0-.27.27v9.66a0.27,0.27,0,0,0,.27.27h0.91A0.27,0.27,0,0,0,1218.67,273.85Zm4.38,0v-6.74a0.27,0.27,0,0,0-.27-0.27h-0.91a0.27,0.27,0,0,0-.27.27v6.74a0.27,0.27,0,0,0,.27.27h0.91A0.27,0.27,0,0,0,1223,273.85Zm4.38,0V262.73a0.27,0.27,0,0,0-.27-0.27h-0.91a0.27,0.27,0,0,0-.27.27v11.12a0.27,0.27,0,0,0,.27.27h0.91A0.27,0.27,0,0,0,1227.42,273.85Zm-13.12,0V270a0.27,0.27,0,0,0-.27-0.27h-0.91a0.27,0.27,0,0,0-.27.27v3.83a0.27,0.27,0,0,0,.27.27H1214A0.27,0.27,0,0,0,1214.29,273.85Zm15.49,3.19a0.55,0.55,0,0,1,.55.55V278a0.55,0.55,0,0,1-.55.55h-22.24A0.55,0.55,0,0,1,1207,278V261.55a0.55,0.55,0,0,1,.55-0.55h0.36a0.55,0.55,0,0,1,.55.55V277h21.33Z"
              transform="translate(-1207 -261)"
              fill="#0081c6" />
    </symbol>
    <symbol id="copy"
            viewBox="0 0 20 20">
        <path d="M1276.75,274.38a0.63,0.63,0,0,1-.62.63h-12.5a0.63,0.63,0,0,1-.62-0.62v-12.5a0.63,0.63,0,0,1,.63-0.62h12.5a0.63,0.63,0,0,1,.63.63v12.5Zm-3.75,3.75a0.63,0.63,0,0,1-.62.63h-12.5a0.63,0.63,0,0,1-.62-0.62v-12.5a0.63,0.63,0,0,1,.63-0.62h1.88v9.38a1.87,1.87,0,0,0,1.88,1.88H1273v1.88Zm3.13-18.12h-12.5a1.87,1.87,0,0,0-1.87,1.88v1.88h-1.87a1.87,1.87,0,0,0-1.87,1.88v12.5a1.87,1.87,0,0,0,1.88,1.88h12.5a1.88,1.88,0,0,0,1.88-1.87v-1.87h1.88a1.88,1.88,0,0,0,1.88-1.87v-12.5A1.88,1.88,0,0,0,1276.13,260Z"
              transform="translate(-1258 -260)"
              fill="#0081c6" />
    </symbol>
    <symbol id="delete"
            viewBox="0 0 18 20.57">
        <path d="M1321.69,275.3V264.69a0.48,0.48,0,0,1,.48-0.48h0.32a0.48,0.48,0,0,1,.48.48V275.3a0.48,0.48,0,0,1-.48.48h-0.32A0.48,0.48,0,0,1,1321.69,275.3Zm-6.43,0V264.69a0.48,0.48,0,0,1,.48-0.48h0.32a0.48,0.48,0,0,1,.48.48V275.3a0.48,0.48,0,0,1-.48.48h-0.32A0.48,0.48,0,0,1,1315.26,275.3Zm3.21,0V264.69a0.48,0.48,0,0,1,.48-0.48h0.32a0.48,0.48,0,0,1,.48.48V275.3a0.48,0.48,0,0,1-.48.48H1319A0.48,0.48,0,0,1,1318.48,275.3Zm7.07,1.77a0.64,0.64,0,0,1-.64.64h-11.57a0.64,0.64,0,0,1-.64-0.64V262.28h12.86v14.79Zm-8-17.36h3.21a0.65,0.65,0,0,1,.51.26l0.77,1h-5.79l0.77-1A0.65,0.65,0,0,1,1317.51,259.71Zm6.11,1.29-1.35-1.8a1.93,1.93,0,0,0-1.54-.77h-3.21a1.93,1.93,0,0,0-1.54.77l-1.35,1.8h-3.54a1,1,0,0,0-1,1V262a0.24,0.24,0,0,0,.24.24h1v14.79a1.93,1.93,0,0,0,1.93,1.93h11.57a1.93,1.93,0,0,0,1.93-1.93V262.28h1a0.24,0.24,0,0,0,.24-0.24V262a1,1,0,0,0-1-1h-3.54Z"
              transform="translate(-1310.12 -258.42)"
              fill="#0081c6" />
    </symbol>
    <symbol id="delete-x"
            viewBox="0 0 15.21 15.2">
        <line x1="14.76"
              y1="14.76"
              x2="0.44"
              y2="0.44"
              fill="none"
              stroke="#0a82c5"
              stroke-width="1.25" />
        <line x1="14.76"
              y1="0.44"
              x2="0.44"
              y2="14.76"
              fill="none"
              stroke="#0a82c5"
              stroke-width="1.25" />
    </symbol>
    <symbol id="collapse-arrow"
            viewBox="0 0 14.54 8.4">
        <polyline points="14.1 0.44 7.03 7.51 0.44 0.93"
                  fill="none"
                  stroke="#676868"
                  stroke-width="1.25" />
    </symbol>
    <symbol id="up-arrow"
            viewBox="0 0 16.19 18.76">
        <polyline points="1231.59 295.4 1240.1 286.9 1248.6 295.4"
                  fill="none"
                  stroke="#178acb"
                  stroke-width="1.25" />
        <line x1="1240.09"
              y1="286.76"
              x2="1240.09"
              y2="306.76"
              fill="none"
              stroke="#178acb"
              stroke-width="1.25" />
        <polyline points="0.44 8.54 8.1 0.88 15.75 8.54"
                  fill="none"
                  stroke="#178acb"
                  stroke-width="1.25" />
        <line x1="8.09"
              y1="0.76"
              x2="8.09"
              y2="18.76"
              fill="none"
              stroke="#178acb"
              stroke-width="1.25" />
    </symbol>
    <symbol id="down-arrow"
            viewBox="0 0 16.19 18.76">
        <polyline points="15.75 10.22 8.09 17.88 0.44 10.22"
                  fill="none"
                  stroke="#178acb"
                  stroke-width="1.25" />
        <line x1="8.09"
              y1="18"
              x2="8.09"
              fill="none"
              stroke="#178acb"
              stroke-width="1.25" />
    </symbol>
</svg>
</div>