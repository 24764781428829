/*
 * Copyright (c) 2020 – 2021, INOVALON, INC. All Rights Reserved.
 * This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
 * The receipt or possession of this program does not convey any rights to use,
 * reproduce or disclose its contents in whole or in part, without the specific
 * written consent of Inovalon, Inc. Any use, reproduction or disclosure of
 * this program without the express written consent of Inovalon, Inc., is a
 * violation of the copyright laws and may subject you to criminal prosecution.
 */

//  app loader will have default delay of 1s before hiding
 export const APP_LOADER_HIDE_DELAY = 1000;
