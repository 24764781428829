
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Injectable } from '@angular/core';
import { MenuItem } from '../models/menu-item.model';
import { environment } from 'src/environments/environment';
import { IndicesMenuApiService } from './indices-menu-api.service';
import { OktaService } from '../factory/okta.service';
import { ClientModel } from '../models/client.model';
import { Observable, BehaviorSubject, firstValueFrom } from 'rxjs';
import { LoggerService } from '../shared/services/logger.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as $ from "jquery";
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  keepOpen = false;
  menuList: MenuItem[];
  adminMenuList: MenuItem[];
  developerMenuList: MenuItem[];
  allMenus: any;
  productMenus: any;
  topNavTabRoutes: any;
  topNavTabRoutes$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dashboardMenus: any;
  utilityMenus: any;
  searchMenus: any;
  powerBIMenus: any;
  valueBasedDashboardMenus: any;
  riskLifecycleDashbaordMenus: any;
  convergedGapsDashboardMenus: any;
  qualityPerfDashboardMenus: any;
  membershipInsightsDashboardMenus: any;
  valueCompass4DashboardMenu: any;
  routeUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  clientLogo: string;
  isMenuLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isMenuLoaded$: Observable<boolean> = this.isMenuLoaded.asObservable();

  userClients: BehaviorSubject<ClientModel[]> = new BehaviorSubject<ClientModel[]>(null);
  userClients$: Observable<ClientModel[]> = this.userClients.asObservable();
  footerText: string;

  constructor(
    private indicesMenuApiService: IndicesMenuApiService,
    private oktaService: OktaService,
    private loggerService: LoggerService,
    private router: Router
  ) {
  }

  async initialize() {
    await Promise.all([this.getIndicesNavItems(),this.getProducts(),this.getClients()])
    // await this.getIndicesNavItems();
    // await this.getProducts();
    // await this.getClients();
  }

  async getIndicesNavItems() {
    try{
      const navUrl = `api/User/GetUserNavItems?referrerApp=rli`;
      const response = await firstValueFrom(this.indicesMenuApiService.get(`MenuService.GetIndicesNavItems`, navUrl));
      if (response == null) { throw new Error('INDICES nav request failed'); }
      if (!response.userTermAccepted || (
        (!response.menus.riskLifecycleDashboardMenu || response.menus.riskLifecycleDashboardMenu.length === 0)
        && (!response.menus.convergedGapsDashboardMenu || response.menus.convergedGapsDashboardMenu.length === 0)
        && (!response.menus.qualityPerfDashboardMenu || response.menus.qualityPerfDashboardMenu.length === 0)
        && (!response.menus.membershipInsightsDashboardMenu || response.menus.membershipInsightsDashboardMenu.length === 0)
        && (!(response.menus.utilityMenu && response.menus.utilityMenu.length > 0 
          && response.menus.utilityMenu[0].children && response.menus.utilityMenu[0].children.length > 0 
          && response.menus.utilityMenu[0].children.map(um => um.name).some(item => ['IND v3 Client Config', 'IND v3 Bulk User Provisioning'].includes(item))))
        && (!response.menus.valueCompass4DashboardMenu || response.menus.valueCompass4DashboardMenu.length === 0)
        && (!response.menus.powerBIMenu || response.menus.powerBIMenu.length === 0)
      )
      ) {
        const idp = localStorage.getItem("idp");
        const domainHint = localStorage.getItem("domain_hint");
        if(idp && ["okta","b2c","b2c-federated"].includes(idp)){
          let url = response.indicesAppUrl + '?chkClt=1'+`&idp=${idp}`; 
          if(domainHint && ["b2c-federated"].includes(idp)){
            url+= `&domain_hint=${domainHint}`;
          } 
          window.location.href  = url    
        }else{
          window.location.href = response.indicesAppUrl + '?chkClt=1';
        }    
      }
      this.allMenus = response.menus;
      this.dashboardMenus = response.menus.dashboardMenu;
      this.valueBasedDashboardMenus = response.menus.valueBasedDashboardMenu;
      this.riskLifecycleDashbaordMenus = response.menus.riskLifecycleDashboardMenu;
      this.convergedGapsDashboardMenus = response.menus.convergedGapsDashboardMenu;
      this.qualityPerfDashboardMenus = response.menus.qualityPerfDashboardMenu;
      this.valueCompass4DashboardMenu = response.menus.valueCompass4DashboardMenu;
      this.membershipInsightsDashboardMenus = response.menus.membershipInsightsDashboardMenu
      const permissions = response.userPermissions.map(permission => permission.permissionName);
      this.oktaService.userPermissions.next(permissions);
      this.getTopNavTabRoutes();
  
      this.utilityMenus = response.menus.utilityMenu;
      this.searchMenus = response.menus.searchMenu;
      this.powerBIMenus = response.menus.powerBIMenu;
      this.clientLogo = response.clientLogo.src;
      this.footerText = response.clientLogo.footerText;
      // set default client
      this.oktaService.client.next(response.client);
      this.isMenuLoaded.next(true)
      return;
    }
    catch(err){
      this.loggerService.logger({
        type: 'error',
        class: 'MenuService',
        method: 'getIndicesNavItems',
        message: 'Failed to get the list of menu you are associated with.',
        error: err
      });
    }
   
    // this.indicesMenuApiService.get(`MenuService.GetIndicesNavItems`, navUrl).subscribe((response) => {
    //   if (response == null) { throw new Error('INDICES nav request failed'); }
    //   if (!response.userTermAccepted || (
    //     (!response.menus.riskLifecycleDashboardMenu || response.menus.riskLifecycleDashboardMenu.length === 0)
    //     && (!response.menus.convergedGapsDashboardMenu || response.menus.convergedGapsDashboardMenu.length === 0)
    //     && (!response.menus.qualityPerfDashboardMenu || response.menus.qualityPerfDashboardMenu.length === 0)
    //     && (!response.menus.membershipInsightsDashboardMenu || response.menus.membershipInsightsDashboardMenu.length === 0)
    //     && (!(response.menus.utilityMenu && response.menus.utilityMenu.length > 0 
    //       && response.menus.utilityMenu[0].children && response.menus.utilityMenu[0].children.length > 0 
    //       && response.menus.utilityMenu[0].children.map(um => um.name).some(item => ['IND v3 Client Config', 'IND v3 Bulk User Provisioning'].includes(item))))
    //     && (!response.menus.valueCompass4DashboardMenu || response.menus.valueCompass4DashboardMenu.length === 0)
    //     && (!response.menus.powerBIMenu || response.menus.powerBIMenu.length === 0)
    //   )
    //   ) {
    //     window.location.href = response.indicesAppUrl + '?chkClt=1';
    //   }
    //   this.allMenus = response.menus;
    //   this.dashboardMenus = response.menus.dashboardMenu;
    //   this.valueBasedDashboardMenus = response.menus.valueBasedDashboardMenu;
    //   this.riskLifecycleDashbaordMenus = response.menus.riskLifecycleDashboardMenu;
    //   this.convergedGapsDashboardMenus = response.menus.convergedGapsDashboardMenu;
    //   this.qualityPerfDashboardMenus = response.menus.qualityPerfDashboardMenu;
    //   this.valueCompass4DashboardMenu = response.menus.valueCompass4DashboardMenu;
    //   this.membershipInsightsDashboardMenus = response.menus.membershipInsightsDashboardMenu
    //   this.oktaService.userPermissions.next(response.userPermissions.map(permission => permission.permissionName));
    //   this.getTopNavTabRoutes();

    //   this.utilityMenus = response.menus.utilityMenu;
    //   this.searchMenus = response.menus.searchMenu;
    //   this.powerBIMenus = response.menus.powerBIMenu;
    //   this.clientLogo = response.clientLogo.src;
    //   this.footerText = response.clientLogo.footerText;

    //   // set default client
    //   this.oktaService.client.next(response.client);
    // }, err => {
    //   this.loggerService.logger({
    //     type: 'error',
    //     class: 'MenuService',
    //     method: 'getIndicesNavItems',
    //     message: 'Failed to get the list of menu you are associated with.',
    //     error: err
    //   });
    // }, () => this.isMenuLoaded.next(true));
  }

  async getProducts() {
    try{
      const navUrl = `api/User/GetApplications`;
      const response = await firstValueFrom(this.indicesMenuApiService.get(`MenuService.GetProducts`, navUrl));
      const products = response && response.length > 0 ? response : [{ name: 'Data Insights', url: '/' }];
      const idp = localStorage.getItem("idp");
      const domainHint = localStorage.getItem("domain_hint");
      let redirectUrl;

      if(idp && ["okta","b2c","b2c-federated"].includes(idp)) {
         redirectUrl = `?idp=${idp}`;
        if(domainHint && ["b2c-federated"].includes(idp)){
          redirectUrl += `&domain_hint=${domainHint}`;
        }
      }

      let productUrls = products;
      if(redirectUrl) {
         productUrls = products.map(p => {
          return { ...p, url: p.url + redirectUrl };
      });
      }
         
      this.productMenus = productUrls;
      return;
    }
    catch(err){
      this.loggerService.logger({
        type: 'error',
        class: 'MenuService',
        method: 'getProducts',
        message: 'Failed to get the list of products you are associated with.',
        error: err
      });
    }

    // this.indicesMenuApiService.get(`MenuService.GetProducts`, navUrl).subscribe((response) => {
    //   const products = response && response.length > 0 ? response : [{ name: 'Data Insights', url: '/' }];
    //   this.productMenus = products;
    // }, err => {
    //   this.loggerService.logger({
    //     type: 'error',
    //     class: 'MenuService',
    //     method: 'getProducts',
    //     message: 'Failed to get the list of products you are associated with.',
    //     error: err
    //   });
    // });
  }

  changeClient(clientName: string) {
    const navUrl = `api/User/ChangeClient/${clientName}`;
    this.indicesMenuApiService.post(`MenuService.ChangeClient`, navUrl, null).subscribe((res) => {
      if (res) {
        // this.initialize();
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
        
      } else {
        this.loggerService.logger({
          type: 'error',
          class: 'MenuService',
          method: 'changeClient',
          message: 'Failed to switch customer.',
        });
      }
    }, err => {
      this.loggerService.logger({
        type: 'error',
        class: 'MenuService',
        method: 'changeClient',
        message: 'Failed to switch customer.',
        error: err
      });
    });
  }

  async getClients() {
    try{
      const navUrl = `api/User/GetUserClients`;
      const clients = await firstValueFrom(this.indicesMenuApiService.get(`MenuService.getClients`, navUrl));
      if (clients && clients.length > 0) {
        this.userClients.next(clients);
      }
      return;
    }
    catch(err){
      this.loggerService.logger({
        type: 'error',
        class: 'MenuService',
        method: 'getClients',
        message: 'Failed to get the list of customers you are associated with.',
        error: err
      });
    }
   
    // return this.indicesMenuApiService.get(`MenuService.getClients`, navUrl).subscribe((clients) => {
    //   if (clients && clients.length > 0) {
    //     this.userClients.next(clients);
    //   }
    // }, err => {
    //   this.loggerService.logger({
    //     type: 'error',
    //     class: 'MenuService',
    //     method: 'getClients',
    //     message: 'Failed to get the list of customers you are associated with.',
    //     error: err
    //   });
    // });
  }

  getPxData(client: ClientModel, user: any): Observable<any> {
    const navUrl = `api/User/GetPxData/${client.clientId}`;
    return this.indicesMenuApiService.get(`MenuService.getPxData`, navUrl).pipe(map(pxData => {
      if (pxData.userFilterData) {
        pxData.userFilterData.id = user.upn;
        pxData.userFilterData.email = user.email;
        pxData.userFilterData.firstName = user.given_name;
        pxData.userFilterData.lastName = user.family_name;
        pxData.userFilterData.userUPNId = user.upn;
      }
      if (pxData.userAccountInfo) {
        pxData.userAccountInfo.id = client.clientName;
        pxData.userAccountInfo.name = client.clientName;
      }
      return pxData;
    }));
  }

  getTopNavTabRoutes(url = this.oktaService.redirectUrl||window.location.pathname||"") {
    const menus = Object.assign({}, this.allMenus);
    let redirectUrl = url.startsWith('/') ? url.substring(1) : url;
    redirectUrl = redirectUrl.indexOf("?") > -1 ? redirectUrl.split("?")[0] : redirectUrl;
    this.routeUrl$.next(redirectUrl);
    redirectUrl = (redirectUrl.includes('qpi') && redirectUrl.includes('extract')) ? redirectUrl.replace('/extract', "") : redirectUrl;   
    const navFound = Object.entries(menus).find(menu => redirectUrl.length > 0 && JSON.stringify(menu).includes(`"routerLink":"${redirectUrl}`));
    const navOnly: any = navFound ? navFound[1] : [];

    let nav = JSON.parse(JSON.stringify(navOnly));

    // remove all other menus/routes from top menu bar
    if (url.includes('/admin')) {
      if (nav.length > 0) {
        nav = nav.filter((item) => {
          if (item.hasOwnProperty('children') && item['children'] && item['children'].length > 1) {
            item['children'] = item['children'].filter(child => (child.routerLink === redirectUrl));
          }
          return JSON.stringify(item).includes(`"routerLink":"${redirectUrl}`)
        });
      }
    }

    if (url.includes ('dbaas')) {
      if (nav.length > 0) {
        nav = this.filterDBaaSNav(nav, redirectUrl);
      }
    }

    if (url.includes('/vbpi') || url.includes('es')) {
      nav = nav.map(domain => {
        domain.children = domain.children.filter(child => redirectUrl.length > 0 && JSON.stringify(child).includes(`"routerLink":"${redirectUrl}`));
        if (url.includes('/vbpi')) {
          domain = domain.children[0];
        }
        return domain;
      })
    }
    this.topNavTabRoutes = nav.map(domain => (
      domain.children.map(subDomain => (
        {
          label: subDomain.name,
          path: subDomain.children ? subDomain.children[0].routerLink : subDomain.routerLink,
          isActive: true,
        }
      ))
    )).pop() || [];
    this.topNavTabRoutes$.next(this.topNavTabRoutes);
  }
  filterDBaaSNav(nav, redirectUrl) {
    return nav.filter((item) => {
      if (item.hasOwnProperty('children') && item['children'] && item['children'].length > 0) {
        item['children'] = this.filterDBaaSNav(item['children'], redirectUrl);
        return item['children'].length > 0;
      }
      return JSON.stringify(item).includes(`"routerLink":"${redirectUrl}"`);
    });
  }
}
