import { Component, OnInit, HostListener, Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { DownloadsFilterPipe } from 'src/app/pipes/downloads-filter.pipe';
import { DownloadItem, DownloadItemStatus } from 'src/app/models/download-item.model';
import { timer, Subject, of } from 'rxjs';
import { switchMap, tap, catchError, takeUntil } from 'rxjs/operators';
import { OktaService } from 'src/app/factory/okta.service';
import { DownloadFileService } from 'src/app/services/download-file.service';


@Component({
  selector: 'app-my-downloads-card',
  templateUrl: './my-downloads-card.component.html',
  styleUrls: ['./my-downloads-card.component.css'],
})
export class MyDownloadsCardComponent implements OnInit, OnDestroy {

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.stopImmediatePropagation()
  }
  selectedHeaderFilter: DownloadItemStatus = null
  downloadItemStatuses: typeof DownloadItemStatus = DownloadItemStatus
  items: DownloadItem[] = []
  viewList: DownloadItem[] = []
  isLoading: boolean = false
  private destroy$: Subject<null> = new Subject()
  private downloadsFilterPipe: DownloadsFilterPipe = new DownloadsFilterPipe()
  private static readonly POOL_INTERVAL: number = 30000
  constructor(
    private downloadFileService: DownloadFileService,
    private oktaService: OktaService) { }

  ngOnInit() {
    this.isLoading = true
    this.startPolling()
    this.oktaService.logout$.subscribe((isLoggedOut) => {
      this.destroy$.next(null)
      this.destroy$.complete()
    })
  }

  startPolling() {
    timer(1, MyDownloadsCardComponent.POOL_INTERVAL).pipe(takeUntil(this.destroy$), switchMap(() => {
      return this.fetchDownloadItems()
    })).subscribe()
  }

  fetchDownloadItems() {
    return this.downloadFileService.getAllDownloadItems().pipe(
      tap(items => {
        this.items = items
        this.isLoading = false
        this.changeFilter(this.selectedHeaderFilter)
      }),
      catchError(err => {
        this.isLoading = false;
        return of([]);
      })
    )
  }

  changeFilter(filter: DownloadItemStatus) {
    const list: DownloadItem[] = this.downloadsFilterPipe.transform(this.items, filter)
    if (list.length) {
      this.selectedHeaderFilter = filter    //select filter if list length is greater than zero
      this.viewList = list
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null)
    this.destroy$.complete()
  }
}


