
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import {Injectable} from '@angular/core';

@Injectable( {
  providedIn: 'root'
})
export class TokenModel {
  upn: string;
  roleName: string;
  displayName: string;
  email: string;
  token: string;
  cutomers: string[] = [];
  permission: string[] = [];
}
