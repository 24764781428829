import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IoMessageBarService } from '../io-message-bar.service';

@Component({
  selector: 'io-message-bar-item',
  templateUrl: './io-message-bar-item.component.html',
  styleUrls: ['./io-message-bar-item.component.css']
})
export class IoMessageBarItemComponent implements OnInit {
  @Input() id: string;
  @Input() type: string;
  @Input() closable = false;

  public isInformation: boolean;
  public isError: boolean;
  public isSuccess: boolean;
  public isWarning: boolean;

  constructor(
    private ioMessageBarService: IoMessageBarService
  ) { }

  ngOnInit() {
    switch (this.type) {
      case 'error':
        this.isError = true;
        break;
      case 'information':
        this.isInformation = true;
        break;
      case 'success':
        this.isSuccess = true;
        break;
      case 'warning':
        this.isWarning = true;
        break;
      default:
        this.isInformation = true;
    }
  }

  onClose() {
    this.ioMessageBarService.closeItem(this.id);
  }

}
