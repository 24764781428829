
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '../services/menu.service';
import { OktaService } from './okta.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { AuthService } from '@io-angular/auth';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OktaGuard implements CanActivate {
  isAuthenticated = this.oktaService.isAuthenticated;
  loggerService: any;

  constructor(
    private oktaService: OktaService,
    private router: Router,
    private menuService: MenuService,
    private authService : AuthService,
    private cookieService:CookieService,
    private activatedRoute:ActivatedRoute
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // this.isAuthenticated = await this.oktaService.oktaAuthService.isAuthenticated();
    this.isAuthenticated= await firstValueFrom(this.authService.isAuthenticated$);

    if (this.isAuthenticated) {

      const cookieRedirectionURL = localStorage.getItem('cookieRedirectionURL')
      var initLoginUrl = localStorage.getItem('loginUrlIniti');

      if (initLoginUrl) {
        state.url = initLoginUrl;
        localStorage.removeItem('loginUrlIniti');
      }
      if (this.oktaService.upn$.value === '') {
        let allow : boolean = false;
        if(cookieRedirectionURL){
          this.oktaService.redirectUrl = cookieRedirectionURL;
          localStorage.removeItem('cookieRedirectionURL');
          allow = false;
          this.router.navigateByUrl(this.oktaService.redirectUrl);
        }else{
          this.oktaService.redirectUrl = state.url;
          allow = true;
        }        
        this.oktaService.getSession();
        return allow;
      } else {
        this.menuService.getTopNavTabRoutes(state.url);
        let permissions = this.oktaService.userPermissions.value;
        if (route.data.permission && route.data.permission.length > 0 && permissions) {
          var hasPermission = false;
          for (var i = 0; i < route.data.permission.length; i++) {            
            hasPermission = permissions.includes(route.data.permission[i]);
            if (hasPermission) {
              break;
            }
          }
          if (!hasPermission) {
            this.router.navigate(['/unauthorized']);
          }
          return hasPermission;
        } else {
          return true;
        }
      }
    } else {
      localStorage.setItem('loginUrlIniti', state.url);
      // this.oktaService.login(state.url);
      return false;
    }
  }

}
