import { Component } from '@angular/core';
import { IoMessageBarService } from '../io-message-bar.service';

@Component({
  selector: 'io-message-bar',
  templateUrl: './io-message-bar.component.html',
  styleUrls: ['./io-message-bar.component.css']
})
export class IoMessageBarComponent  {

  constructor(
    public ioMessageBarService: IoMessageBarService,
  ) { }

}
