export class CCGFilterLabels {
    static readonly ALL = "All";
}

export class CCGFilterFields {
    static readonly PROJECT_NAME = "projectName";
    static readonly REPORTING_POPULATION = "reportingPopulationName";
    static readonly LINE_OF_BUSINESS = "lineOfBusiness";
    static readonly GAP_DESCRIPTION = "gapDescription";
    static readonly MEDICAL_GROUP = "medicalGroup";
    static readonly PROVIDER_NAME = "providerName";
    static readonly ANALYTICS_RUN_NAME = "analyticRunNameDate";
    static readonly CATALOG_NAME = "catalogName";
    static readonly DOMAIN_TYPE = "domainType";
    static readonly GAP_TYPE = "gapType";
    static readonly CLINIC_NAME = "clinicName";
    static readonly MEMBER_NAME = "memberFullName";
    static readonly MEMBER_ID = "healthPlanMemberId";

    //filter labels
    static readonly ANALYTICS_RUN_DATE = "analyticsRunDate";
    static readonly MEASUREMENT_PERIOD = "measurementPeriod";
    static readonly CONTINUOUS_ENROLLMENT = "continuousEnrollment";

    //ccg top gaps description filter
    static readonly QUALITY_MEASURE_PERFORMANCE_RATE = "rateName";
}

export class CCGFiltersBinding {
    static readonly bindingInfo = {
        [CCGFilterFields.PROJECT_NAME]: {
            idField: "projectId",
            label: "Project Name"
        },
        [CCGFilterFields.REPORTING_POPULATION]: {
            idField: "reportingPopulationId",
            label: "Reporting Population"
        },
        [CCGFilterFields.LINE_OF_BUSINESS]: {
            idField: "lineOfBusinessId",
            label: "Line of Business"
        },
        [CCGFilterFields.GAP_DESCRIPTION]: {
            idField: "gapDescriptionId",
            label: "Gap Description (Measure)"
        },
        [CCGFilterFields.MEDICAL_GROUP]: {
            idField: "medicalGroupId",
            label: "Medical Group (ID)"
        },
        [CCGFilterFields.PROVIDER_NAME]: {
            idField: "providerId",
            label: "Provider Name (ID)"
        },

        [CCGFilterFields.ANALYTICS_RUN_NAME]: {
            idField: "analyticRunId",
            label: "Analytics Run Name"
        },
        [CCGFilterFields.CATALOG_NAME]: {
            idField: "catalogId",
            label: "Catalog Name"
        },
        [CCGFilterFields.DOMAIN_TYPE]: {
            idField: "domainTypeId",
            label: "Domain Type"
        },
        [CCGFilterFields.GAP_TYPE]: {
            idField: "gapTypeId",
            label: "Gap Type"
        },
        [CCGFilterFields.CLINIC_NAME]: {
            idField: "clinicId",
            label: "Clinic Name (ID)"
        },
        [CCGFilterFields.MEASUREMENT_PERIOD]: {
            idField: "measurementPeriodId",
            label: "Measurement Period"
        },
        [CCGFilterFields.ANALYTICS_RUN_DATE]: {
            idField: "analyticsRunDateId",
            label: "Analytics Run Date"
        },
        [CCGFilterFields.CONTINUOUS_ENROLLMENT]: {
            idField: "continuousEnrollmentId",
            label: "Continuous Enrollment"
        },
        [CCGFilterFields.QUALITY_MEASURE_PERFORMANCE_RATE]: {
            idField: "rateId",
            label: "Quality Measure Performance Rate"
        }
    }
}