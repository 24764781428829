export class HomeConstants {
  /* Toggle Button Text */
  static readonly CHARTS_TEXT = "Charts";
  static readonly PURSUITS_TEXT = "Pursuits";
  static readonly CLICKED_COLUMN = "clickedColumn";
  static readonly CLICKED_COLUMN_VALUE = "clickedColumnValue";
  static readonly MRR = "Medical Record Review";

  static readonly gainSightLogStrings = {
    [HomeConstants.CHARTS_TEXT]: "3.0 MRR Charts View",
    [HomeConstants.PURSUITS_TEXT]: "3.0 MRR Pursuits View"
  }

  static readonly tabs = {
    OVERVIEW_IDX: 0,
    ACTUALS_VS_FORECASTS_IDX: 1,
    AGING_REPORT_IDX: 2,
    ACTIVITY_LOG_IDX: 3,
    TREND_IDX: 4,
    EXTRACT_IDX: 5
  }

  static readonly pursuitStates = {
    ISSUED: "Issued",
    IN_PROGRESS: "In Progress",
    CLOSED: "Closed",
  }

  static readonly generalConstants = {
    STATUS_STRING: "status",
    YES: "Yes",
    NO: "No"
  }
}


export class TabsConstants {
  static readonly OVERVIEW = { index: 0, label: "Overview" }
  static readonly ACTUALS_VS_FORECASTS = { index: 1, label: "Actuals vs Forecasts" }
  static readonly AGING_REPORT = { index: 2, label: "Aging Report" }
  static readonly ACTIVITY_LOG = { index: 3, label: "Activity Log" }
  static readonly TREND = { index: 4, label: "Trend" }
  static readonly EXTRACT = { index: 5, label: "Extract" }
}